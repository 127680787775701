import React, { useEffect } from "react";
import { PageHeaderInterface } from "../../../interface/PageHeaderInterface";
import DatePickerPopover from "@components/Datepicker/DatePickerPopover";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { DEPLOY_ENVIRONMENT } from "constants/constants";
import { useTimeRangeLabel } from "@lib/hooks/useTimeRangeLabel";
import {
  attributionModeAtom,
  comparedAtom,
  hasChangedAttributionModeAtom,
  showAttributionModeBannerAtom,
} from "atoms";
import { useAtom } from "jotai";
import dayjs from "dayjs";
import AiAttributionBadge from "./AiAttributionBadge";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import ReferralBanner from "@components/ReferralBanner/ReferralBanner";

export function DatePageHeader({
  title,
  maxDate,
  minDate,
  children,
  disableAiModeSwitch = false,
  enableCompare = false,
}: PageHeaderInterface) {
  const { label, comparedLabel } = useTimeRangeLabel();
  const [attributionMode, setAttributionMode] = useAtom(attributionModeAtom);
  const [hasChangedAttributionMode, setHasChangedAttributionMode] = useAtom(
    hasChangedAttributionModeAtom
  );
  const { selectedOrganisationData } = useSelectedStores();
  const [showAttributionModeBanner, setShowAttributionModeBanner] = useAtom(
    showAttributionModeBannerAtom
  );
  const [isCompared, setIsCompared] = useAtom(comparedAtom);
  useEffect(() => {
    if (!enableCompare && isCompared) {
      setIsCompared(false);
    }
  }, [isCompared, enableCompare, setIsCompared]);
  const showBanner =
    attributionMode !== "ai" &&
    dayjs(showAttributionModeBanner).subtract(1, "second").isBefore(dayjs());
  return (
    <>
      <div className="grid grid-cols-3 items-center gap-2 relative z-navbar">
        <div className="w-full col-span-3 sm:col-span-2 flex items-center">
          {typeof title === "string" ? <h2 className="h2">{title}</h2> : title}
          {attributionMode === "ai" ? <AiAttributionBadge /> : null}
        </div>
        <div className="w-full flex justify-end items-start self-start lg:min-w-[320p] col-span-3 sm:col-span-1 ">
          {children}

          <div className="w-fit flex-none">
            <DatePickerPopover
              isCompared={enableCompare}
              maxDate={maxDate}
              minDate={minDate}
            >
              <div className="px-3 h-12 w-fit 2xl:px-8 bg-cardPrimary rounded-md  cursor-pointer flex flex-none items-center justify-center">
                <div className="">
                  <div className="text-xs text-foreground text-center">
                    {label}
                  </div>
                  {comparedLabel && enableCompare ? (
                    <div className="w-full text-xs text-foreground-soft text-center">
                      {comparedLabel}
                    </div>
                  ) : null}
                </div>
                <div className="ml-2">
                  <ChevronDownIcon
                    color={"var(--color-foreground)"}
                    className="h-4 w-4"
                  />
                </div>
              </div>
            </DatePickerPopover>
          </div>
        </div>

        <ReferralBanner />
        {showBanner && !disableAiModeSwitch ? (
          <div className="col-span-3 flex items-center p-2 bg-primary rounded-md ">
            <div className="flex-1 flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="w-4 h-4 mr-1 flex-shrink-0 dark:fill-foreground fill-white"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M19 9l1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z" />
              </svg>
              <p className="font-semibold text-sm tracking-tight dark:text-foreground text-white">
                NEW AI Attribution Mode{" "}
                {DEPLOY_ENVIRONMENT !== "beta" ? (
                  <span className="inline-block text-sm rounded-md py-0.5 px-1 bg-gray-500 text-background dark:text-foreground dark:bg-gray-600">
                    Beta
                  </span>
                ) : null}
                <span className="font-normal ml-1 ">
                  Switch to AI Attribution mode and stop worrying about which
                  attribution is right for you. You can switch back anytime in
                  Settings.{" "}
                </span>
                <a
                  className="font-semibold underline"
                  href="https://knowledge.tracify.ai/en/articles/109052-ai-attribution-mode"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </p>
            </div>
            <button
              className="text-sm py-0.5 px-1 mr-0.5 tracking-tight border border-white font-semibold rounded-md bg-gradient-to-r from-aiPurple to-aiPurpleDark dark:to-aiPurpleDarker text-white"
              onClick={() => {
                setAttributionMode("ai");
                setHasChangedAttributionMode(true);
              }}
            >
              Switch to AI Mode
            </button>
            <button
              className="text-sm p-1 bg-primary hover:bg-primary-dark text-white rounded-md transition-colors duration-200"
              onClick={() => {
                setShowAttributionModeBanner(
                  dayjs().add(2, "days").toISOString()
                );
              }}
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}

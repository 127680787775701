import ChannelSelectMenu from "@components/ChannelSelectMenu/ChannelSelectMenu";
import { Mutable, SelectTypes } from "@lib/util-types";
import { Select } from "@ui";
import {
  attributionModelAtom,
  attributionWindowAtom,
  creativeAttributionAtom,
  reportTimeAtom,
} from "atoms";
import {
  ATTRIBUTION_MODEL_OPTIONS,
  ATTRIBUTION_WINDOW_OPTIONS,
  AVAILABLE_MARKETING_CHANNEL_OPTIONS,
  CREATIVE_ATTRIBUTION_OPTIONS,
  REPORT_TIME_OPTIONS,
} from "constants/constants";
import { useAtom } from "jotai";
import React from "react";

type Props = {
  selectType?: SelectTypes;
  showChannelSelectMenu?: boolean;
  hideReportTimeMenu?: boolean;
  alwaysIncludeChannelValue?: (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"];
  hideAttributionModelMenu?: boolean;
  useCreativeOptions?: boolean;
  children?: React.ReactNode;
  alwaysOneChannelValue?: boolean;
  onOneSelectedChannelFailure?: () => void;
};

const DataSelectMenu: React.FC<Props> = ({
  selectType,
  showChannelSelectMenu,
  hideReportTimeMenu,
  alwaysIncludeChannelValue,
  hideAttributionModelMenu,
  useCreativeOptions,
  children,
  onOneSelectedChannelFailure,
  alwaysOneChannelValue,
}) => {
  const [creativeAttribution, setCreativeAttribution] = useAtom(
    creativeAttributionAtom
  );
  const [attributionModel, setAttributionModel] = useAtom(attributionModelAtom);
  const [attributionWindow, setAttributionWindow] = useAtom(
    attributionWindowAtom
  );
  const [reportTime, setReportTime] = useAtom(reportTimeAtom);

  let menuCount = 3;
  if (showChannelSelectMenu) {
    menuCount += 1;
  }
  if (hideReportTimeMenu) {
    menuCount -= 1;
  }
  if (hideAttributionModelMenu) {
    menuCount -= 1;
  }
  const gridClassNames =
    menuCount === 4
      ? "grid-cols-2 lg:grid-cols-4"
      : menuCount === 3
        ? "grid-cols-1 md:grid-cols-3"
        : menuCount === 2
          ? "max-w-xl ml-auto grid-cols-1 md:grid-cols-2"
          : "max-w-xl ml-auto grid-cols-1";
  return (
    <div
      className={`flex-1 grid ${gridClassNames} relative gap-2 xl:mt-0 xl:justify-end`}
    >
      {showChannelSelectMenu && (
        <ChannelSelectMenu
          selectType={selectType}
          alwaysIncludeValue={alwaysIncludeChannelValue}
          alwaysOneValue={alwaysOneChannelValue}
          onOneSelectedFailure={onOneSelectedChannelFailure}
        />
      )}
      {(!showChannelSelectMenu || hideReportTimeMenu) && children
        ? children
        : null}
      <Select
        id="attribution-window-select"
        value={parseInt(`${attributionWindow}`) as unknown as string} // only here to make typescript happy
        initialValue={parseInt(`${attributionWindow}`) as unknown as string} // only here to make typescript happy
        placeholder="Attribution Window"
        label="Attribution Window"
        prefix="Attr. Window: "
        className="bg-gray-700"
        type={selectType}
        onChange={(e) => setAttributionWindow(e.target.value)}
        options={
          ATTRIBUTION_WINDOW_OPTIONS as Mutable<
            typeof ATTRIBUTION_WINDOW_OPTIONS
          >
        }
        width="w-full"
      ></Select>
      {!hideReportTimeMenu ? (
        <Select
          id="report-time-select"
          value={reportTime}
          initialValue={reportTime}
          placeholder="Report Time"
          label="Report Time"
          className="bg-gray-700"
          type={selectType}
          onChange={(e) => setReportTime(e.target.value)}
          options={REPORT_TIME_OPTIONS as Mutable<typeof REPORT_TIME_OPTIONS>}
          width="w-full"
        ></Select>
      ) : null}
      {!hideAttributionModelMenu ? (
        <Select
          id="attribution-model-select"
          value={useCreativeOptions ? creativeAttribution : attributionModel}
          initialValue={
            useCreativeOptions ? creativeAttribution : attributionModel
          }
          placeholder="Attribution Model"
          label="Attribution Model"
          className="bg-gray-700"
          type={selectType}
          prefix="Attribution: "
          onChange={(e) =>
            useCreativeOptions
              ? setCreativeAttribution(e.target.value)
              : setAttributionModel(e.target.value)
          }
          options={
            useCreativeOptions
              ? (CREATIVE_ATTRIBUTION_OPTIONS as Mutable<
                  typeof CREATIVE_ATTRIBUTION_OPTIONS
                >)
              : (ATTRIBUTION_MODEL_OPTIONS as Mutable<
                  typeof ATTRIBUTION_MODEL_OPTIONS
                >)
          }
          width="w-full"
        ></Select>
      ) : null}
    </div>
  );
};

export default DataSelectMenu;

/* eslint-disable react/display-name */
import React, {
  useRef,
  useState,
  MouseEvent,
  useMemo,
  useImperativeHandle,
  ElementType,
} from "react";
import ButtonDrip from "./button-drip";
import ButtonLoading from "./button-loading";
import { filterPropsWithGroup, getButtonChildrenWithIcon } from "./utils";
import {
  getButtonColors,
  getButtonCursor,
  getButtonDripColor,
  getButtonSize,
} from "./styles";
import { ButtonNormalSizes, ButtonTypes } from "../../lib/util-types";
import { useButtonGroupContext } from "../button-group/button-group-context";
import s from "./button.module.css";

interface Props {
  elementType?: ElementType;
  href?: string;
  target?: string;
  type?: ButtonTypes;
  size?: ButtonNormalSizes;
  ghost?: boolean;
  modalBtn?: boolean;
  iconButton?: boolean;
  rounded?: boolean;
  loading?: boolean;
  shadow?: boolean;
  auto?: boolean;
  effect?: boolean;
  disabled?: boolean;
  htmlType?: React.ButtonHTMLAttributes<any>["type"];
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  iconClassName?: string;
}

type NativeAttrs = Omit<React.ButtonHTMLAttributes<any>, keyof Props>;
export type ButtonProps = Props & NativeAttrs;

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps>
>(({ ...btnProps }, ref: React.Ref<HTMLButtonElement | null>) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useImperativeHandle(ref, () => buttonRef.current);

  const [dripShow, setDripShow] = useState<boolean>(false);
  const [dripX, setDripX] = useState<number>(0);
  const [dripY, setDripY] = useState<number>(0);
  const groupConfig = useButtonGroupContext();
  const filteredProps = filterPropsWithGroup(btnProps, groupConfig);
  const {
    children,
    elementType: ElementType = "button",
    onClick,
    icon,
    iconRight,
    type = "default" as ButtonTypes,
    size = "medium" as ButtonNormalSizes,
    htmlType = "button" as React.ButtonHTMLAttributes<any>["type"],
    ghost = false,
    modalBtn = false,
    rounded = false,
    iconButton = false,
    loading = false,
    shadow = false,
    auto = false,
    effect = false,
    disabled = false,
    className = "",
    iconClassName = "",
    ...props
  } = filteredProps;
  const colors = useMemo(() => getButtonColors(filteredProps), [filteredProps]);

  const cursor = useMemo(
    () => getButtonCursor(disabled, loading),
    [disabled, loading]
  );
  const sizeClass = useMemo(
    () => getButtonSize(size, auto, iconButton),
    [size, auto, iconButton]
  );
  const dripColor = useMemo(
    () => getButtonDripColor(filteredProps),
    [filteredProps]
  );

  /* istanbul ignore next */
  const dripCompletedHandle = () => {
    setDripShow(false);
    setDripX(0);
    setDripY(0);
  };

  const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled || loading) return;
    const showDrip = !shadow && !ghost && effect;
    /* istanbul ignore next */
    if (showDrip && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDripShow(true);
      setDripX(event.clientX - rect.left);
      setDripY(event.clientY - rect.top);
    }

    onClick && onClick(event);
  };

  const childrenWithIcon = useMemo(
    () =>
      getButtonChildrenWithIcon(
        auto,
        iconButton,
        size,
        children,
        {
          icon,
          iconRight,
        },
        iconClassName
      ),
    [auto, iconButton, size, children, icon, iconRight, iconClassName]
  );
  // const splitColors = colors.split(" ");

  // let textColors = "";
  // splitColors.forEach((el) => {
  //   if (el.includes("text-")) {
  //     textColors += " " + el.replace("text", "bg");
  //   }
  // });

  let buttonStyles = ``;

  if (modalBtn) {
    buttonStyles = `modalBtn ${s.modalBtn}
      ${disabled ? s.disabledBtn : ""}`;
  } else if (rounded) {
    buttonStyles = `${s.roundedBtn}
      ${sizeClass} ${type === "text" ? "px-0" : ""}
      ${colors}`;
  } else {
    buttonStyles = `
      ${sizeClass} ${type === "text" ? "px-0" : ""}
      ${colors}`;
  }

  return (
    <ElementType
      ref={buttonRef}
      type={ElementType === "button" ? htmlType : undefined}
      className={`btn ${s.btn} ${ElementType === "a" ? s.a : ""} ${cursor} ${
        shadow ? s.shadowBtn : ""
      }  ${
        iconRight && !loading ? "text-left" : "text-center justify-center"
      }  ${buttonStyles} ${className}`}
      disabled={disabled}
      onClick={clickHandler}
      {...props}
    >
      {loading && <ButtonLoading />}
      {childrenWithIcon}
      {dripShow && (
        <ButtonDrip
          x={dripX}
          y={dripY}
          color={dripColor}
          onCompleted={dripCompletedHandle}
        />
      )}
    </ElementType>
  );
});

export default Button;

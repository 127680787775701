import useSelectedStores from "@lib/hooks/use-selected-stores";
import { Mutable, SelectTypes } from "@lib/util-types";
import { Select } from "@ui";
import { selectedMarketingChannelsAtom } from "atoms";
import { AVAILABLE_MARKETING_CHANNEL_OPTIONS } from "constants/constants";
import { useAtom } from "jotai";
import React, { useEffect, useMemo } from "react";

type Props = {
  selectType?: SelectTypes;
  alwaysIncludeValue?: (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"];
  alwaysOneValue?: boolean;
  onOneSelectedFailure?: () => void;
};

const ChannelSelectMenu: React.FC<Props> = ({
  selectType,
  alwaysIncludeValue,
  alwaysOneValue,
  onOneSelectedFailure,
}) => {
  const availableChanels = AVAILABLE_MARKETING_CHANNEL_OPTIONS;
  const [selectedMarketingChannels, setSelectedMarketingChannels] = useAtom(
    selectedMarketingChannelsAtom
  );

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const eventValue = Object.values(e.target?.selectedOptions).map(
      (option) => option.value
    ) as Array<(typeof availableChanels)[number]["value"]>;
    setSelectedMarketingChannels(eventValue);
  };

  const isDeselectable = useMemo(
    () => selectedMarketingChannels.length > availableChanels.length / 2,
    [selectedMarketingChannels, availableChanels.length]
  );

  const handleSelection = () => {
    setSelectedMarketingChannels(() =>
      availableChanels.map((option) => option.value)
    );
  };

  const handleDeselection = () => {
    if (!isDeselectable) {
      return;
    }

    const filterOption = alwaysIncludeValue || availableChanels[0].value;
    setSelectedMarketingChannels(
      availableChanels
        .map((option) => option.value)
        .filter((option) => option === filterOption)
    );
  };

  return (
    <Select
      id="selected-marketing-channels-select"
      name="selected-marketing-channels"
      value={selectedMarketingChannels}
      initialValue={selectedMarketingChannels}
      placeholder="Selected Marketing Channels"
      label="Touchpoints:"
      className="bg-gray-700"
      multiple={true}
      type={selectType}
      onChange={onChange}
      options={availableChanels as Mutable<typeof availableChanels>}
      labelHelper={
        isDeselectable ? (
          <span onClick={handleDeselection}>Deselect all</span>
        ) : (
          <span onClick={handleSelection}>Select all</span>
        )
      }
      width="w-full"
      alwaysIncludeValue={alwaysIncludeValue}
      alwaysOneValue={alwaysOneValue}
      onOneSelectedFailure={onOneSelectedFailure}
    ></Select>
  );
};

export default ChannelSelectMenu;

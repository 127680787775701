import { TableTypes } from "@components/Commons/ColumnSelector/ColumnSelectorDialog";
import { CooperationChannels } from "@lib/api-hooks/useCooperations";
import { UseAnalyticsProps } from "@lib/api-hooks";
import { Mutable } from "@lib/util-types";
import { FilterTypesEnum } from "interface/AdsFilterInterface";
import { ChartBarSquareIcon } from "@heroicons/react/24/outline";
import { AnalyticsFilterType } from "@api/services/analytics";
import ChartLineIcon from "@components/Layout/icons/chart-line";
import { BaseAnalytics } from "@api/types/backendTypes";

export const emptyBaseAnalytics: BaseAnalytics = {
  ads: new Map(),
  adsets: new Map(),
  campaigns: new Map(),
  totals: new Map(),
};

export const TRACIFY_APP_STATE_VAR = "TRACIFY_APP_STATE";

export const GIT_REPO_SLUG = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;
export const MARKETING_CHANNELS_VIEWS = ["ads-manager", "dashboard"];
export const MARKETING_CHANNELS_VIEW_VAR = "MARKETING_CHANNELS_VIEW";
export const DEFAULT_MARKETING_CHANNELS_VIEW: (typeof MARKETING_CHANNELS_VIEWS)[number] =
  "dashboard";

export const DEPLOY_ENVIRONMENT =
  GIT_REPO_SLUG === "main"
    ? "production"
    : GIT_REPO_SLUG === "beta"
      ? "beta"
      : GIT_REPO_SLUG === "demo"
        ? "demo"
        : "development";

export const START_DATE_RANGE_SELECT_OPTIONS_BASIC = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 days", value: "last_7_days" },
  { label: "Last 14 days", value: "last_14_days" },
  { label: "Last 30 days", value: "last_30_days" },
  { label: "This Month", value: "this_month" },
  { label: "Last Month", value: "last_month" },
] as const;

export const START_DATE_RANGE_SELECT_OPTIONS_EXTRA = [
  { label: "Last 3 months", value: "last_3_months" },
  { label: "Last 6 months", value: "last_6_months" },
  { label: "Last 1 year", value: "last_1_year" },
  { label: "Custom", value: "Custom" },
] as const;

export const START_DATE_RANGE_SELECT_OPTIONS_FULL = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 days", value: "last_7_days" },
  { label: "Last 10 days", value: "last_10_days" },
  { label: "Last 14 days", value: "last_14_days" },
  { label: "Last 15 days", value: "last_15_days" },
  { label: "Last 30 days", value: "last_30_days" },
  { label: "This Month", value: "this_month" },
  { label: "Last Month", value: "last_month" },
  { label: "Last 3 months", value: "last_3_months" },
  { label: "Last 6 months", value: "last_6_months" },
  { label: "Last 1 year", value: "last_1_year" },
  { label: "Custom", value: "Custom" },
] as const;

export const PREVIOUS_DATE_RANGE_SELECT_OPTIONS = [
  { label: "Previous Period", value: "last_period" },
  { label: "Last Month", value: "last_month" },
  { label: "Last Year", value: "last_year" },
] as const;

export const ATTRIBUTION_WINDOW_OPTIONS = [
  {
    label: "1d",
    value: "1",
  },
  {
    label: "2d",
    value: "2",
  },
  {
    label: "7d",
    value: "7",
  },
  {
    label: "14d",
    value: "14",
  },
  {
    label: "28d",
    value: "28",
  },
  {
    label: "60d",
    value: "60",
  },
  {
    label: "90d",
    value: "90",
  },
] as const;

export const ATTRIBUTION_MODEL_OPTIONS = [
  {
    label: "Linear",
    value: "linear",
  },
  {
    label: "First Click",
    value: "first",
  },
  {
    label: "Last Click",
    value: "last",
  },
  {
    label: "U-Shape",
    value: "ushape",
  },
] as const;
export const CREATIVE_ATTRIBUTION_OPTIONS = [
  {
    label: "Isolated",
    value: "isolated",
  },
  ...ATTRIBUTION_MODEL_OPTIONS,
] as const;

export const CHANNELS_WITHOUT_BASESCORE_TYPE = [
  "influencer",
  "influencer_module",
  "email",
  "referred",
  "referral",
  "whatsapp",
  "direct",
];

export const AVAILABLE_MARKETING_CHANNEL_OPTIONS = [
  {
    label: "Meta",
    value: "facebook",
  },
  {
    label: "Google",
    value: "google",
  },
  {
    label: "TikTok",
    value: "tiktok",
  },
  {
    label: "Influencer",
    value: "influencer",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "WhatsApp",
    value: "whatsapp",
  },
  {
    label: "Microsoft Ads",
    value: "msads",
  },

  {
    label: "Pinterest",
    value: "pinterest",
  },
  {
    label: "Snapchat",
    value: "snapchat",
  },

  {
    label: "Taboola",
    value: "taboola",
  },
  {
    label: "Outbrain",
    value: "outbrain",
  },
  {
    label: "Criteo",
    value: "criteo",
  },
  {
    label: "Organic",
    value: "referred",
  },
  {
    label: "Direct",
    value: "direct",
  },
];

export const AVAILABLE_INFLUENCER_SOURCE_OPTIONS = [
  {
    label: "TikTok",
    value: "tiktok",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "YouTube",
    value: "youtube",
  },
  {
    label: "Blog",
    value: "blog",
  },
  {
    label: "Twitch",
    value: "twitch",
  },
  {
    label: "Pinterest",
    value: "pinterest",
  },
  {
    label: "LinkedIn",
    value: "linkedin",
  },
  {
    label: "Snapchat",
    value: "snapchat",
  },
  {
    label: "Podcast",
    value: "podcast",
  },
];

export const AVAILABLE_GOOGLE_CHANNEL_OPTIONS = [
  {
    label: "Perf. Max",
    value: "PERFORMANCE_MAX",
  },
  {
    label: "Search",
    value: "SEARCH",
  },
  {
    label: "Demand Gen",
    value: "DISCOVERY",
  },
  {
    label: "Display",
    value: "DISPLAY",
  },
  {
    label: "Shopping",
    value: "SHOPPING",
  },
  {
    label: "Local",
    value: "LOCAL",
  },
  {
    label: "Local Services",
    value: "LOCAL_SERVICES",
  },
  {
    label: "Multi Channel",
    value: "MULTI_CHANNEL",
  },
  {
    label: "Smart",
    value: "SMART",
  },
  {
    label: "Unknown",
    value: "UNKNOWN",
  },
  {
    label: "Unspecified",
    value: "UNSPECIFIED",
  },
  {
    label: "Video",
    value: "VIDEO",
  },
  {
    label: "Catalog Sales",
    value: "CATALOG_SALES",
  },
] as const;

export const REPORT_TIME_OPTIONS = [
  {
    label: "Conversion Date",
    value: "event",
  },
  {
    label: "Click Date",
    value: "adview",
  },
] as const;

export const COLUMN_OPTIONS = [
  { label: "Spent", value: "spend" },
  { label: "Fixed Cost", value: "fixedSpent" },
  { label: "Variable Cost", value: "variableSpent" },
  { label: "Orders", value: "purchaseCount" },
  { label: "Labels", value: "labels" },
  { label: "Revenue", value: "purchaseAmount" },
  { label: "ROAS", value: "roas" },
  { label: "ROI", value: "roi" },
  { label: "Cost", value: "cost" },
  { label: "CAC", value: "cac" },
  { label: "New Customer Rate", value: "newCustomerRate" },
  { label: "New Visitor Rate", value: "newVisitorRate" },
  { label: "CPO", value: "cpo" },
  { label: "CTR", value: "ctr" },
  { label: "Average Order Value", value: "aov" },
  { label: "Conversion Rate", value: "cr" },
  { label: "CPM", value: "cpm" },
  { label: "Frequency", value: "frequency" },
  { label: "Clicks", value: "clicks" },
  { label: "Link Clicks", value: "linkClick" },
  { label: "Google Conversions", value: "platformConversions" },
  { label: "All Google Conversions", value: "allPlatformConversions" },
  { label: "CPC", value: "cpc" },
  { label: "Unique Adds to Cart", value: "addtocart" },
  { label: "Unique Checkouts Initiated", value: "checkout" },
  { label: "Unique Content Views", value: "productview" },
  { label: "Unique Page Views", value: "pageview" },
  { label: "Impressions", value: "impressions" },
  { label: "Reach", value: "reach" },
  { label: "ø time to sale", value: "avgConversionTime" },
  { label: "New Customer Rate", value: "ncr" },
  { label: "% of Customers", value: "customerPercentage" },
  { label: "Steps", value: "steps" },
  { label: "Comments", value: "comment" },
  { label: "Post Saves", value: "onsiteConversionPostSave" },
  { label: "Post Shares", value: "post" },
  { label: "Avg. Watch Time", value: "videoAvgTimeWatchedActions" },
  { label: "3s Video Views", value: "videoView" },
  { label: "Thumbstop Ratio", value: "thumbstopRatio" },
  { label: "Acquisition ROAS", value: "acquisitionRoas" },
];

// for the general dashboard mode
export const GENERAL_COLUMN_OPTIONS = [
  { label: "Spent", value: "spend" },
  { label: "Conversions", value: "purchaseCount" },
  { label: "Conv. value", value: "purchaseAmount" },
  { label: "ROI", value: "roas" },
  { label: "ROI", value: "roi" },
  { label: "Cost", value: "cost" },
  { label: "CAC", value: "cac" },
  { label: "New Customer Rate", value: "newCustomerRate" },
  { label: "New Visitor Rate", value: "newVisitorRate" },
  { label: "CPA", value: "cpo" },
  { label: "CTR", value: "ctr" },
  { label: "Average Conversion Value", value: "aov" },
  { label: "Conversion Rate", value: "cr" },
  { label: "CPM", value: "cpm" },
  { label: "Frequency", value: "frequency" },
  { label: "Clicks", value: "clicks" },
  { label: "Link Clicks", value: "linkClick" },
  { label: "CPC", value: "cpc" },
  { label: "Unique Adds to Cart", value: "addtocart" },
  { label: "Unique Checkouts Initiated", value: "checkout" },
  { label: "Unique Content Views", value: "productview" },
  { label: "Unique Page Views", value: "pageview" },
  { label: "Impressions", value: "impressions" },
  { label: "Reach", value: "reach" },
  { label: "ø time to sale", value: "avgConversionTime" },
  { label: "New Customer Rate", value: "ncr" },
  { label: "% of Customers", value: "customerPercentage" },
  { label: "Steps", value: "steps" },
  { label: "Comments", value: "comment" },
  { label: "Post Saves", value: "onsiteConversionPostSave" },
  { label: "Post Shares", value: "post" },
  { label: "Avg. Watch Time", value: "videoAvgTimeWatchedActions" },
  { label: "3s Video Views", value: "videoView" },
  { label: "Thumbstop Ratio", value: "thumbstopRatio" },
  { label: "Acquisition ROI", value: "acquisitionRoas" },
];

export const NVR_OPTIONS = [
  { label: "All Users", value: "default" },
  { label: "New Users", value: "new" },
  { label: "Returning Users", value: "returning" },
  { label: "New & Returning Users", value: "all" },
] as const;

export const AVAILABLE_COLUMNS_PER_TABLE: Map<
  TableTypes,
  Array<(typeof COLUMN_OPTIONS)[number]["value"]>
> = new Map([
  [
    "realtime",
    [
      "pageview",
      "productview",
      "purchaseAmount",
      "purchaseCount",
      "addtocart",
      "aov",
      "cr",
    ],
  ],
  [
    "marketingChannelOverview",
    [
      "aov",
      "cr",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "roas",
      "addtocart",
      "pageview",
      "productview",
      "purchaseCount",
      "purchaseAmount",
      "spend",
    ],
  ],
  [
    "creativesOverview",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
    ],
  ],
  [
    "pinterestCreativesOverview",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "impressions",
    ],
  ],
  [
    "facebookCreativesOverview",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "linkClick",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
      "newCustomerRate",
      "newVisitorRate",
    ],
  ],
  [
    "googleChannels",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
    ],
  ],
  [
    "adsManager",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
    ],
  ],
  [
    "pinterestAdsManager",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "impressions",
    ],
  ],
  [
    "facebookAdsManager",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "linkClick",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
      "newCustomerRate",
      "newVisitorRate",
    ],
  ],
  [
    "googleAdsManager",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "impressions",
      "platformConversions",
      "allPlatformConversions",
    ],
  ],
  [
    "influencer",
    [
      "purchaseCount",
      "purchaseAmount",
      "spend",
      "fixedSpent",
      "variableSpent",
      "roas",
      "addtocart",
      "productview",
      "pageview",
      "aov",
      "cr",
      "cpo",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      // "name",
    ],
  ],
  [
    "cooperations",
    [
      "purchaseCount",
      "purchaseAmount",
      "spend",
      "labels",
      "fixedSpent",
      "variableSpent",
      "roas",
      "addtocart",
      "productview",
      "pageview",
      "aov",
      "cr",
      "cpo",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      // "name",
    ],
  ],
  [
    "influencerChannelOverview",
    [
      "aov",
      "cr",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "roas",
      "addtocart",
      "pageview",
      "productview",
      "purchaseCount",
      "purchaseAmount",
      "spend",
    ],
  ],
  [
    "creativeComparison",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "cpc",
      "cpm",
      "ctr",
      "clicks",
      "aov",
      "cr",
      "frequency",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "addtocart",
      "reach",
      "impressions",
      "productview",
      "pageview",
    ],
  ],
  [
    "facebookCreativeComparison",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "cpc",
      "cpm",
      "ctr",
      "linkClick",
      "aov",
      "cr",
      "frequency",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "addtocart",
      "thumbstopRatio",
      "comment",
      "onsiteConversionPostSave",
      "post",
      "reach",
      "impressions",
      "videoAvgTimeWatchedActions",
      "videoView",
      "productview",
      "pageview",
    ],
  ],
  [
    "facebookCreativeAnalysis",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "linkClick",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
      "comment",
      "onsiteConversionPostSave",
      "post",
      "videoAvgTimeWatchedActions",
      "videoView",
      "thumbstopRatio",
    ],
  ],
  [
    "creativeAnalysis",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
    ],
  ],
]);

export const maxMetricCountForTables: Record<string, number> = {
  facebookCreativeAnalysis: 10,
  creativeAnalysis: 10,
};

export const chartTooltipStyles = {
  backgroundColor: "var(--gray-800)",
  borderColor: "var(--gray-500)",
  borderRadius: "0.5rem",
  padding: "1rem",
};

export const CONSTANT_LABELS: { [key: string]: string } = {
  meta: "Meta",
  facebook: "Meta",
  google: "Google",
  tiktok: "TikTok",
  instagram: "Instagram",
  threads: "Threads",
  twitter: "Twitter / X",
  linkedin: "LinkedIn",
  snapchat: "Snapchat",
  youtube: "YouTube",
  twitch: "Twitch",
  podcast: "Podcast",
  blog: "Blog",
  other: "Other",
  email: "Email",
  influencer: "Influencer",
  influencer_module: "Influencer",
  organic: "Organic",
  referred: "Organic",
  referral: "Referral",
  taboola: "Taboola",
  outbrain: "Outbrain",
  pinterest: "Pinterest",
  whatsapp: "WhatsApp",
  direct: "Direct",
  msads: "Microsoft",
  criteo: "Criteo",
  purchaseAmount: "Revenue",
  purchaseCount: "Orders",
  spend: "Spent",
  fixedSpent: "Fixed Cost",
  variableSpent: "Variable Cost",
  productview: "Unique Content Views",
  pageview: "Unique Page Views",
  addtocart: "Unique Adds to Cart",
  checkout: "Unique Checkouts Initiated",
  roas: "ROAS",
  mer: "MER",
  cac: "CAC",
  cpo: "CPO",
  aov: "Average Order Value",
  cr: "Conversion Rate",
  new_pageview: "New visitors",
  returning_pageview: "Returning visitors",
  new_customers: "New Customers",
  returning_customers: "Returning Customers",
  all_customers: "All Customers",
  story: "Story",
  post: "Post",
  reel: "Reel",
  live: "Live",
  video: "Video",
  short: "Short",
  pin: "Pin",
};

export const GENERAL_CONSTANT_LABELS: { [key: string]: string } = {
  ...CONSTANT_LABELS,
  purchaseAmount: "Conv. value",
  purchaseCount: "Conversions",
  roas: "ROI",
  cpo: "CPA",
  aov: "ACV",
};

export const METRICS_LABELS: { [key: string]: string } = {
  purchaseAmount: "Revenue",
  purchaseCount: "Orders",
  spend: "Spent",
  ctr: "CTR",
  productview: "uCV",
  pageview: "uPV",
  addtocart: "uATC",
  checkout: "uIC",
  roas: "ROAS",
  mer: "MER",
  cac: "CAC",
  cpo: "CPO",
  compared_cpo: "Previous CPO",
  compared_mer: "Previous MER",
  compared_cac: "Previous CAC",
  aov: "AOV",
  cpm: "CPM",
  frequency: "Frequency",
  clicks: "Clicks",
  linkClick: "Clicks (link)",
  platformConversions: "Google Conversions",
  allPlatformConversions: "All Google Conversions",
  cpc: "CPC",
  newCustomerRate: "New Customer Rate",
  newVisitorRate: "New Visitor Rate",
  thumbstopRatio: "Thumbstop Ratio",
  videoView: "Video Views",
  fixedSpent: "Fixed Cost",
  variableSpent: "Variable Cost",
  videoAvgTimeWatchedActions: "Avg. Watch Time",
  onsiteConversionPostSave: "Post Saves",
  comment: "Comments",
  post: "Post Shares",
  reach: "Reach",
  impressions: "Impressions",
  cr: "Conversion Rate",
  new_pageview: "New visitors",
  returning_pageview: "Returning visitors",
  new_customers: "New Customers",
  all_customers: "All Customers",
  returning_customers: "Returning Customers",
};

export const METRICS_BIGGER_IS_BETTER: { [key: string]: boolean } = {
  purchaseAmount: true,
  purchaseCount: true,
  spend: true,
  ctr: true,
  productview: true,
  pageview: true,
  addtocart: true,
  checkout: true,
  roas: true,
  mer: true,
  cac: false,
  cpo: false,
  compared_cpo: false,
  compared_mer: false,
  compared_cac: false,
  aov: true,
  cpm: false,
  frequency: true,
  clicks: true,
  linkClick: true,
  platformConversions: true,
  allPlatformConversions: true,
  cpc: false,
  newCustomerRate: true,
  newVisitorRate: true,
  thumbstopRatio: true,
  videoView: true,
  fixedSpent: true,
  variableSpent: true,
  videoAvgTimeWatchedActions: true,
  onsiteConversionPostSave: false,
  comment: true,
  post: true,
  reach: true,
  impressions: true,
  cr: true,
  new_pageview: true,
  returning_pageview: true,
  new_customers: true,
  all_customers: true,
  returning_customers: true,
};

export const GENERAL_METRICS_LABELS: { [key: string]: string } = {
  ...METRICS_LABELS,
  purchaseAmount: "Conv. value",
  purchaseCount: "Conversions",
  roas: "ROI",
  cpo: "CPA",
  compared_cpo: "Previous CPA",
  aov: "ACV",
};

export const METRICS_LABELS_INF: { [key: string]: string } = {
  ...METRICS_LABELS,
  spend: "Cost",
  roas: "ROID",
};
export const GENERAL_METRICS_LABELS_INF: { [key: string]: string } = {
  ...METRICS_LABELS_INF,
  purchaseAmount: "Conv. value",
  purchaseCount: "Conversions",
  roas: "ROID",
  cpo: "CPA",
  aov: "ACV",
};

export const METRIC_SUFFIXES: { [key: string]: string } = {
  purchaseAmount: "€",
  purchaseCount: "",
  spend: "€",
  ctr: "%",
  productview: "",
  pageview: "",
  addtocart: "",
  checkout: "",
  roas: "",
  mer: "",
  cac: "€",
  cpo: "€",
  aov: "€",
  cpm: "€",
  frequency: "",
  clicks: "",
  linkClick: "",
  cpc: "€",
  reach: "",
  impressions: "",
  cr: "%",
  newCustomerRate: "%",
  newVisitorRate: "%",
  thumbstopRatio: "%",
  comment: "",
  onsiteConversionPostSave: "",
  post: "",
  videoAvgTimeWatchedActions: "s",
  videoView: "",
};

export const METRIC_DECIMALS: { [key: string]: number } = {
  purchaseAmount: 2,
  purchaseCount: 0,
  spend: 2,
  ctr: 2,
  productview: 0,
  pageview: 0,
  addtocart: 0,
  checkout: 0,
  roas: 2,
  mer: 2,
  cac: 2,
  cpo: 2,
  aov: 2,
  cpm: 2,
  frequency: 0,
  clicks: 0,
  linkClick: 0,
  cpc: 2,
  reach: 0,
  impressions: 0,
  cr: 2,
  newCustomerRate: 2,
  newVisitorRate: 2,
  thumbstopRatio: 2,
  comment: 0,
  onsiteConversionPostSave: 0,
  post: 0,
  videoAvgTimeWatchedActions: 1,
  videoView: 0,
};
export const METRIC_DECIMALS_LINEAR_USHAPE: { [key: string]: number } = {
  ...METRIC_DECIMALS,
  purchaseCount: 2,
  productview: 2,
  pageview: 2,
  addtocart: 2,
};

export const CHANNEL_URL_PARAMS: { [key in CooperationChannels]: string } = {
  facebook: "fb",
  tiktok: "tt",
  instagram: "ig",
  linkedin: "lin",
  snapchat: "sc",
  youtube: "yt",
  twitch: "ttv",
  podcast: "pod",
  blog: "blg",
  pinterest: "pin",
  twx: "twx",
  threads: "thr",
  other: "other",
};

export const AXIS_VALUES = [
  1, 1.5, 2, 3, 4, 5, 6, 8, 10, 25, 50, 75, 100, 250, 500, 750, 1000, 2500,
  5000, 7500, 10000, 25000, 50000, 75000, 100000, 250000, 500000, 750000,
  1000000,
];
export const FINE_GRAINED_AXIS_VALUES = [
  1, 1.5, 2, 3, 4, 5, 6, 8, 10, 12, 15, 18, 20, 25, 30, 40, 50, 75, 100, 125,
  150, 200, 250, 300, 400, 500, 750, 1000, 1250, 1500, 2000, 2500, 3000, 4000,
  5000, 7500, 10000, 12500, 15000, 20000, 25000, 30000, 40000, 50000, 75000,
  100000, 125000, 150000, 200000, 250000, 400000, 500000, 750000, 1000000,
];

export const DASHBOARD_APPROVALS = ["access_rts_dashboard"] as const;

export type FilterTypes = {
  label: string;
  value: string;
  type: string;
  availableFilter?: FilterTypesEnum[];
  hidden?: boolean;
  inputLabel?: string;
  options?: Array<{ label: string; value: any }>;
};

export const ADS_MANAGER_FILTERS = [
  {
    label: "Campaign Name",
    value: "campaigns",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad group Name",
    value: "adsets",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  { label: "Spent", value: "spend", inputLabel: "€", type: "number" },
  {
    label: "Revenue",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  { label: "ROAS", value: "roas", type: "number" },
  { label: "CTR", value: "ctr", inputLabel: "%", type: "number" },
  { label: "CAC", value: "cac", inputLabel: "€", type: "number" },
  { label: "CPO", value: "cpo", inputLabel: "€", type: "number" },
  { label: "Campaign Id", value: "campaignIds", type: "text" },
  { label: "Ad group Id", value: "adsetIds", type: "text" },
  { label: "Ad Id", value: "adIds", type: "text" },
  {
    label: "Channel Type",
    value: "advertisingChannelType",
    type: "text",
    availableFilter: [FilterTypesEnum.contains],
    hidden: true,
  },
  // {
  //   label: "Conversion Rate",
  //   value: "conversionRate",
  //   inputLabel: "%",
  //   type: "number",
  // },
];

export const GENERAL_ADS_MANAGER_FILTERS = [
  {
    label: "Campaign Name",
    value: "campaigns",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad group Name",
    value: "adsets",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  { label: "Spent", value: "spend", inputLabel: "€", type: "number" },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  { label: "ROI", value: "roas", type: "number" },
  { label: "CTR", value: "ctr", inputLabel: "%", type: "number" },
  { label: "CAC", value: "cac", inputLabel: "€", type: "number" },
  { label: "CPA", value: "cpo", inputLabel: "€", type: "number" },
  { label: "Campaign Id", value: "campaignIds", type: "text" },
  { label: "Ad group Id", value: "adsetIds", type: "text" },
  { label: "Ad Id", value: "adIds", type: "text" },
  {
    label: "Channel Type",
    value: "advertisingChannelType",
    type: "text",
    availableFilter: [FilterTypesEnum.contains],
    hidden: true,
  },
  // {
  //   label: "Conversion Rate",
  //   value: "conversionRate",
  //   inputLabel: "%",
  //   type: "number",
  // },
];

export const CONVERSION_PATH_FILTERS = [
  {
    label: "Number of touchpoints",
    value: "steps",
    inputLabel: "",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },

  {
    label: "First touchpoint",
    value: "firstTouchpoint",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Last touchpoint",
    value: "lastTouchpoint",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Included touchpoint",
    value: "path",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Revenue",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  {
    label: "Orders",
    value: "purchaseCount",
    inputLabel: "",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Average Order Value",
    value: "aov",
    type: "number",
    inputLabel: "€",
  },
  {
    label: "Conversion Rate",
    value: "cr",
    inputLabel: "%",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "ø time to sale",
    value: "avgConversionTime",
    inputLabel: "hours",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  // {
  //   label: "New customer rate",
  //   value: "ncr",
  //   inputLabel: "%",
  //   type: "number",
  //   availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  // },
  {
    label: "% of Customers",
    value: "customerPercentage",
    inputLabel: "%",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
];

export const GENERAL_CONVERSION_PATH_FILTERS = [
  {
    label: "Number of touchpoints",
    value: "steps",
    inputLabel: "",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "First touchpoint",
    value: "firstTouchpoint",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Last touchpoint",
    value: "lastTouchpoint",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  {
    label: "Conversions",
    value: "purchaseCount",
    inputLabel: "",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Average Conversion Value",
    value: "aov",
    type: "number",
    inputLabel: "€",
  },
  {
    label: "Conversion Rate",
    value: "cr",
    inputLabel: "%",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "ø time to conversion",
    value: "avgConversionTime",
    inputLabel: "hours",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "% of Customers",
    value: "customerPercentage",
    inputLabel: "%",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
];

export const MAPPED_FILTER_OPERATORS = {
  "⊆": FilterTypesEnum.contains,
  "~⊆": FilterTypesEnum.contains_not,
  ">": FilterTypesEnum.gt,
  "<": FilterTypesEnum.lt,
};

export const CREATIVE_SEGMENTS_FILTERS = [
  {
    label: "Campaign Name",
    value: "campaigns",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Adset Name",
    value: "adsets",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Spent",
    value: "spend",
    inputLabel: "€",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Revenue",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Orders",
    value: "purchaseCount",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Impressions",
    value: "impressions",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
];
export const GENERAL_CREATIVE_SEGMENTS_FILTERS = [
  {
    label: "Campaign Name",
    value: "campaigns",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Adset Name",
    value: "adsets",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Spent",
    value: "spend",
    inputLabel: "€",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Conversions",
    value: "purchaseCount",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Impressions",
    value: "impressions",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
];

export const CREATIVES_FILTERS = [
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Creative Format",
    value: "creativeFormat",
    type: "select",
    options: [
      { label: "Image", value: "image" },
      { label: "Video", value: "video" },
    ],
  },
  { label: "Spent", value: "spend", inputLabel: "€", type: "number" },
  {
    label: "Revenue",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  { label: "Impressions", value: "impressions", type: "number" },
  { label: "ROAS", value: "roas", type: "number" },
  { label: "CTR", value: "ctr", inputLabel: "%", type: "number" },
  { label: "CAC", value: "cac", inputLabel: "€", type: "number" },
  { label: "CPO", value: "cpo", inputLabel: "€", type: "number" },
];

export const GENERAL_CREATIVES_FILTERS = [
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Creative Format",
    value: "creativeFormat",
    type: "select",
    options: [
      { label: "Image", value: "image" },
      { label: "Video", value: "video" },
    ],
  },
  { label: "Spent", value: "spend", inputLabel: "€", type: "number" },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  { label: "Impressions", value: "impressions", type: "number" },
  { label: "ROI", value: "roas", type: "number" },
  { label: "CTR", value: "ctr", inputLabel: "%", type: "number" },
  { label: "CAC", value: "cac", inputLabel: "€", type: "number" },
  { label: "CPA", value: "cpo", inputLabel: "€", type: "number" },
];

export const ECOMMERCE_TO_GENERAL_LABEL_MAPPING = {
  Revenue: "Conv. value",
  Orders: "Conversions",
  ROAS: "ROI",
  CPO: "CPA",
};

export const STRINGS_TO_REMOVE_FROM_NAMES = [
  /( - copy)( \d{1,5})?/gi,
  /( - kopie)( \d{1,5})?/gi,
  /( - copie)( \d{1,5})?/gi,
  /( - copia)( \d{1,5})?/gi,
  /(copy)( \d{1,5})?/gi,
  /(kopie)( \d{1,5})?/gi,
  /(copie)( \d{1,5})?/gi,
  /(copia)( \d{1,5})?/gi,
  "/",
  ":",
  // countryCodeRegex,
];

// channel has to be defined in the value prop before _
// because we use it in other functions
export const INFLUENCER_PLACEMENT_TYPES = [
  { label: "Instagram Story", value: "instagram_story" },
  { label: "Instagram Post", value: "instagram_post" },
  { label: "Instagram Reel", value: "instagram_reel" },
  { label: "Instagram Live", value: "instagram_live" },
  { label: "TikTok Post", value: "tiktok_post" },
  { label: "TikTok Story", value: "tiktok_story" },
  { label: "Youtube Video", value: "youtube_video" },
  { label: "Youtube Short", value: "youtube_short" },
  { label: "Twitch Live", value: "twitch_live" },
  { label: "Pinterest Pin", value: "pinterest_pin" },
  { label: "Blog Post", value: "blog_post" },
  { label: "Podcast", value: "podcast" },
  { label: "LinkedIn Post", value: "linkedin_post" },
  { label: "Snapchat Story", value: "snapchat_story" },
  { label: "Facebook Post", value: "facebook_post" },
  { label: "Facebook Story", value: "facebook_story" },
  { label: "Twitter / X Post", value: "twx_post" },
  { label: "Threads Post", value: "threads_post" },
  { label: "Other", value: "other_post" },
] as const;

export const channelDashboardSettings = (
  nvrEnabled: boolean,

  options?: {
    creativeAttribution?: (typeof CREATIVE_ATTRIBUTION_OPTIONS)[number]["value"];
    creativeChannel?: "facebook" | "google" | "tiktok" | "pinterest";
  }
): UseAnalyticsProps => ({
  collectAdInfo: true,
  collectAdKpis: true,
  collectAdKpiTimeline: true,
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  fixedMarketingChannels:
    !options?.creativeAttribution || options?.creativeAttribution === "isolated"
      ? [options?.creativeChannel ?? "facebook"]
      : AVAILABLE_MARKETING_CHANNEL_OPTIONS.map((el) => el.value).filter(
          (el) => {
            return !["direct", "referred"].includes(el);
          }
        ),
  fixedAttributionModel:
    !options?.creativeAttribution || options?.creativeAttribution === "isolated"
      ? "last"
      : options?.creativeAttribution,
  fixedReportTime: "event",
  collectAdInfoFqMode: true,
  fullyQualifiedIds: true,
  enableCompare: true,
});
export const channelAdsManagerSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  views: nvrEnabled ? ["nvr_tc_aggregated"] : ["tc_aggregated"],
  collectAdInfo: true,
  collectAdInfoFqMode: true,
  collectAdKpis: true,
  fullyQualifiedIds: true,
  enableCompare: true,
});
export const realtimeDashboardSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  views: nvrEnabled ? ["nvr_tc_aggregated"] : ["tc_aggregated"],
  collectAdInfo: true,
  include: ["influencers", "cooperations"],
  collectAdInfoFqMode: true,
  fullyQualifiedIds: true,
  cooperationsAsAdsets: true,
  enableCompare: true,
});

export const marketingOverviewSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  fixedMarketingChannels: AVAILABLE_MARKETING_CHANNEL_OPTIONS.map(
    (el) => el.value
  ),
  include: ["influencers"],
  collectAdKpis: true,
  collectAdKpiTimeline: true,
  collectAdInfoFqMode: true,
  fullyQualifiedIds: true,
  enableCompare: true,
});

export const customerJourneySettings = (): UseAnalyticsProps => ({
  collectAdKpis: true,
  collectAdKpiTimeline: true,
  fixedMarketingChannels: AVAILABLE_MARKETING_CHANNEL_OPTIONS.map(
    (el) => el.value
  ),
  fixedReportTime: "event",
  fixedAttributionModel: "last",
  fixedAttributionWindow: "60",
  include: ["cooperations"],
  views: ["customer_journey"],
  useDefaultMarketingChannels: DEPLOY_ENVIRONMENT === "production",
});
export const influencerOverviewSettings = (
  nvrEnabled: boolean,

  options?: {
    creativeAttribution?: (typeof CREATIVE_ATTRIBUTION_OPTIONS)[number]["value"];
    creativeChannel?: "influencer";
  }
): UseAnalyticsProps => ({
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  fixedMarketingChannels:
    !options?.creativeAttribution || options?.creativeAttribution === "isolated"
      ? [options?.creativeChannel ?? "influencer"]
      : AVAILABLE_MARKETING_CHANNEL_OPTIONS.map((el) => el.value).filter(
          (el) => {
            return !["direct", "referred"].includes(el);
          }
        ),
  fixedAttributionModel:
    !options?.creativeAttribution || options?.creativeAttribution === "isolated"
      ? "last"
      : options?.creativeAttribution,
  fixedReportTime: "event",
  include: ["influencers", "cooperations"],
  collectAdKpis: false,
  collectAdKpiTimeline: false,
  collectAdInfoFqMode: false,
  fullyQualifiedIds: true,
  enableCompare: true,
});
export const singleInfluencerSettings = (
  nvrEnabled: boolean,

  filter?: AnalyticsFilterType
): UseAnalyticsProps => ({
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  filter: filter ?? undefined,
  collectAdKpis: false,
  collectAdKpiTimeline: false,
  collectAdInfoFqMode: false,
  fullyQualifiedIds: true,
  enableCompare: true,
});

export const influencerDashboardSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  views: nvrEnabled ? ["nvr_tc_aggregated"] : ["tc_aggregated"],
  collectAdInfo: false,
  collectAdInfoFqMode: false,
  collectAdKpis: false,
  collectAdKpiTimeline: false,
  enableCompare: true,
});

export const creativeDashboardSettings = (
  nvrEnabled: boolean,

  options?: {
    creativeAttribution?: (typeof CREATIVE_ATTRIBUTION_OPTIONS)[number]["value"];
    creativeChannel?: "facebook" | "tiktok";
  }
): UseAnalyticsProps => ({
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  fixedMarketingChannels:
    !options?.creativeAttribution || options?.creativeAttribution === "isolated"
      ? [options?.creativeChannel ?? "facebook"]
      : AVAILABLE_MARKETING_CHANNEL_OPTIONS.map((el) => el.value).filter(
          (el) => {
            return !["direct", "referred"].includes(el);
          }
        ),
  fixedAttributionModel:
    !options?.creativeAttribution || options?.creativeAttribution === "isolated"
      ? "last"
      : options?.creativeAttribution,
  fixedReportTime: "event",
  collectAdInfoFqMode: true,
  fullyQualifiedIds: true,
  collectAdInfo: true,
  collectAdKpis: true,
  collectAdKpiTimeline: true,
});

export const fixedSettingsPerDashboardRoute = (
  nvrEnabled: boolean,
  options?: {
    creativeAttribution?: (typeof CREATIVE_ATTRIBUTION_OPTIONS)[number]["value"];
    creativeChannel?: "facebook" | "tiktok";
  }
) => ({
  "/": realtimeDashboardSettings(nvrEnabled),
  "/channels/google/dashboard": channelDashboardSettings(nvrEnabled, {
    creativeAttribution: options?.creativeAttribution,
    creativeChannel: options?.creativeChannel,
  }),
  "/channels/google/ads-manager": channelAdsManagerSettings(nvrEnabled),
  "/channels/meta/dashboard": channelDashboardSettings(nvrEnabled, {
    creativeAttribution: options?.creativeAttribution,
    creativeChannel: options?.creativeChannel,
  }),
  "/channels/meta/ads-manager": channelAdsManagerSettings(nvrEnabled),
  "/channels/tiktok/dashboard": channelDashboardSettings(nvrEnabled, {
    creativeAttribution: options?.creativeAttribution,
    creativeChannel: options?.creativeChannel,
  }),
  "/channels/tiktok/ads-manager": channelAdsManagerSettings(nvrEnabled),
  "/influencers": influencerDashboardSettings(nvrEnabled),
  "/influencers/cooperations": influencerDashboardSettings(nvrEnabled),
  "/marketing/overview": marketingOverviewSettings(nvrEnabled),
  "/customer-journeys": customerJourneySettings(),
  "/creatives/comparison": creativeDashboardSettings(nvrEnabled, {
    creativeAttribution: options?.creativeAttribution,
    creativeChannel: options?.creativeChannel,
  }),
  "/creatives/analysis": creativeDashboardSettings(nvrEnabled, {
    creativeAttribution: options?.creativeAttribution,
    creativeChannel: options?.creativeChannel,
  }),
});

export const creativeComparisonColors = [
  "text-[#6254FF]",
  "text-[#4D73A3]",
  "text-[#9E5A54]",
  "text-[#46A070]",
  "text-[#AD648D]",
  "text-[#6491A3]",
  "text-[#9267A6]",
  "text-[#7657A4]",
];
export const pureCreativeComparisonColors = [
  "#6254FF",
  "#4D73A3",
  "#9E5A54",
  "#46A070",
  "#AD648D",
  "#6491A3",
  "#9267A6",
  "#7657A4",
];

export const creativeComparisonGradients = [
  "bg-gradient-to-r from-[#6254FF]",
  "bg-gradient-to-r from-[#4D73A3]",
  "bg-gradient-to-r from-[#9E5A54]",
  "bg-gradient-to-r from-[#46A070]",
  "bg-gradient-to-r from-[#AD648D]",
  "bg-gradient-to-r from-[#6491A3]",
  "bg-gradient-to-r from-[#9267A6]",
  "bg-gradient-to-r from-[#7657A4]",
];

export const CREATIVE_COMPARISON_OPTIONS = [
  { label: "Spent", value: "spend", suffix: "€" },
  {
    label: "Revenue",
    value: "purchaseAmount",
    suffix: "€",
  },
  { label: "ROAS", value: "roas" },
  { label: "CTR", value: "ctr", suffix: "%" },
  { label: "CAC", value: "cac", suffix: "€" },
  { label: "New Customer Rate", value: "newCustomerRate", suffix: "%" },
  { label: "CPO", value: "cpo", suffix: "€" },
  {
    label: "Conversion Rate",
    value: "cr",
    suffix: "%",
  },
];
// for general dashboard mode
export const GENERAL_CREATIVE_COMPARISON_OPTIONS = [
  { label: "Spent", value: "spend", suffix: "€" },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    suffix: "€",
  },
  { label: "ROI", value: "roas" },
  { label: "CTR", value: "ctr", suffix: "%" },
  { label: "CAC", value: "cac", suffix: "€" },
  { label: "New Customer Rate", value: "newCustomerRate", suffix: "%" },
  { label: "CPA", value: "cpo", suffix: "€" },
  {
    label: "Conversion Rate",
    value: "cr",
    suffix: "%",
  },
];

export const CREATIVE_CHART_OPTIONS = [
  { label: "Bar Chart", value: "bar", icon: ChartBarSquareIcon },
  {
    label: "Line Chart",
    value: "line",
    icon: ChartLineIcon,
  },
];

export const INFLUENCER_PERFORMANCE_OPTIONS = [
  { label: "Cost", value: "spend", suffix: "€" },
  {
    label: "Revenue",
    value: "purchaseAmount",
    suffix: "€",
  },
  {
    label: "Orders",
    value: "purchaseCount",
    suffix: "",
  },
  { label: "ROI", value: "roas" },
  {
    label: "Conversion Rate",
    value: "cr",
    suffix: "%",
  },
  { label: "CPO", value: "cpo", suffix: "€" },
  { label: "CAC", value: "cac", suffix: "€" },
  { label: "New Customer Rate", value: "newCustomerRate", suffix: "%" },
];

export const GENERAL_INFLUENCER_PERFORMANCE_OPTIONS = [
  { label: "Cost", value: "spend", suffix: "€" },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    suffix: "€",
  },
  {
    label: "Conversions",
    value: "purchaseCount",
    suffix: "",
  },
  { label: "ROI", value: "roas" },
  {
    label: "Conversion Rate",
    value: "cr",
    suffix: "%",
  },
  { label: "CPA", value: "cpo", suffix: "€" },
  { label: "CAC", value: "cac", suffix: "€" },
  { label: "New Customer Rate", value: "newCustomerRate", suffix: "%" },
];
export const pageLookup = {
  "/": "realtime dashboard",
  "/channels/meta/ads-manager": "meta ads manager",
  "/channels/google/ads-manager": "google ads manager",
  "/channels/tiktok/ads-manager": "tiktok ads manager",
  "/channels/pinterest/ads-manager": "pinterest ads manager",
};

import {
  ColumnItemType,
  TableTypes,
} from "@components/Commons/ColumnSelector/ColumnSelectorDialog";
import { atomWithQuery } from "@lib/atomWithQuery";
import { STORAGE_KEYS, availableDashboardModes } from "@lib/hooks/constants";
import { formatFilterToQuery, formatQueryToFilter } from "@lib/util-functions";
import { removeDuplicates } from "@lib/util-functions/array";
import { getTimeZones } from "@vvo/tzdb";
import {
  ATTRIBUTION_MODEL_OPTIONS,
  ATTRIBUTION_WINDOW_OPTIONS,
  AVAILABLE_COLUMNS_PER_TABLE,
  AVAILABLE_MARKETING_CHANNEL_OPTIONS,
  COLUMN_OPTIONS,
  CREATIVE_ATTRIBUTION_OPTIONS,
  CREATIVE_CHART_OPTIONS,
  CREATIVE_COMPARISON_OPTIONS,
  INFLUENCER_PLACEMENT_TYPES,
  INFLUENCER_PERFORMANCE_OPTIONS,
  PREVIOUS_DATE_RANGE_SELECT_OPTIONS,
  REPORT_TIME_OPTIONS,
  START_DATE_RANGE_SELECT_OPTIONS_FULL,
} from "constants/constants";
import dayjs from "dayjs";
import { AdsFilterInterface } from "interface/AdsFilterInterface";
import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import {
  getPreviousDateRange,
  getStartDateRange,
} from "services/DateRangeServices";

const previousDateRange = getPreviousDateRange(
  PREVIOUS_DATE_RANGE_SELECT_OPTIONS[0].value,
  { endDate: new Date().toISOString(), startDate: new Date().toISOString() }
);
export const dashboardAccessAtom = atom<boolean>(true);

export const atomsInitializedAtom = atom<boolean>(false);

export const showAttributionModeSurveyAtom = atom<boolean>(false);

export const currencyModalStatusAtom = atom<boolean>(false);

export const posthogFeatureFlagsAtom = atom<Record<string, any>>({});
const defaultTimerange = "last_7_days";
const defaultDateRange = getStartDateRange(defaultTimerange);

export const startTimeAtom = atomWithQuery(
  "startTime",
  dayjs(defaultDateRange.startDate).startOf("day").toISOString(),
  {
    shallow: true,
    deserialize: (str) => {
      // sometimes we have a space in the time string to indicate the timezone offset
      // but here we are only interested in the pure date and use the local user timezone anyway
      const onlyDateString = (
        typeof str === "string" ? str : dayjs().startOf("day").toISOString()
      )?.split(" ")[0];
      return onlyDateString
        ? dayjs(new Date(onlyDateString)).startOf("day").toISOString()
        : dayjs(defaultDateRange.startDate).startOf("day").toISOString();
    },
    replaceState: true,
  }
);
export const endTimeAtom = atomWithQuery(
  "endTime",
  dayjs(defaultDateRange.endDate).endOf("day").toISOString(),
  {
    shallow: true,
    deserialize: (str) => {
      const onlyDateString = (
        typeof str === "string" ? str : dayjs().endOf("day").toISOString()
      )?.split(" ")[0];
      return onlyDateString
        ? dayjs(new Date(onlyDateString)).endOf("day").toISOString()
        : dayjs(defaultDateRange.endDate).endOf("day").toISOString();
    },
    replaceState: true,
  }
);
export const previousStartTimeAtom = atomWithQuery(
  "previousStartTime",
  previousDateRange.startDate,
  {
    shallow: true,
    deserialize: (str) => {
      const onlyDateString = (
        typeof str === "string" ? str : previousDateRange.startDate
      )?.split(" ")[0];
      return onlyDateString
        ? new Date(onlyDateString).toISOString()
        : new Date().toISOString();
    },
    replaceState: true,
  }
);
export const previousEndTimeAtom = atomWithQuery(
  "previousEndTime",
  previousDateRange.endDate,
  {
    shallow: true,
    deserialize: (str) => {
      const onlyDateString = (
        typeof str === "string" ? str : previousDateRange.endDate
      )?.split(" ")[0];
      return onlyDateString
        ? new Date(onlyDateString).toISOString()
        : new Date().toISOString();
    },
    replaceState: true,
  }
);
export const timeRangeKeyAtom = atomWithQuery<
  (typeof START_DATE_RANGE_SELECT_OPTIONS_FULL)[number]["value"]
>("timerange", defaultTimerange, {
  shallow: true,
  deserialize: (str) => {
    const value = START_DATE_RANGE_SELECT_OPTIONS_FULL.find(
      (el) => el.value === str
    )?.value;
    return value ?? defaultTimerange;
  },
  replaceState: true,
});

export const comparePeriodAtom = atomWithQuery<
  (typeof PREVIOUS_DATE_RANGE_SELECT_OPTIONS)[number]["value"]
>("comparePeriod", PREVIOUS_DATE_RANGE_SELECT_OPTIONS[0].value, {
  shallow: true,
  deserialize: (str) =>
    PREVIOUS_DATE_RANGE_SELECT_OPTIONS.find((el) => el.value === str)?.value,
  replaceState: true,
});

export const attributionModelAtom = atomWithQuery<
  (typeof ATTRIBUTION_MODEL_OPTIONS)[number]["value"]
>("attributionModel", "last", {
  shallow: true,
  deserialize: (str) =>
    ATTRIBUTION_MODEL_OPTIONS.find((el) => el.value === str)?.value ?? "last",
  replaceState: true,
});
export const reportTimeAtom = atomWithQuery<
  (typeof REPORT_TIME_OPTIONS)[number]["value"]
>("reportTime", "event", {
  shallow: true,
  deserialize: (str) =>
    REPORT_TIME_OPTIONS.find((el) => el.value === str)?.value ?? "event",
  replaceState: true,
});

export const creativeAttributionAtom = atomWithQuery<
  (typeof CREATIVE_ATTRIBUTION_OPTIONS)[number]["value"]
>("creativeAttribution", "isolated", {
  shallow: true,
  deserialize: (str) =>
    CREATIVE_ATTRIBUTION_OPTIONS.find((el) => el.value === str)?.value ??
    "isolated",
  replaceState: true,
});

export const attributionWindowAtom = atomWithQuery<
  (typeof ATTRIBUTION_WINDOW_OPTIONS)[number]["value"]
>("attributionWindow", "1", {
  shallow: true,
  deserialize: (str) =>
    ATTRIBUTION_WINDOW_OPTIONS.find((el) => String(el.value) === str)?.value ??
    1,
  replaceState: true,
});

export const selectedMarketingChannelsAtom = atomWithQuery<
  Array<(typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"]>
>(
  "selectedMarketingChannels",
  AVAILABLE_MARKETING_CHANNEL_OPTIONS.map((el) => el.value).filter(
    (el) => !["direct"].includes(el)
  ),
  {
    shallow: true,
    deserialize: (str) => {
      if (typeof str === "string") {
        return str?.split(",")?.filter((el) => {
          const options: string[] = AVAILABLE_MARKETING_CHANNEL_OPTIONS.map(
            (el) => el.value
          );
          if (options.includes(el)) return true;
          return false;
        });
      } else {
        return AVAILABLE_MARKETING_CHANNEL_OPTIONS.map((el) => el.value).filter(
          (el) => !["direct"].includes(el)
        );
      }
    },
    serialize: (str) => str.join(","),
    replaceState: true,
  }
);

export const selectedTimezoneAtom = atomWithQuery<string>(
  "selectedTimezone",
  new Intl.DateTimeFormat().resolvedOptions().timeZone,
  {
    shallow: true,
    deserialize: (str) => {
      const timezones = getTimeZones();
      const names = timezones.map((el) => el.name);
      if (str && names.includes(str)) return str;
      return new Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    serialize: (str) => {
      const timezones = getTimeZones();
      const names = timezones.map((el) => el.name);
      if (str && names.includes(str)) return str;
      return new Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    replaceState: true,
  }
);

export type NvrType = "default" | "new" | "returning" | "all";
export const newVsReturningAtom = atomWithQuery<NvrType>(
  "customers",
  "default",
  {
    shallow: true,
    deserialize: (str) =>
      ["default", "new", "returning", "all"].includes(str ?? "")
        ? str
        : "default",
    replaceState: true,
  }
);

export const comparedAtom = atomWithQuery<boolean>("compare", false, {
  shallow: true,
  deserialize: (str) => {
    return str == "true";
  },
  replaceState: true,
});
export const guideModeAtom = atom<boolean>(false);

export const selectedStoreIdsAtom = atomWithQuery<string[]>("csids", [], {
  shallow: true,
  deserialize: (str) =>
    (typeof str === "string" ? str : "")?.split(",").filter((el) => el !== ""),
  serialize: (str) => str.filter((el) => el !== "").join(","),
  saveToStorage: true,
  replaceState: true,
});
export const selectedStoreOptionsAtom = atom<string[]>([]);

export const selectedOrganisationAtom = atom<string | undefined>(undefined);

export const selectedColumnsAtom = atom<
  Map<TableTypes, Array<(typeof COLUMN_OPTIONS)[number]["value"]>>
>(AVAILABLE_COLUMNS_PER_TABLE);

export const selectedColumnsOrderAtom = atom<ColumnItemType[]>(
  COLUMN_OPTIONS.map((el) => ({ ...el, id: el.value }))
);

export const selectedFiltersAtom = atomWithQuery<AdsFilterInterface[]>(
  "filters",
  [],
  {
    shallow: true,
    deserialize: (value) => (value ? formatQueryToFilter(value) : []),
    serialize: formatFilterToQuery,
    replaceState: true,
  }
);

export const selectedTabAtom = atomWithQuery<string>("tabValue", "campaigns", {
  shallow: true,
  deserialize: (str) => str,
});

export const settingsTabValueAtom = atomWithQuery<string>(
  "tabValue",
  "general",
  {
    shallow: true,
    deserialize: (str) => str,
  }
);

export const influencerFilterAtom = atomWithQuery<string | null>(
  "influencerFilter",
  null,
  {
    shallow: true,
    deserialize: (str) => (str ? str : null),
    replaceState: true,
  }
);
export const placementTypeFilterAtom = atomWithQuery<
  (typeof INFLUENCER_PLACEMENT_TYPES)[number]["value"] | null
>("placementTypeFilter", null, {
  shallow: true,
  deserialize: (str) => (str ? str : null),
  replaceState: true,
});

export const viewToExportAtom = atomWithQuery<string>(
  "viewToExport",
  "default",
  {
    shallow: true,
    deserialize: (str) => str,
    replaceState: true,
  }
);

export const openExportModalAtom = atomWithQuery<boolean>(
  "openExportModal",
  false,
  {
    shallow: true,
    deserialize: (value) => value === "true",
    serialize: (value) => `${value}`,
    replaceState: true,
  }
);
export const tablesToExportAtom = atomWithQuery<string[]>(
  "tablesToExport",
  ["all"],
  {
    shallow: true,
    deserialize: (str) => (typeof str === "string" ? str : "all")?.split(","),
    serialize: (str) => str.join(","),
    replaceState: true,
  }
);

export const intercomOpenStatusAtom = atom<boolean>(false);

export const creativeChannelSelectionAtom = atomWithQuery<
  "facebook" | "tiktok"
>("creativeChannelSelection", "facebook", {
  shallow: true,
  serialize: (val) => val,
  deserialize: (str) =>
    str && ["facebook", "tiktok"].includes(str) ? str : "facebook",
  replaceState: true,
});
export const creativesRowSelectionAtom = atomWithQuery<string[]>(
  "creativesRowSelection",
  [],
  {
    shallow: true,
    deserialize: (str) => {
      const array: string[] = [];
      for (const el of (typeof str === "string" ? str : "")?.split(",") ?? []) {
        if (!array.includes(el) && el !== "") array.push(el);
      }
      return array;
    },
    serialize: (val) => removeDuplicates(val).join(","),
    replaceState: true,
  }
);

export const firstCreativeComparisonMetricAtom = atomWithQuery<
  (typeof CREATIVE_COMPARISON_OPTIONS)[number]["value"]
>("creative_comparison_1", CREATIVE_COMPARISON_OPTIONS[0].value, {
  shallow: true,
  deserialize: (str) =>
    CREATIVE_COMPARISON_OPTIONS.find((el) => el.value === str)?.value,
  serialize: (str) => str,
  replaceState: true,
});

export const secondCreativeComparisonMetricAtom = atomWithQuery<
  (typeof CREATIVE_COMPARISON_OPTIONS)[number]["value"] | null
>("creative_comparison_2", CREATIVE_COMPARISON_OPTIONS[1].value, {
  shallow: true,
  deserialize: (str) =>
    CREATIVE_COMPARISON_OPTIONS.find((el) => el.value === str)?.value,
  serialize: (str) => (str ? str : ""),
  replaceState: true,
});

export const selectedCreativeChartAtom = atomWithQuery<
  (typeof CREATIVE_CHART_OPTIONS)[number]["value"]
>("selected_creative_chart", CREATIVE_CHART_OPTIONS[0].value, {
  shallow: true,
  deserialize: (str) =>
    CREATIVE_CHART_OPTIONS.find((el) => el.value === str)?.value,
  serialize: (str) => str,
  replaceState: true,
});

export const sortByOptionsAtom = atomWithQuery<string>(
  "sort_creatives",
  "spend_desc",
  {
    shallow: true,
    deserialize: (str) => str,
    serialize: (str) => str,
    replaceState: true,
  }
);

export const selectedCreativeSegmentAtom = atomWithStorage<string>(
  "creative_segment",
  "all_creatives_default"
);
export const influencerPerformanceMetricsAtom = atomWithStorage<{
  [key: string]: {
    metric: (typeof INFLUENCER_PERFORMANCE_OPTIONS)[number]["value"] | null;
    active: boolean;
  };
}>("influencer_performance ", {
  first: { metric: INFLUENCER_PERFORMANCE_OPTIONS[0].value, active: true },
  second: { metric: INFLUENCER_PERFORMANCE_OPTIONS[1].value, active: false },
  third: { metric: INFLUENCER_PERFORMANCE_OPTIONS[2].value, active: false },
  fourth: { metric: INFLUENCER_PERFORMANCE_OPTIONS[3].value, active: false },
});

export const attributionModeAtom = atomWithStorage<"ai" | "standard">(
  "attribution_mode",
  "ai"
);

export const aiAttributionWindowAtom = atomWithStorage<Record<string, number>>(
  "ai_attribution_window",
  {}
);

export const hasChangedAttributionModeAtom = atomWithStorage<boolean>(
  "has_changed_attribution_mode",
  false
);

const storage = createJSONStorage<string>(() => sessionStorage);

export const showAttributionModeBannerAtom = atomWithStorage<string>(
  "show_attribution_mode_banner",
  new Date().toISOString(),
  storage
);

export const dashboardModeAtom = atomWithStorage<
  (typeof availableDashboardModes)[number]["value"]
>(STORAGE_KEYS.DASHBOARD_MODE, "ecommerce");

import { ButtonTypes } from "../../lib/util-types";
import React from "react";
import LoadingDots from "../loading-dots/loading-dots";
import s from "./button.module.css";
interface Props {}

const ButtonLoading: React.FC<React.PropsWithChildren<Props>> = ({}) => {
  return (
    <div className={`${s["btn-loading"]} absolute inset-0 w-full h-full`}>
      <LoadingDots />
    </div>
  );
};

export default ButtonLoading;

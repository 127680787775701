import {
  ArchiveBoxIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Menu as HeadlessMenu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import Button from "../button/button";

export interface MenuProps {}

export const Menu: React.FC<MenuProps> = () => {
  return (
    <div className="w-56 text-right fixed top-16">
      <HeadlessMenu as="div" className="relative inline-block text-left">
        {({ open }) => {
          return (
            <>
              <div>
                <HeadlessMenu.Button>
                  <Button
                    elementType="span"
                    type="primary"
                    auto
                    iconRight={
                      <ChevronDownIcon
                        className={`transform duration-200 ease-out ${
                          open ? "rotate-180" : "rotate-0 text-opacity-70"
                        }`}
                        aria-hidden="true"
                      />
                    }
                  >
                    <span>Options</span>
                  </Button>
                </HeadlessMenu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <HeadlessMenu.Items className="absolute left-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <HeadlessMenu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-primary text-foreground"
                              : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          <PencilIcon
                            className={`mr-2 h-4 w-4 ${active ? "" : ""}`}
                            aria-hidden="true"
                          />
                          Edit
                        </button>
                      )}
                    </HeadlessMenu.Item>
                    <HeadlessMenu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-primary text-foreground"
                              : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          <DocumentDuplicateIcon
                            className={`mr-2 h-4 w-4 ${active ? "" : ""}`}
                            aria-hidden="true"
                          />
                          Duplicate
                        </button>
                      )}
                    </HeadlessMenu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <HeadlessMenu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-primary text-foreground"
                              : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          <ArchiveBoxIcon
                            className={`mr-2 h-4 w-4 ${active ? "" : ""}`}
                            aria-hidden="true"
                          />
                          Archive
                        </button>
                      )}
                    </HeadlessMenu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <HeadlessMenu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-primary text-foreground"
                              : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          <TrashIcon
                            className={`mr-2 h-4 w-4 ${active ? "" : ""}`}
                            aria-hidden="true"
                          />
                          Delete
                        </button>
                      )}
                    </HeadlessMenu.Item>
                  </div>
                </HeadlessMenu.Items>
              </Transition>
            </>
          );
        }}
      </HeadlessMenu>
    </div>
  );
};
export default Menu;

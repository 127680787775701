import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import styles from "./styles.module.css";
import {
  getPreviousDateRange,
  getStartDateRange,
} from "../../services/DateRangeServices";
import {
  START_DATE_RANGE_SELECT_OPTIONS_BASIC,
  PREVIOUS_DATE_RANGE_SELECT_OPTIONS,
} from "../../constants/constants";
import {
  ComparisionDateRangeInterface,
  DatePickerRangeInterface,
} from "../../interface/DateRangeInterface";
import { useAtom } from "jotai";
import {
  comparedAtom,
  comparePeriodAtom,
  endTimeAtom,
  previousEndTimeAtom,
  previousStartTimeAtom,
  startTimeAtom,
  timeRangeKeyAtom,
} from "../../atoms";
import { Button, Checkbox } from "@ui";
import { CalendarIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { DateRangeDisplayer } from "./DateRangeDisplayer";
import { DashboardDatePickerProps } from "interface/DashboardDatePicker";

export function DashboardDatePicker(props: DashboardDatePickerProps) {
  const { closeDatePicker, isCompared, maxDate, minDate } = props;
  const [startTime, setStartTime] = useAtom(startTimeAtom);
  const [endTime, setEndTime] = useAtom(endTimeAtom);
  const [previousStartTime, setPreviousStartTime] = useAtom(
    previousStartTimeAtom
  );
  const [previousEndTime, setPreviousEndTime] = useAtom(previousEndTimeAtom);
  const [timeRangeKey, setTimeRangeKey] = useAtom(timeRangeKeyAtom);
  const comparePeriod = "last_period";
  const [isComparedToPrevious, setIsCompared] = useAtom(comparedAtom);
  const [localIsCompared, setLocalIsCompared] = useState(
    props.isCompared ? isComparedToPrevious : false
  );
  const [localTimeRangeKey, setLocalTimeRangeKey] =
    useState<typeof timeRangeKey>(timeRangeKey);
  const [selectedDateRange, setSelectedDateRange] =
    useState<DatePickerRangeInterface>({
      startDate: new Date(startTime),
      endDate: new Date(endTime),
      key: "selection",
    });
  const [selectedPreviousDate, setSelectedPreviousDate] =
    useState<DatePickerRangeInterface>({
      startDate: new Date(previousStartTime),
      endDate: new Date(previousEndTime),
      key: "selection",
    });

  useEffect(() => {
    if (!props.isCompared && (isComparedToPrevious || localIsCompared)) {
      setLocalIsCompared(false);
      setIsCompared(false);
    }
  }, [isComparedToPrevious, localIsCompared, props.isCompared, setIsCompared]);

  const days = useMemo(() => {
    const diff = dayjs(endTime).diff(dayjs(startTime), "day") + 1;
    return diff;
  }, [endTime, startTime]);

  const [comparisionDateRange, setComparisionDateRange] =
    useState<ComparisionDateRangeInterface>({
      current: {
        startDate: dayjs(startTime).format("MMM DD, YYYY"),
        endDate: dayjs(endTime).format("MMM DD, YYYY"),
        preset: timeRangeKey,
      },
      previous: {
        startDate: dayjs(startTime)
          .subtract(days, "day")
          .format("MMM DD, YYYY"),
        endDate: dayjs(endTime).subtract(days, "day").format("MMM DD, YYYY"),
        preset: "last_period",
      },
    });
  const onCalenderSelect = useCallback(
    (selectedDate: any, applyInstantly?: boolean) => {
      const dateRange = getPreviousDateRange(
        comparisionDateRange.previous.preset,
        selectedDate
      );

      setComparisionDateRange((current) => ({
        current: {
          startDate: format(new Date(selectedDate.startDate), "MMM d, yyyy"),
          endDate: format(new Date(selectedDate.endDate), "MMM d, yyyy"),
          preset: current.current.preset,
        },
        previous: {
          startDate: format(new Date(dateRange.startDate), "MMM d, yyyy"),
          endDate: format(new Date(dateRange.endDate), "MMM d, yyyy"),
          preset: current.previous.preset,
        },
      }));
      let timerange: typeof localTimeRangeKey = "Custom";
      if (
        dayjs(selectedDate.startDate).isSame(new Date(), "day") &&
        dayjs(selectedDate.endDate).isSame(new Date(), "day")
      ) {
        timerange = "today";
      } else if (
        dayjs(selectedDate.startDate).isSame(
          dayjs().subtract(1, "day"),
          "day"
        ) &&
        dayjs(selectedDate.endDate).isSame(dayjs().subtract(1, "day"), "day")
      ) {
        timerange = "yesterday";
      }
      setLocalTimeRangeKey(timerange);
      setSelectedDateRange(selectedDate);
      setSelectedPreviousDate(dateRange);

      if (applyInstantly) {
        setStartTime(
          dayjs(selectedDate.startDate).startOf("day").toISOString()
        );
        setEndTime(dayjs(selectedDate.endDate).endOf("day").toISOString());
        setPreviousStartTime(
          dayjs(dateRange.startDate).startOf("day").toISOString()
        );
        setPreviousEndTime(dayjs(dateRange.endDate).endOf("day").toISOString());
        setTimeRangeKey(timerange);
        setIsCompared(localIsCompared);
      }
    },
    [
      comparisionDateRange.previous.preset,
      localIsCompared,
      setEndTime,
      setIsCompared,
      setPreviousEndTime,
      setPreviousStartTime,
      setStartTime,
      setTimeRangeKey,
    ]
  );

  useEffect(() => {
    if (
      maxDate &&
      minDate &&
      dayjs(maxDate).isBefore(selectedDateRange.endDate) &&
      dayjs(minDate).isAfter(selectedDateRange.startDate)
    ) {
      onCalenderSelect(
        { key: "selection", endDate: maxDate, startDate: minDate },
        true
      );
    } else if (
      maxDate &&
      dayjs(maxDate).isBefore(selectedDateRange.endDate, "second")
    ) {
      onCalenderSelect(
        {
          key: "selection",
          endDate: maxDate,
          startDate: dayjs(selectedDateRange.startDate).isAfter(maxDate)
            ? dayjs(maxDate).startOf("day").toDate()
            : selectedDateRange.startDate,
        },
        true
      );
    } else if (minDate && dayjs(minDate).isAfter(selectedDateRange.startDate)) {
      onCalenderSelect(
        {
          key: "selection",
          startDate: minDate,
          endDate: dayjs(selectedDateRange.endDate).isAfter(minDate)
            ? dayjs(minDate)
                .hour(23)
                .minute(59)
                .second(59)
                .millisecond(999)
                .toDate()
            : selectedDateRange.endDate,
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxDate, minDate, onCalenderSelect]);

  const handleDatePresetSelect = (range: typeof timeRangeKey) => {
    const dateRange = getStartDateRange(range, selectedDateRange);
    if (maxDate && dayjs(maxDate).isBefore(dateRange.endDate)) {
      dateRange.endDate = maxDate;
    }
    const compareDateRange = getPreviousDateRange(
      comparisionDateRange.previous.preset,
      dateRange
    );
    setComparisionDateRange((current) => ({
      current: {
        startDate: format(new Date(dateRange.startDate), "MMM d, yyyy"),
        endDate: format(new Date(dateRange.endDate), "MMM d, yyyy"),
        preset: range,
      },
      previous: {
        startDate: format(new Date(compareDateRange.startDate), "MMM d, yyyy"),
        endDate: format(new Date(compareDateRange.endDate), "MMM d, yyyy"),
        preset: current.previous.preset,
      },
    }));

    setLocalTimeRangeKey(range);
    setSelectedDateRange(dateRange);
    setSelectedPreviousDate(compareDateRange);

    //  instantly apply settings
    setStartTime(dayjs(dateRange.startDate).startOf("day").toISOString());
    setEndTime(
      // set minute and second to 0 to make client-side caching easier
      dayjs(dateRange.endDate).endOf("day").toISOString()
    );
    setPreviousStartTime(
      dayjs(compareDateRange.startDate).startOf("day").toISOString()
    );
    setPreviousEndTime(
      dayjs(compareDateRange.endDate).endOf("day").toISOString()
    );
    setTimeRangeKey(range);
    setIsCompared(localIsCompared);

    closeDatePicker();
  };

  const onStartDateRangeListSelect = (range: typeof timeRangeKey) => {
    handleDatePresetSelect(range);
  };
  const onPreviousPresetSelect = (
    range: (typeof PREVIOUS_DATE_RANGE_SELECT_OPTIONS)[number]["value"]
  ) => {
    const dateRange = getPreviousDateRange(range, selectedDateRange);
    setComparisionDateRange((current) => ({
      current: {
        startDate: format(new Date(current.current.startDate), "MMM d, yyyy"),
        endDate: format(new Date(current.current.endDate), "MMM d, yyyy"),
        preset: current.current.preset,
      },
      previous: {
        startDate: format(new Date(dateRange.startDate), "MMM d, yyyy"),
        endDate: format(new Date(dateRange.endDate), "MMM d, yyyy"),
        preset: current.previous.preset,
      },
    }));
    setSelectedPreviousDate(dateRange);
  };
  const onCompareToPreviousClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalIsCompared(event.target.checked);
    onPreviousPresetSelect(PREVIOUS_DATE_RANGE_SELECT_OPTIONS[0].value);
  };

  const handleApply = () => {
    setStartTime(
      dayjs(selectedDateRange.startDate).startOf("day").toISOString()
    );
    setEndTime(dayjs(selectedDateRange.endDate).endOf("day").toISOString());
    setPreviousStartTime(
      dayjs(selectedPreviousDate.startDate).startOf("day").toISOString()
    );
    setPreviousEndTime(
      dayjs(selectedPreviousDate.endDate).endOf("day").toISOString()
    );
    setTimeRangeKey(localTimeRangeKey);
    setIsCompared(localIsCompared);

    closeDatePicker();
  };

  return (
    <div
      className={`flex bg-gray-800 border-gray-700 border shadow-lg rounded-xl`}
    >
      <div className="w-3/12 flex flex-col h-full py-3 ">
        <div className="flex text-foreground text-base font-semibold items-center px-4">
          <CalendarIcon className="h-5 w-5 text-primary" />
          <div className="ml-2">Date Presets</div>
        </div>
        <div className="h-3" />
        {START_DATE_RANGE_SELECT_OPTIONS_BASIC &&
          START_DATE_RANGE_SELECT_OPTIONS_BASIC.map((option) => {
            if (
              option.value === "today" &&
              dayjs(maxDate).isBefore(new Date())
            ) {
              return null;
            }
            return (
              <button
                key={option.value}
                onClick={() => onStartDateRangeListSelect(option.value)}
                className={`h-10 mb-1 flex border-l-8 items-center cursor-pointer border-box
              transition-colors duration-200 hover:bg-dateRangeBg hover:border-l-8 hover:border-primary-dark
              ${
                localTimeRangeKey === option.value
                  ? "text-primary bg-dateRangeBg border-primary-dark "
                  : "text-foreground border-transparent"
              } `}
              >
                <p className="ml-9">{option.label}</p>
              </button>
            );
          })}
        {/* <div className="w-full pl-9 pr-4 mt-2">
          <Select
            options={START_DATE_RANGE_SELECT_OPTIONS_EXTRA}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleOtherOptionSelect(event.target.value)
            }
            value={otherOptionKey}
            label="Outher"
            placeholder="Outher"
            id="other-preset"
            size="small"
            borderColor="border-gray-700 hover:border-gray-400"
          ></Select>
        </div> */}
      </div>
      <div className="w-9/12 flex py-3 flex-col border-gray-600 border-l ">
        {isCompared && (
          <div className="px-4">
            <Checkbox
              label={
                <p>
                  Compare
                  <span
                    className={`text-sm ml-2 rounded-md py-0.5 px-1 bg-[#ffd85c] text-background-soft inline`}
                  >
                    New
                  </span>
                </p>
              }
              onChange={onCompareToPreviousClick}
              checked={localIsCompared}
              size="small"
            />
          </div>
        )}
        {localIsCompared && (
          <div className="flex flex-col border-b border-gray-600 py-2 px-4">
            <div className="">
              <DateRangeDisplayer
                isActive
                isEditable={false}
                startDate={comparisionDateRange?.current?.startDate}
                endDate={comparisionDateRange?.current?.endDate}
                preset={comparisionDateRange?.current?.preset}
              />
            </div>
            <div className="mt-2">
              <DateRangeDisplayer
                isEditable={false}
                startDate={comparisionDateRange?.previous?.startDate}
                endDate={comparisionDateRange?.previous?.endDate}
                preset={comparePeriod}
              />
            </div>
          </div>
        )}
        <div className={`${styles.container}`}>
          <DateRangePicker
            editableDateInputs
            scroll={{ enabled: false }}
            onChange={(item) => onCalenderSelect(item.selection)}
            moveRangeOnFirstSelection={false}
            months={2}
            showDateDisplay={false}
            direction="horizontal"
            ranges={[selectedDateRange]}
            dateDisplayFormat="dd-MM-yyyy"
            monthDisplayFormat={"MMMM yyyy"}
            maxDate={maxDate}
            minDate={minDate}
            calendarFocus="backwards"
          />
        </div>
        <div className="px-4 space-x-2 flex items-center">
          <Button size="small" type="primary" onClick={handleApply}>
            Apply
          </Button>
          {/* <Button size="small" type="outline" onClick={handleApply}>
            Cancel
          </Button> */}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef } from "react";
import s from "./button.module.css";
interface Props {
  x: number;
  y: number;
  onCompleted: () => void;
  color: string;
}

export type ButtonDrip = Props;

const ButtonDrip: React.FC<ButtonDrip> = ({
  x = 0,
  y = 0,
  color,
  onCompleted,
}) => {
  const dripRef = useRef<HTMLDivElement>(null);
  /* istanbul ignore next */
  const top = Number.isNaN(+y) ? 0 : y - 10;
  /* istanbul ignore next */
  const left = Number.isNaN(+x) ? 0 : x - 10;

  useEffect(() => {
    /* istanbul ignore next */
    if (!dripRef.current) return;
    dripRef.current.addEventListener("animationend", onCompleted);
    return () => {
      /* istanbul ignore next */
      if (!dripRef.current) return;
      dripRef.current.removeEventListener("animationend", onCompleted);
    };
  });

  return (
    <div ref={dripRef} className="drip absolute inset-0">
      <svg
        className={s.dripSvg}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        style={{ top, left }}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color}>
            <rect width="100%" height="100%" rx="10" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default ButtonDrip;

import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useLocalStorage } from "@lib/hooks/use-local-storage";

function ReferralBanner() {
  const [referralHideTime, setReferralHideTime] = useLocalStorage<{
    referralHideTime: string;
  }>("REFERRAL_BANNER", {
    usingJson: true,
  });

  const hideReferralBanner = () => {
    // Hide the referral banner for 30 days, 30 days = 30 * 24 * 60 * 60 * 1000
    setReferralHideTime({
      referralHideTime: new Date(
        Date.now() + 30 * 24 * 60 * 60 * 1000
      ).toUTCString(),
    });
  };

  if (
    referralHideTime?.referralHideTime &&
    new Date(referralHideTime.referralHideTime) > new Date()
  ) {
    return null;
  }

  return (
    <div className="col-span-3 h-auto py-1 bg-primaryNeutral400 border-3 border-primary rounded-md flex flex-col md:flex-row justify-between items-center">
      <div className="flex flex-col md:flex-row gap-1 items-center justify-center w-full md:w-5/6">
        <div className="flex flex-col md:flex-row gap-1 md:gap-5 items-center justify-center text-center md:text-left">
          <span className="text-lg text-foreground dark:text-black font-bold px-2 text-center">
            💰 NEW Referral Program
          </span>
          <span className="text-sm text-foreground dark:text-black">
            Get 500€ for every successfully referred Tracify customer.
          </span>
        </div>
        <a
          className="font-semibold underline text-sm text-foreground dark:text-black mt-2 md:mt-0"
          href="https://www.tracify.ai/referral?utm_source=Dashboard&utm_ref_source=Dashboard "
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </div>

      <div className="w-full md:w-1/6 mt-2 md:mt-0 text-center md:text-right flex items-center justify-end gap-2 pe-2">
        <button
          onClick={() => {
            window.open(
              "https://www.tracify.ai/referral?utm_source=Dashboard&utm_ref_source=Dashboard ",
              "_blank"
            );
          }}
          className="text-white text-sm font-semibold bg-foreground dark:bg-black py-1 px-2 flex items-center rounded-md w-fit"
        >
          Earn 500€
        </button>
        <div className="text-black" onClick={hideReferralBanner}>
          <XMarkIcon className="h-4 w-4" />
        </div>
      </div>
    </div>
  );
}

export default ReferralBanner;

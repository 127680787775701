import { BaseScore } from "@api/types/backendTypes";
import { ExportType } from "interface/ExportType";
import { formatTracifyNumber } from "../string";
import { availableDashboardModes } from "@lib/hooks/constants";

export const transformToCSVRow = (
  score: BaseScore,
  ids?: { campaignId?: string; adsetId?: string },
  settings: {
    includesCurrentDay?: boolean;
    exportType?: ExportType;
    calculateCurrencyConvertion?: (
      value: number | undefined,
      numDigits: number
    ) => string;
    formatNumber?: (value: number, numDigits?: number) => string;
  } = {}
) => {
  const csvRow: Array<string | number> = [];
  const formatFn = settings.formatNumber ?? formatTracifyNumber;

  const currencyHelper = (value: number | undefined, numDigits: number) => {
    return settings?.calculateCurrencyConvertion
      ? settings.calculateCurrencyConvertion(value ?? 0, numDigits)
      : settings.formatNumber
        ? `${settings.formatNumber(value ?? 0, numDigits)} €}`
        : `"${formatFn(value ?? 0, numDigits)} €"`;
  };

  let currentCampaignId = ids?.campaignId;
  let currentAdsetId = ids?.adsetId;
  if (score.type === "campaign") {
    csvRow.push("Campaign");
    csvRow.push(`"${score.refId}"`);
    csvRow.push(`"${" "}"`);
    csvRow.push(`"${" "}"`);
    currentCampaignId = score.refId;
  } else if (score.type === "adset") {
    csvRow.push("Ad group");
    csvRow.push(`"${currentCampaignId ?? score.parentCampaign?.refId ?? " "}"`);
    csvRow.push(`"${score.refId}"`);
    csvRow.push(`"${" "}"`);
    currentAdsetId = score.refId;
  } else if (score.type === "ad") {
    csvRow.push("Ad");
    csvRow.push(`"${currentCampaignId ?? score.parentCampaign?.refId ?? " "}"`);
    csvRow.push(`"${currentAdsetId ?? score.parentAdset?.refId ?? " "}"`);
    csvRow.push(`"${score.refId}"`);
  } else if (settings.exportType === "influencers") {
    csvRow.push("Influencers");
  } else if (settings.exportType === "cooperations") {
    csvRow.push("Cooperations");
    // @ts-ignore
    csvRow.push(`"${score.influencerName}"`);
  } else {
    csvRow.push(" ");
    csvRow.push(" ");
    csvRow.push(" ");
    csvRow.push(" ");
  }
  csvRow.push(`"${score.name}"`);
  if (!settings?.includesCurrentDay) {
    csvRow.push(
      `"${
        settings.formatNumber
          ? settings.formatNumber(score.roas ?? 0, 1)
          : formatFn(score.roas ?? 0, 1)
      }"`
    );
  } else {
    csvRow.push(
      `"${
        settings.formatNumber ? settings.formatNumber(0, 1) : formatFn(0, 1)
      }"`
    );
  }
  if (!settings?.includesCurrentDay) {
    csvRow.push(`"${currencyHelper(score.cpo ?? 0, 1)}"`);
  } else {
    csvRow.push(`"${currencyHelper(0, 1)}"`);
  }

  csvRow.push(
    `"${
      score.purchaseCount % 1 === 0
        ? formatFn(score.purchaseCount, 0)
        : formatFn(score.purchaseCount, 2)
    }"`
  );
  csvRow.push(`"${currencyHelper(score.purchaseAmount, 2)}"`);
  if (!settings?.includesCurrentDay) {
    csvRow.push(`"${currencyHelper(score.spend ?? 0, 2)}"`);
  } else {
    csvRow.push(`"${currencyHelper(0, 2)}"`);
  }
  csvRow.push(`"${currencyHelper(score.aov ?? 0, 2)}"`);
  csvRow.push(`"${formatFn((score.cr ?? 0) * 100, 2)}%"`);
  csvRow.push(`"${formatFn(score.addtocart, 0)}"`);
  csvRow.push(`"${formatFn(score.productview, 0)}"`);
  csvRow.push(`"${formatFn(score.pageview, 0)}"`);

  let subRows: Array<any> = [];
  if (settings.exportType === "all") {
    subRows = score.subRows?.map((el) =>
      transformToCSVRow(
        el,
        {
          campaignId: currentCampaignId,
          adsetId: currentAdsetId,
        },
        {
          ...settings,
        }
      )
    );
  }
  if (subRows.length > 0) {
    return [csvRow, ...subRows?.flat()];
  } else {
    return [csvRow];
  }
};

export const exportToCsv = (
  data: BaseScore[],
  {
    includesCurrentDay,
    exportType,
    dashboardMode = "ecommerce",
    calculateCurrencyConvertion,
    formatNumber,
  }: {
    includesCurrentDay: boolean;
    exportType: ExportType;
    dashboardMode: (typeof availableDashboardModes)[number]["value"];
    calculateCurrencyConvertion: (
      value: number | undefined,
      numDigits: number
    ) => string;
    formatNumber: (value: number, numDigits?: number) => string;
  }
) => {
  const defaultHeaders = [
    "Name",
    dashboardMode === "general" ? "ROI" : "ROAS",
    dashboardMode === "general" ? "CPA" : "CPO",
    dashboardMode === "general" ? "Conversions" : "Orders",
    dashboardMode === "general" ? "Conv. Value" : "Revenue",
    "Amount Spent",
    dashboardMode === "general" ? "ACV" : "AOV",
    "CR",
    "Unique Adds to Cart",
    "Unique Content Views",
    "Unique Page Views",
  ];

  let headers: string[] = [];

  if (exportType === "influencers") {
    headers = ["Type", ...defaultHeaders];
  } else if (exportType === "cooperations") {
    headers = ["Type", "Influencer", ...defaultHeaders];
  } else {
    headers = ["Type", "Campaign", "Ad group", "Ad", ...defaultHeaders];
  }

  const rows = data
    .map((el) =>
      transformToCSVRow(
        el,
        {},
        {
          includesCurrentDay,
          exportType,
          calculateCurrencyConvertion,
          formatNumber,
        }
      )
    )
    .flat(1);

  const contentRows = [headers, ...rows];
  return contentRows.map((e) => e.join(",")).join("\n");
};

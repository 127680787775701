import { NormalSizes, SelectTypes } from "@lib/util-types";
import s from "./select.module.css";

export interface SelectSize {
  height: string;
  fontSize: string;
  minWidth: string;
  minHeight: string;
}

export const getSelectColors = (type: SelectTypes): string => {
  const colors: { [key in SelectTypes]: string } = {
    default: s.defaultSelect,
    lite: s.liteSelect,
    red: s.redSelect,
    green: s.greenSelect,
    blue: s.blueSelect,
    yellow: s.yellowSelect,
  };
  const defaultColor = colors.default;
  if (!type) return defaultColor;
  return colors[type] || defaultColor;
};

export const getSizes = (size?: NormalSizes) => {
  const sizes: { [key in NormalSizes]: SelectSize } = {
    medium: {
      height: `h-12`,
      fontSize: "text-sm",
      minWidth: "10rem",
      minHeight: "2.25rem",
    },
    small: {
      height: `h-9`,
      fontSize: "text-xs",
      minWidth: "8rem",
      minHeight: "1.75rem",
    },
    large: {
      height: `h-14`,
      fontSize: "text-lg",
      minWidth: "12.5rem",
      minHeight: "2.75rem",
    },
  };

  return size ? sizes[size] : sizes.medium;
};

import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import { Tag } from "@ui";
import React from "react";

const AiAttributionBadge = () => {
  return (
    <LinkWithPersistentQuery href="/settings?tabValue=marketing" passHref>
      <p className="ml-2 text-sm py-1 px-1.5 mr-1 font-semibold  rounded-md bg-gradient-to-l text-white from-aiPurple  to-primary  flex items-center">
        <span> AI Attribution</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          className="w-4 h-4 ml-1 fill-white"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 9l1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z" />
        </svg>
      </p>
    </LinkWithPersistentQuery>
  );
};

export default AiAttributionBadge;

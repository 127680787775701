import { ButtonNormalSizes } from "../../lib/util-types";
import React from "react";
import { getButtonIconStyles } from "./styles";
import s from "./button.module.css";

interface Props {
  isRight?: boolean;
  isSingle?: boolean;
  auto: boolean;
  iconButton: boolean;
  size: ButtonNormalSizes;
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type ButtonIconProps = Props & NativeAttrs;

const ButtonIcon: React.FC<React.PropsWithChildren<ButtonIconProps>> = ({
  isRight = false,
  isSingle,
  size,
  auto,
  iconButton,
  children,
  className = "",
  ...props
}) => {
  const styles = getButtonIconStyles(size, auto, iconButton, isRight);
  return (
    <span
      className={`${
        s.icon
      } top-1/2 flex items-center justify-center z-10 ${styles} ${
        isSingle
          ? "single static transform-none"
          : "absolute transform -translate-y-1/2"
      } ${className}`}
      {...props}
    >
      {children}
    </span>
  );
};

export default ButtonIcon;

/* eslint-disable react/display-name */
import { NormalSizes } from "@lib/util-types";
import React, {
  ChangeEvent,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

type NativeAttrs = Omit<React.InputHTMLAttributes<any>, keyof Props>;
interface Props {
  id?: string;
  checked?: boolean;
  backgroundColor?: string;
  label?: string | React.ReactNode;
  sublabel?: string | React.ReactNode;
  error?: boolean;
  size?: NormalSizes;
  indeterminate?: boolean;
  initialValue?: boolean;
  errorText?: String;
  setChecked?: (value: boolean, id?: string) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}
export type CheckboxProps = Props & NativeAttrs;

export const Checkbox = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<CheckboxProps>
>(
  (
    {
      label,
      sublabel,
      error,
      errorText,
      disabled,
      backgroundColor,
      checked,
      size,
      indeterminate = false,
      onChange,
      initialValue = false,
      readOnly = false,
      hidden,
      ...props
    },
    ref: React.Ref<HTMLInputElement | null>
  ) => {
    const checkboxRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => checkboxRef.current);
    const [selfValue, setSelfValue] = useState<boolean>(initialValue);
    const isControlledComponent = useMemo(
      () => checked !== undefined,
      [checked]
    );
    const sizes = useMemo(() => {
      if (size === "small") {
        return "h-4 w-4 ";
      } else if (size === "large") {
        return "h-7 w-7 ";
      } else return "h-6 w-6 ";
    }, [size]);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      // if (disabled || readOnly) return;
      setSelfValue(event.target.checked);
      onChange && onChange(event);
    };
    useEffect(() => {
      if (isControlledComponent) {
        setSelfValue(checked as boolean);
      }
    });

    React.useEffect(() => {
      if (indeterminate && checkboxRef.current) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      }
    }, [indeterminate]);

    const controlledValue = isControlledComponent
      ? { checked: selfValue }
      : { defaultChecked: initialValue };
    const inputProps = {
      ...props,
      ...controlledValue,
    };
    return (
      <div className={`${hidden ? "hidden" : ""}`}>
        <label
          className={`inline-flex items-center text-left  ${
            disabled
              ? "text-foreground-soft cursor-not-allowed opacity-70"
              : "cursor-pointer"
          }`}
        >
          <input
            type="checkbox"
            ref={checkboxRef}
            className={`${sizes} rounded-sm ${
              error ? "border-red-500" : "border-gray-500"
            } bg-gray-800 focus:border-primary focus:ring-0 focus:ring-offset-gray-500 focus:ring-primary focus:ring-opacity-0 disabled:border-gray-500 disabled:bg-background-soft ${
              backgroundColor ?? "text-primary"
            }`}
            disabled={Boolean(disabled)}
            onChange={changeHandler}
            hidden={hidden}
            {...inputProps}
          />
          <span className="ml-2 text-sm font-semibold">{label}</span>
        </label>
        {sublabel ? (
          <div className="flex items-center">
            <div className={`${sizes} flex-shrink-0`} />
            <div
              className={`ml-2 text-xs ${
                typeof sublabel === "string" ? "italic" : ""
              }`}
            >
              {sublabel}
            </div>
          </div>
        ) : null}
        {error ? (
          <p className="ml-10 mt-2 text-xs text-red-500">{errorText}</p>
        ) : null}
      </div>
    );
  }
);

export default Checkbox;

import { CardContainer } from "@components/Cards/CardContainer";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { Button } from "@ui";
import { openExportModalAtom, tablesToExportAtom } from "atoms";
import { useAtom } from "jotai";
import React, { useCallback } from "react";

type QdTableContainerProps = {
  title: string;
  titleColor: string;
  loading: boolean;
  channel: string;
  beta?: boolean;
  children: JSX.Element;
};

const QdTableContainer = ({
  title,
  titleColor,
  channel,
  beta,
  loading,
  children,
}: QdTableContainerProps) => {
  const [, setExportModalOpen] = useAtom(openExportModalAtom);
  const [, setTablesToExport] = useAtom(tablesToExportAtom);

  const onExportClicked = useCallback(() => {
    setExportModalOpen(true);
    if (channel === "referred") {
      return setTablesToExport([channel, "referral"]);
    } else if (channel === "influencer") {
      return setTablesToExport([channel, "influencer_module"]);
    } else if (channel === "influencer_module") {
      return setTablesToExport([channel, "influencer"]);
    }
    setTablesToExport([channel]);
  }, [channel, setExportModalOpen, setTablesToExport]);

  return (
    <CardContainer>
      <div className="flex items-stretch flex-wrap space-x-2 pb-2">
        <p
          className={`text-white text-xl font-semibold ${titleColor} p-2 flex items-center rounded-md`}
        >
          {title}
        </p>
        <Button
          disabled={loading}
          iconButton
          type="abort"
          icon={<ArrowDownTrayIcon />}
          size="large"
          onClick={onExportClicked}
        />
        {beta && (
          <p className="text-white dark:text-foreground self-center text-sm rounded-md py-1 px-2 bg-gray-600 ">
            Beta
          </p>
        )}
      </div>
      {children}
    </CardContainer>
  );
};

export default QdTableContainer;

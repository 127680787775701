/* eslint-disable @next/next/no-img-element */
import { NormalSizes } from "../../lib/util-types";
import { useMemo } from "react";
/* eslint-disable-next-line */
interface Props {
  src?: string;
  stacked?: boolean;
  text?: string;
  size?: NormalSizes;
  isSquare?: boolean;
  className?: string;
}

type NativeAttrs = Omit<
  Partial<React.ImgHTMLAttributes<any> & React.HTMLAttributes<any>>,
  keyof Props
>;
export type AvatarProps = Props & NativeAttrs;

const getSize = (size: NormalSizes): string => {
  // eslint-disable-next-line no-unused-vars
  const sizes: { [key in NormalSizes]: string } = {
    small: "w-6 h-6",
    medium: "w-10 h-10",
    large: "w-16 h-16",
  };
  return sizes[size];
};

const safeText = (text: string): string => {
  if (text.length <= 4) return text;
  return text.slice(0, 3);
};

export function Avatar({
  src,
  stacked = false,
  text = "",
  size = "small" as NormalSizes,
  isSquare = false,
  className = "",
  ...props
}: AvatarProps) {
  const showText = !src;
  const sizeClass = useMemo(() => getSize(size), [size]);
  return (
    <span
      className={`avatar ${sizeClass} inline-block relative overflow-hidden border-[0.5px] border-gray-800 align-top ${
        !showText ? "bg-transparent" : "bg-primary"
      } ${stacked ? "-ml-2" : ""} ${
        isSquare ? "rounded-md" : "rounded-full"
      } ${className}`}
    >
      {!showText && (
        <img
          className={`avatar-img inline-block object-cover w-full h-full ${
            isSquare ? "rounded-md" : "rounded-full"
          }`}
          alt=""
          src={src}
          {...props}
        />
      )}
      {showText && (
        <span
          className="avatar-text absolute left-1/2 top-1/2 text-sm text-center transform -translate-x-1/2 -translate-y-1/2 scale-75 whitespace-nowrap select-none text-foreground"
          {...props}
        >
          {safeText(text)}
        </span>
      )}
    </span>
  );
}

export default Avatar;

import React, { useMemo } from "react";
import withDefaults from "../../lib/util-functions/with-defaults";
import { TableColumnItem } from "./table-context";

interface Props {
  width: number;
  columns: Array<TableColumnItem>;
  className?: string;
}

const defaultProps = {
  className: "",
};

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type TableHeadProps = Props & typeof defaultProps & NativeAttrs;

const makeColgroup = (width: number, columns: Array<TableColumnItem>) => {
  const unsetWidthCount = columns.filter((c) => !c.width).length;
  const customWidthTotal = columns.reduce((pre, current) => {
    return current.width ? pre + current.width : pre;
  }, 0);
  const averageWidth = (width - customWidthTotal) / unsetWidthCount;
  if (averageWidth <= 0) return <colgroup />;
  return (
    <colgroup>
      {columns.map((column, index) => (
        <col key={`colgroup-${index}`} width={column.width || averageWidth} />
      ))}
    </colgroup>
  );
};

const TableHead: React.FC<TableHeadProps> = ({ columns, width }) => {
  const isScalableWidth = useMemo(
    () => columns.find((item) => !!item.width),
    [columns]
  );
  const colgroup = useMemo(() => {
    if (!isScalableWidth) return <colgroup />;
    return makeColgroup(width, columns);
  }, [isScalableWidth, width]);

  return (
    <>
      {colgroup}
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th
              className={`table-head border-collapse max-w-[1px] bg-gray-700 border-spacing-0 py-0 px-4 text-sm font-semibold text-left align-middle text-foreground rounded-none`}
              key={`table-th-${column.value}-${index}`}
            >
              <div className="thead-box flex items-center">{column.label}</div>
            </th>
          ))}
        </tr>
      </thead>
      <style jsx>{`
        thead {
          border-collapse: separate;
          border-spacing: 0;
        }

        th {
          letter-spacing: 0;
        }

        .thead-box {
          min-height: 2.5rem;
        }
      `}</style>
    </>
  );
};

const MemoTableHead = React.memo(TableHead);

export default withDefaults(MemoTableHead, defaultProps);

import { Popover } from "@headlessui/react";
import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { DashboardDatePicker } from "./DashboardDatePicker";

type Props = {
  disabled?: boolean;
  isCompared: boolean;
  children: React.ReactNode;
  maxDate?: Date;
  minDate?: Date;
};
const DatePickerPopover: React.FC<Props> = ({
  disabled,
  children,
  isCompared,
  maxDate,
  minDate,
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  return (
    <Popover className="relative h-full w-full" id="date-select-popover">
      <Popover.Button
        disabled={disabled}
        ref={setReferenceElement}
        className={`h-full w-full flex items-center rounded-md overflow-hidden focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-primary focus:ring-offset-primary`}
      >
        {children}
      </Popover.Button>

      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="z-popover"
      >
        {({ close }) => (
          <DashboardDatePicker
            isCompared={isCompared}
            closeDatePicker={close}
            maxDate={maxDate}
            minDate={minDate}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default DatePickerPopover;

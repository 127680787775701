/* eslint-disable react/display-name */
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import InputLabel from "./input-label";
import InputBlockLabel from "./input-block-label";
import InputIcon from "./input-icon";
import { getSizes, getColors, InputTypes } from "./styles";
import { Props } from "./input-props";
import { NormalSizes } from "../../lib/util-types";

type NativeAttrs = Omit<React.InputHTMLAttributes<any>, keyof Props>;
export type InputProps = Props & NativeAttrs;

// const simulateChangeEvent = (
//   el: HTMLInputElement,
//   event: React.MouseEvent<HTMLDivElement>
// ): React.ChangeEvent<HTMLInputElement> => {
//   return {
//     ...event,
//     target: el,
//     currentTarget: el,
//   };
// };

export const Input = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<InputProps>
>(
  (
    {
      type,
      label,
      labelRight,
      textOrientation = "left",
      icon,
      iconRight,
      onIconClick,
      onChange,
      name,
      value,
      onClearClick,
      onBlur,
      onFocus,
      children,
      disabled = false,
      readOnly = false,
      clearable = false,
      iconClickable = false,
      width = "initial",
      size = "medium" as NormalSizes,
      status = "default" as InputTypes,
      autoComplete = "off",
      className = "",
      placeholder = "",
      initialValue = "",
      error = "",
      ...props
    },
    ref: React.Ref<HTMLInputElement | null>
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current);

    const [selfValue, setSelfValue] = useState<string>(initialValue);
    const [, setHover] = useState<boolean>(false);
    const { height, fontSize, iconClasses } = useMemo(
      () => getSizes(size),
      [size]
    );
    const isControlledComponent = useMemo(() => value !== undefined, [value]);

    const colors = useMemo(() => getColors(status), [status]);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled || readOnly) return;
      setSelfValue(event.target.value);
      onChange && onChange(event);
    };
    // const clearHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    //   setSelfValue("");
    //   onClearClick && onClearClick(event);
    //   /* istanbul ignore next */
    //   if (!inputRef.current) return;

    //   const changeEvent = simulateChangeEvent(inputRef.current, event);
    //   changeEvent.target.value = "";
    //   onChange && onChange(changeEvent);
    //   inputRef.current.focus();
    // };

    const focusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      setHover(true);
      onFocus && onFocus(e);
    };
    const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      setHover(false);
      onBlur && onBlur(e);
    };

    const iconClickHandler = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (disabled) return;
        onIconClick && onIconClick(e);
      },
      [disabled, onIconClick]
    );
    const iconProps = useMemo(
      () => ({
        clickable: iconClickable,
        onClick: iconClickHandler,
      }),
      [iconClickable, iconClickHandler]
    );

    useEffect(() => {
      if (isControlledComponent) {
        setSelfValue(value as string);
      }
    }, [isControlledComponent, value]);

    const controlledValue = isControlledComponent
      ? { value: selfValue }
      : { defaultValue: initialValue };
    const inputProps = {
      ...props,
      ...controlledValue,
    };

    return (
      <div className="with-label inline-block box-border">
        {children && <InputBlockLabel name={name}>{children}</InputBlockLabel>}
        <div
          className={`input-container ${height} inline-flex items-center ${className}`}
        >
          {label && (
            <InputLabel fontSize={fontSize} colors={colors}>
              {label}
            </InputLabel>
          )}
          <div
            className={`input-wrapper box-border inline-flex align-middle items-center h-full flex-1 select-none rounded-md border transition-color duration-200 ease-out ${
              disabled
                ? "bg-gray-700 border-gray-900 text-gray-600"
                : !!error
                  ? "border-red"
                  : colors
            } ${label ? "rounded-tl-none rounded-bl-none" : ""} ${
              labelRight ? "rounded-tr-none rounded-br-none" : ""
            }`}
          >
            {icon && (
              <InputIcon icon={icon} className={iconClasses} {...iconProps} />
            )}
            <input
              type={type ?? "text"}
              ref={inputRef}
              className={`${fontSize} my-1 mx-3 p-0 shadow-none ${
                textOrientation === "right" ? "text-right" : ""
              } text-inherit bg-transparent placeholder:text-inherit placeholder:opacity-50 placeholder:text-sm border-none outline-none focus:ring-0 rounded-md w-full min-w-0 ${
                disabled ? "cursor-not-allowed" : ""
              } ${icon ? "ml-0" : ""} ${iconRight ? "mr-0" : ""}`}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              onFocus={focusHandler}
              onBlur={blurHandler}
              onChange={changeHandler}
              autoComplete={autoComplete}
              id={[name, "input"].join("-")}
              name={name}
              {...inputProps}
            />
            {iconRight && (
              <InputIcon
                icon={iconRight}
                className={iconClasses}
                {...iconProps}
              />
            )}
          </div>
          {labelRight && (
            <InputLabel fontSize={fontSize} isRight={true} colors={colors}>
              {labelRight}
            </InputLabel>
          )}
        </div>
        {!!error && (
          <p className="text-red mt-1 text-sm" role="alert">
            {error}
          </p>
        )}
        <style jsx>{`
          .with-label {
            width: ${width};
            -webkit-box-align: center;
          }
          .input-container {
            width: ${width};
          }
        `}</style>
      </div>
    );
  }
);

export default Input;

import { NormalTypes } from "../../lib/util-types";
import React, {
  useRef,
  useImperativeHandle,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getColors } from "../input/styles";
import s from "./textarea.module.css";
interface Props {
  name?: string;
  value?: string;
  label?: string;
  initialValue?: string;
  placeholder?: string;
  status?: NormalTypes;
  width?: string;
  error?: string;
  minHeight?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  className?: string;
}

type NativeAttrs = Omit<React.TextareaHTMLAttributes<any>, keyof Props>;
export type TextareaProps = Props & NativeAttrs;

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.PropsWithChildren<TextareaProps>
>(
  (
    {
      name,
      onFocus,
      onBlur,
      onChange,
      value,
      placeholder,
      label,
      initialValue = "",
      error = "",
      status = "default" as NormalTypes,
      width = "initial",
      minHeight = "6.25rem",
      disabled = false,
      readOnly = false,
      className = "",
      ...props
    },
    ref: React.Ref<HTMLTextAreaElement | null>
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => textareaRef.current);
    const isControlledComponent = useMemo(() => value !== undefined, [value]);
    const [selfValue, setSelfValue] = useState<string>(initialValue);
    const [hover, setHover] = useState<boolean>(false);
    const colors = useMemo(() => getColors(status), [status]);

    const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (disabled || readOnly) return;
      setSelfValue(event.target.value);
      onChange && onChange(event);
    };
    const focusHandler = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setHover(true);
      onFocus && onFocus(e);
    };
    const blurHandler = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setHover(false);
      onBlur && onBlur(e);
    };

    useEffect(() => {
      if (isControlledComponent) {
        setSelfValue(value as string);
      }
    });

    const controlledValue = isControlledComponent
      ? { value: selfValue }
      : { defaultValue: initialValue };
    const textareaProps = {
      ...props,
      ...controlledValue,
    };

    return (
      <div>
        <div
          className={`text-sm text-gray-700 mb-2 ${!label ? "hidden" : null}`}
        >
          <label
            className="text-sm font-medium"
            id={[name, "label"].join("-")}
            htmlFor={[name, "textarea"].join("-")}
          >
            {label}
          </label>
        </div>
        <div
          className={`${
            s.wrapper
          } inline-flex box-border select-none min-w-4xs h-auto border rounded-none  ${
            disabled
              ? s.disabled +
                " bg-gray-100 border-gray-200 text-gray-700 cursor-not-allowed"
              : !!error
                ? "border-red"
                : colors
          } ${className}`}
          style={width ? { width } : {}}
        >
          <textarea
            className={`${s.textarea} text-sm bg-transparent shadow-none block w-full h-full resize-none border-none outline-none p-2  focus: focus:ring-0`}
            name={name}
            ref={textareaRef}
            disabled={disabled}
            placeholder={placeholder}
            readOnly={readOnly}
            onFocus={focusHandler}
            onBlur={blurHandler}
            onChange={changeHandler}
            style={minHeight ? { minHeight } : {}}
            {...textareaProps}
          />
        </div>
        {!!error && (
          <p className="text-red mt-1 text-sm" role="alert">
            {error}
          </p>
        )}
      </div>
    );
  }
);

export default Textarea;

import { NormalSizes, NormalTypes } from "../../lib/util-types";

export type InputSize = {
  height: string;
  fontSize: string;
  iconClasses: string;
};

export const getSizes = (size?: NormalSizes) => {
  const sizes: { [key in NormalSizes]: InputSize } = {
    small: {
      height: "h-9",
      fontSize: "text-sm",
      iconClasses: "w-5 px-2",
    },
    medium: {
      height: "h-12",
      fontSize: "text-base",
      iconClasses: "w-6 px-2",
    },
    large: {
      height: "h-14",
      fontSize: "text-base",
      iconClasses: "w-7 px-2",
    },
  };
  if (!size) return sizes.medium;
  return sizes[size];
};

export type InputTypes = NormalTypes | "search";

export const getColors = (status?: InputTypes): string => {
  const colors: { [key in InputTypes]: string } = {
    default:
      "text-foreground placeholder:text-foreground-soft border-gray-800 bg-gray-800 hover:border-primary group-hover:border-primary focus-within:border-primary",
    secondary:
      "text-foreground border-secondary bg-secondary hover:border-on-secondary focus-within:border-on-secondary group-hover:border-on-secondary",
    primary:
      "text-foregorund border-primary-light bg-white hover:border-primary focus-within:border-primary group-hover:border-primary",
    warning:
      "text-foreground border-yellow-200 bg-white hover:border-yellow-500 focus-within:border-yellow-500 group-hover:border-yellow-500",
    error:
      "text-error border-red-500 bg-white hover:border-red-700 focus-within:border-red-700 group-hover:border-red-700",
    search:
      "text-foreground-soft border-gray-700 bg-gray-800 hover:border-gray-300 focus-within:border-gray-300 group-hover:border-gray-300",
  };

  if (!status) return colors.default;
  return colors[status];
};

/* eslint-disable react/display-name */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Listbox, Transition } from "@headlessui/react";
import { getSelectColors, getSizes } from "./styles";
import { NormalSizes, SelectTypes } from "@lib/util-types";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import s from "./select.module.css";
import { Button, Input } from "@ui";
import { arraysHaveSameValues } from "@lib/util-functions/array";
import { AVAILABLE_MARKETING_CHANNEL_OPTIONS } from "constants/constants";

type Props = {
  disabled?: boolean;
  size?: NormalSizes;
  value?: string | string[] | null;
  error?: string;
  label?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  blockLabel?: string | React.ReactNode;
  blockDescription?: string | React.ReactNode;
  initialValue?: string | string[];
  placeholder?: string;
  icon?: JSX.Element;
  onChange?: (value: any) => void;
  type?: SelectTypes;
  pure?: boolean;
  multiple?: boolean;
  className?: string;
  width?: string;
  borderColor?: string;
  searchable?: boolean;
  options: { label: string | React.ReactNode; value: any }[];
  dropdownClassName?: string;
  dropdownStyle?: object;
  onClear?: () => void;
  disableMatchWidth?: boolean;
  labelHelper?: React.ReactNode;
  alwaysIncludeValue?: string;
  alwaysOneValue?: boolean;
  onOneSelectedFailure?: () => void;
  truncateLabel?: boolean;
};
type NativeAttrs = Omit<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  keyof Props
> & {};

export type SelectProps = Props & NativeAttrs;

export const Select = React.forwardRef<
  HTMLSelectElement,
  React.PropsWithChildren<SelectProps>
>(
  (
    {
      value,
      onChange,
      children,
      searchable,
      disabled,
      className,
      prefix,
      onFocus,
      label,
      blockLabel,
      blockDescription,
      name,
      type = "default",
      id,
      borderColor,
      suffix,
      options,
      placeholder,
      initialValue = "",
      error = "",
      size,
      multiple,
      defaultValue,
      onClear,
      width,
      dropdownClassName = "",
      hidden,
      labelHelper,
      alwaysIncludeValue,
      alwaysOneValue,
      onOneSelectedFailure,
      truncateLabel = true,
      ...props
    },
    ref: React.Ref<HTMLSelectElement | null>
  ) => {
    const selectRef = useRef<HTMLSelectElement>(null);
    useImperativeHandle(ref, () => selectRef.current);
    const { height } = useMemo(() => getSizes(size), [size]);
    const [query, setQuery] = useState("");
    const filteredOptions = useMemo(() => {
      if (query) {
        return options.filter(
          (option) =>
            (typeof option.label === "string" &&
              option.label?.toLowerCase().includes(query.toLowerCase())) ||
            `${option.value}`.toLowerCase().includes(query.toLowerCase())
        );
      }
      return options;
    }, [query, options]);

    // Initialize the internal state with the value or defaultValue
    const [selfValue, setSelfValue] = useState<typeof value>(
      value ||
        initialValue ||
        (defaultValue as typeof value) ||
        (multiple ? [] : "")
    );

    const onChangeSelfValue = useCallback(
      (val: string | string[] | null) => {
        if (Array.isArray(val)) {
          if (alwaysIncludeValue && !val.includes(alwaysIncludeValue)) {
            return setSelfValue([alwaysIncludeValue, ...val]);
          }
          if (alwaysOneValue) {
            if (val.length > 0) {
              setSelfValue([...val]);
            } else {
              onOneSelectedFailure?.();
            }
          } else {
            setSelfValue(val);
          }
        } else {
          setSelfValue(val);
        }
      },
      [alwaysIncludeValue, alwaysOneValue, onOneSelectedFailure]
    );

    // Effect to update internal state when value changes
    useEffect(() => {
      if (value !== undefined) {
        onChangeSelfValue(value);
      } else if (selectRef.current?.value) {
        if (multiple) {
          const eventValue = Object.values(
            selectRef.current?.selectedOptions
          ).map((option) => option.value) as Array<
            (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"]
          >;
          onChangeSelfValue(eventValue);
        } else {
          onChangeSelfValue(selectRef.current?.value as string);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alwaysIncludeValue, JSON.stringify(value ?? "")]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (onChange) {
        onChange(event); // Propagate the change to the parent component
      }
    };

    const colors = useMemo(() => getSelectColors(type), [type]);
    useEffect(() => {
      if (
        selfValue !== "" &&
        selfValue !== null &&
        ((value && selfValue !== value) || selfValue !== initialValue)
      ) {
        const evt = new Event("change", { bubbles: true });
        if (selectRef?.current) {
          selectRef?.current?.dispatchEvent(evt);
        }
      }
      // we only want this effect to trigger when selfValues is changing
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selfValue]);
    return (
      <>
        <Listbox
          value={selfValue}
          onChange={(val: string | string[]) => {
            const haveSameValues =
              Array.isArray(val) && Array.isArray(selfValue)
                ? arraysHaveSameValues(val, selfValue)
                : val === selfValue;
            if (!haveSameValues) {
              onChangeSelfValue(val);
              setQuery("");
            }
          }}
          multiple={multiple}
          disabled={disabled}
          id={id}
          as="div"
          className={hidden ? "hidden" : ""}
        >
          {blockLabel ? (
            <label
              htmlFor={[name, "select"].join("-")}
              className="font-semibold text-sm text-foreground-soft mb-2 block"
            >
              {blockLabel}
            </label>
          ) : null}

          {blockDescription && typeof blockDescription === "string" ? (
            <p className="text-xs text-foreground-soft mb-3 block">
              {blockDescription}
            </p>
          ) : (
            blockDescription
          )}
          <div
            className={`relative text-foreground ${height} ${
              width ? width : ""
            }`}
          >
            <Listbox.Button
              className={`relative z-30 w-full h-full px-6 text-left transition-color rounded-md duration-200 border ${
                s.select
              } ${
                !!error
                  ? "border-red bg-gray-800 text-foreground"
                  : disabled
                    ? "bg-gray-700 text-foreground border-gray-600 filter bg-blend-darken cursor-not-allowed opacity-30"
                    : colors
              }  sm:text-sm ${className}`}
              onClick={(e: any) => e.stopPropagation()}
            >
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className={`h-5 w-5 ${
                    ["lite", "default"].includes(type)
                      ? disabled
                        ? "text-foreground"
                        : "text-primary"
                      : "text-foreground"
                  }`}
                  aria-hidden="true"
                />
              </span>
              <span className="flex items-center space-x-0.5 truncate font-semibold mr-2">
                {placeholder && !selfValue && <span>{placeholder}</span>}
                {prefix && <span>{prefix} </span>}
                <span>
                  {multiple && selfValue && selfValue?.length > 3 && label
                    ? `${label} (${selfValue?.length})`
                    : options
                        ?.filter(
                          (el) =>
                            el?.value?.toString() === `${selfValue}` ||
                            (multiple && selfValue?.includes(`${el.value}`))
                        )
                        ?.map((el, index) =>
                          typeof el.label === "string"
                            ? `${index > 0 ? ", " : ""}${el.label}`
                            : el.label
                        )}
                </span>
                {suffix && <span>{suffix}</span>}
              </span>
            </Listbox.Button>
            {onClear && selfValue && (
              <div
                className={`absolute ${
                  size === "small" ? "left-0.5 top-1.5" : "left-1 top-3"
                } z-30`}
              >
                <Button
                  size="tiny"
                  type="text"
                  iconButton
                  icon={<XMarkIcon />}
                  onClick={() => {
                    onClear();
                    setSelfValue(null);
                  }}
                />
              </div>
            )}
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute z-40 scrollbar w-full min-w-max py-1 mt-1 rounded-md overflow-auto text-base bg-gray-800 shadow-lg text-foreground max-h-60 ring-1 ring-secondary ring-opacity-5 cursor-pointer focus:outline-none sm:text-sm ${dropdownClassName}`}
              >
                {searchable ? (
                  <div className="px-4 py-1">
                    <Input
                      status="search"
                      size="small"
                      placeholder="Search Influencer"
                      onChange={(event) => setQuery(event.target.value)}
                      value={query}
                      width="100%"
                      autoComplete="off"
                    />
                  </div>
                ) : null}

                {label && (
                  <div className="flex items-center font-semibold text-xs px-4 pt-2 pb-4 cursor-default ">
                    <div className={`${truncateLabel ? "truncate" : ""}`}>
                      {typeof label === "string" ? <p>{label}</p> : label}
                    </div>
                    {label && labelHelper && (
                      <div className="ml-auto ps-2 cursor-pointer">
                        {labelHelper}
                      </div>
                    )}
                  </div>
                )}
                {filteredOptions?.map((el, index) => (
                  <Listbox.Option
                    className={({ active }) =>
                      `${active ? "text-primary bg-gray-700" : "text-gray-200"}
                            cursor-pointer select-none relative py-2 pl-10 pr-4 z-40`
                    }
                    value={el.value}
                    id={`${el.value}-${index}`}
                    key={`${el.value}-${index}`}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block `}
                        >
                          {el.label}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? "text-primary" : "text-primary"
                            }
                                  absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {!!error && (
            <p className="text-red mt-1 text-sm" role="alert">
              {error}
            </p>
          )}
        </Listbox>
        <select
          className="hidden"
          ref={selectRef}
          disabled={disabled}
          value={selfValue ?? value ?? undefined}
          onChange={handleChange}
          multiple={multiple}
          id={[name, "select"].join("-")}
          name={name}
        >
          {options?.map((el, index) => (
            <option value={el.value} key={el.value?.toString() + index}>
              {el.label}
            </option>
          ))}
        </select>
      </>
    );
  }
);
export default Select;

/* eslint-disable no-unused-vars */
import { ButtonProps } from "./button";
import { ButtonNormalSizes, ButtonTypes } from "../../lib/util-types";
import { addColorAlpha } from "../../lib/util-functions";
import s from "./button.module.css";

export interface ButtonColorGroup {
  bg: string;
  border: string;
  color: string;
}

export const getButtonGhostColors = (type: ButtonTypes): string | null => {
  const colors: { [key in ButtonTypes]?: string } = {
    secondary: s.secondaryGhostBtn,
    primary: s.primaryGhostBtn,
    warning: s.warningGhostBtn,
    error: s.errorGhostBtn,
  };

  return colors[type] || null;
};

export const getButtonColors = (props: ButtonProps): string => {
  const { type, disabled, ghost, loading } = props;
  const colors: { [key in ButtonTypes]: string } = {
    default: s.defaultBtn,
    outline: s.outlineBtn,
    secondary: s.secondaryBtn,
    primary: s.primaryBtn,
    warning: s.warningBtn,
    error: s.errorBtn,
    abort: s.abortBtn,
    text: s.textBtn,
  };
  if (disabled) return s.disabledBtn;
  const defaultColor = colors.default;
  if (!type) return defaultColor;
  if (ghost) return getButtonGhostColors(type) || defaultColor;
  return colors[type] || defaultColor;
};
export interface ButtonCursorGroup {
  cursor: string;
  events: string;
}

export const getButtonCursor = (
  disabled: boolean,
  loading: boolean
): string => {
  if (disabled) return "cursor-not-allowed pointer-events-auto";
  if (loading) return "cursor-default pointer-events-none";
  return "cursor-pointer pointer-events-auto";
};

export type ButtonSizeGroup = {
  height: string;
  width: string;
  padding: string;
  minWidth: string;
  fontSize: string;
};

export const getButtonSize = (
  size: ButtonNormalSizes = "medium",
  auto: boolean,
  iconButton: boolean
): string => {
  const defaultLayout = "h-12 px-5 text-sm min-w-4xs max-w-full";

  const autoLayouts: { [key in ButtonNormalSizes]: string } = {
    tiny: "h-6 px-1 text-xs w-min w-auto",
    small: "h-9 px-2 text-xs w-min w-auto",
    medium: "h-12 px-3 text-sm w-min w-auto",
    large: "h-14 px-6 text-sm w-min w-auto",
  };
  const iconButtonLayouts: { [key in ButtonNormalSizes]: string } = {
    tiny: "h-6 px-1 text-xs w-min w-auto",
    small: "h-7 px-1 text-sm w-min w-auto",
    medium: "h-10 px-2 text-sm w-min w-auto",
    large: "h-12 px-4 text-base w-min w-auto",
  };
  const layouts: { [key in ButtonNormalSizes]: string } = {
    tiny: "h-6 px-2 text-xs min-w-5xs max-w-full",
    small: "h-9 px-4 text-xs min-w-5xs max-w-full",
    medium: defaultLayout,
    large: "h-14 px-7 text-sm min-w-2xs max-w-full",
  };
  if (auto) return autoLayouts[size] || defaultLayout;
  if (iconButton) return iconButtonLayouts[size] || defaultLayout;

  return layouts[size] || defaultLayout;
};
export const getPaddingForAutoMode = (
  size: ButtonNormalSizes = "medium",
  isRight: boolean
): string => {
  const defaultPaddings = `${isRight ? "pr-6" : "pl-6"}`;

  const paddings: { [key in ButtonNormalSizes]: string } = {
    medium: defaultPaddings,
    tiny: `${isRight ? "pr-3" : "pl-3"}`,
    small: `${isRight ? "pr-5" : "pl-5"}`,
    large: `${isRight ? "pr-8" : "pl-8"}`,
  };

  return paddings[size] || defaultPaddings;
};

export const getButtonIconStyles = (
  size: ButtonNormalSizes = "medium",
  auto: boolean,
  iconButton: boolean,
  isRight: boolean
): string => {
  const defaultLayout = `h-4 ${
    isRight ? "right-5 left-auto" : "right-auto left-5"
  }`;

  const autoLayouts: { [key in ButtonNormalSizes]: string } = {
    tiny: `h-3 ${isRight ? "right-1 left-auto" : "right-auto left-1"}`,
    small: `h-3 ${isRight ? "right-2 left-auto" : "right-auto left-2"}`,
    medium: `h-4 ${isRight ? "right-3 left-auto" : "right-auto left-3"}`,
    large: `h-6 ${isRight ? "right-6 left-auto" : "right-auto left-6"}`,
  };
  const iconButtonLayouts: { [key in ButtonNormalSizes]: string } = {
    tiny: `h-[14px] ${isRight ? "right-1 left-auto" : "right-auto left-1"}`,
    small: `h-3 ${isRight ? "right-1 left-auto" : "right-auto left-1"}`,
    medium: `h-4 ${isRight ? "right-2 left-auto" : "right-auto left-2"}`,
    large: `h-6 ${isRight ? "right-4 left-auto" : "right-auto left-4"}`,
  };
  const layouts: { [key in ButtonNormalSizes]: string } = {
    tiny: `h-3 ${isRight ? "right-3 left-auto" : "right-auto left-3"}`,
    small: `h-3 ${isRight ? "right-5 left-auto" : "right-auto left-5"}`,
    medium: defaultLayout,
    large: `h-6 ${isRight ? "right-7 left-auto" : "right-auto left-7"}`,
  };

  if (auto) return autoLayouts[size] || defaultLayout;
  if (iconButton) return iconButtonLayouts[size] || defaultLayout;

  return layouts[size] || defaultLayout;
};

export const getButtonDropdownIconStyles = (
  size: ButtonNormalSizes = "medium"
): string => {
  const defaultLayout = `16px`;
  const layouts: { [key in ButtonNormalSizes]: string } = {
    tiny: `10px`,
    small: `12px`,
    medium: defaultLayout,
    large: `24px`,
  };
  return layouts[size] || defaultLayout;
};

export const getButtonDripColor = (props: ButtonProps) => {
  const { type } = props;
  return addColorAlpha("#eaeaea", 0.65);
};

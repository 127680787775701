import React, { PropsWithChildren, useEffect, useMemo, useRef } from "react";

import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  ExpandedState,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  Table,
  Overwrite,
  TableState,
  ColumnResizeMode,
  ColumnFiltersState,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  RowSelectionState,
  OnChangeFn,
  getFacetedUniqueValues,
  ColumnSizingState,
  createColumnHelper,
  flexRender,
} from "@tanstack/react-table";
import { TablePagination } from "./TablePagination";
import { Checkbox, Input } from "@ui";
import { ArrowUpIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { TablePaginationNew } from "./TablePaginationNew";
import { getLocalStorage } from "@lib/util-functions/getLocalStorage";
import { useReadLocalStorage } from "usehooks-ts";
import { BaseScore } from "@api/types/backendTypes";
const defaultColumnWidth = 150;
export interface TableProperties<T extends Record<string, any>> {
  data: T[];
  onFilter?: ({
    filterValue,
    table,
  }: {
    filterValue: string;
    table: Table<T>;
  }) => void;
  showFooter?: boolean;
  newTablePagination?: boolean;
  showIndexForColumns?: string[];
  enableResizing?: boolean;
  enableRowSelection?: boolean;
  onRowSelectionChange?: (val: RowSelectionState) => void;
  onResetRowSelection?: (val: boolean) => void;
  rowSelection?: RowSelectionState;
  maxHeight?: string | number;
  initialState?: Partial<TableState>;
  visibleColumns?: Record<string, boolean>;
  columnsOrder?: string[];
  pinnedColumns?: {
    left?: string[];
    right?: string[];
  };
  hidePagination?: boolean;
  disableAscSorting?: boolean;
  columnFilters?: ColumnFiltersState;
  header?: React.ReactNode;
  emptyState?: React.ReactNode;
  hideRows?: boolean;
  loadingState?: React.ReactNode;
  defaultSorting?: SortingState;
  enableGlobalFilter?: boolean;
  manualExpanding?: boolean;
  expandAll?: boolean;
  globalFilterComponent?: JSX.Element;
  disableRowsPerPage?: boolean;
  columns: ColumnDef<T, any>[];
  tdClassName?: string;
  spacing?: "normal" | "wide" | "tight";
}
let globalColumnSizing = {};

export default function TableComponent<T extends Record<string, any>>({
  data,
  columns,
  header,
  emptyState,
  disableRowsPerPage,
  visibleColumns,
  columnsOrder,
  pinnedColumns,
  hidePagination,
  loadingState,
  defaultSorting,
  manualExpanding = false,
  enableGlobalFilter,
  globalFilterComponent,
  maxHeight,
  showIndexForColumns = [],
  columnFilters: columnFiltersProps = [],
  onResetRowSelection,
  showFooter,
  enableResizing,
  initialState,
  newTablePagination,
  rowSelection,
  onRowSelectionChange,
  enableRowSelection,
  onFilter,
  hideRows,
  disableAscSorting,
  expandAll = false,
  tdClassName = "",
  spacing = "normal",
}: PropsWithChildren<TableProperties<T>>) {
  const [sorting, setSorting] = React.useState<SortingState>(
    defaultSorting ?? []
  );
  const [columnResizeMode] = React.useState<ColumnResizeMode>("onChange");

  const [expanded, setExpanded] = React.useState<ExpandedState>(
    () => expandAll as ExpandedState
  );
  const minHeight =
    spacing === "normal"
      ? "min-h-[2.75rem]"
      : spacing === "wide"
        ? "min-h-[3.25rem]"
        : "min-h-[2.25rem]";
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [columnVisibility, setColumnVisibility] =
    React.useState(visibleColumns);
  const [columnPinning, setColumnPinning] = React.useState(pinnedColumns ?? {});
  const [columnOrder, setColumnOrder] = React.useState(columnsOrder ?? []);
  const columnSizing = useReadLocalStorage("columnSizing") as ColumnSizingState;
  useEffect(() => {
    setColumnOrder(columnsOrder ?? []);
  }, [columnsOrder]);
  useEffect(() => {
    setColumnVisibility(visibleColumns ?? {});
  }, [visibleColumns]);
  useEffect(() => {
    setColumnPinning(pinnedColumns ?? {});
  }, [pinnedColumns]);

  const state: Partial<TableState> = {
    sorting,
    expanded,
    globalFilter,
    columnVisibility,
    columnPinning,
    columnOrder,
    rowSelection: rowSelection ?? {},
  };

  const onSortingChange: React.Dispatch<React.SetStateAction<SortingState>> = (
    action
  ) => {
    if (typeof action === "function") {
      setSorting((prevState) => {
        const newState = action(prevState);
        // disable ascending sorting
        if (
          disableAscSorting &&
          Boolean(newState.find((el) => el.desc === false))
        ) {
          return prevState;
        }
        return newState;
      });
    } else {
      if (
        disableAscSorting &&
        Boolean(action.find((el) => el.desc === false))
      ) {
        return; // don't perform any action
      }

      setSorting(action);
    }
  };

  const table = useReactTable({
    data: data,
    columns,
    state,
    initialState: {
      ...initialState,
      pagination: {
        pageSize: 100,
        ...(initialState?.pagination ?? {}),
      },
      columnSizing: initialState?.columnSizing ?? globalColumnSizing,
    },
    enableRowSelection,
    onRowSelectionChange:
      onRowSelectionChange && rowSelection
        ? (updaterOrValue) => {
            if (typeof updaterOrValue === "function") {
              onRowSelectionChange(updaterOrValue(rowSelection));
            } else {
              onRowSelectionChange(updaterOrValue);
            }
          }
        : undefined,
    columnResizeMode,
    manualFiltering: !!onFilter,
    manualExpanding: manualExpanding,
    onSortingChange: onSortingChange,
    onExpandedChange: setExpanded,
    onGlobalFilterChange: (value) => {
      setGlobalFilter(value);
      onFilter && onFilter({ filterValue: value, table });
    },
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    // @ts-ignore
    getFacetedUniqueValues: getFacetedUniqueValues(),
    // @ts-ignore
    onColumnVisibilityChange: setColumnVisibility,
    onColumnPinningChange: setColumnPinning,
    onColumnOrderChange: setColumnOrder,
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // debugTable: process.env.NODE_ENV === "development",
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      const allColumns = table.getAllColumns();
      const columnSizesToSet = columnSizing ?? globalColumnSizing;
      for (const column of allColumns) {
        if (column.getCanResize() === false) {
          delete columnSizesToSet[column.id];
        }
      }
      table.setColumnSizing(columnSizesToSet);
    }, 400);
    if (columnSizing) globalColumnSizing = columnSizing;
    return () => clearTimeout(handler);
  }, [columnSizing, table]);

  const columnSizingProps = table.getState().columnSizing;
  useEffect(() => {
    const handler = setTimeout(() => {
      const stateSizes = getLocalStorage()?.getItem("columnSizing") ?? "{}";
      const mergedSizing = { ...JSON.parse(stateSizes), ...columnSizingProps };
      getLocalStorage()?.setItem("columnSizing", JSON.stringify(mergedSizing));
    }, 400);

    return () => clearTimeout(handler);
  }, [columnSizingProps]);

  const tableRef = useRef<HTMLDivElement | null>(null);
  const instanceColumns = table
    ?.getAllColumns()
    .filter((el) => el.getIsVisible());
  const tableSize = useMemo(() => {
    const columnWidthToAdd = instanceColumns.reduce((prev, curr) => {
      return prev + (curr.getSize() ?? defaultColumnWidth);
    }, 0);

    const containerWidth = tableRef.current?.clientWidth;
    return {
      width:
        containerWidth && containerWidth > columnWidthToAdd
          ? containerWidth
          : columnWidthToAdd,
      columnWidth: columnWidthToAdd,
    };
  }, [instanceColumns]);
  // when we have an emptyState, we only want to show 1 row, with a loading state, we want to show 5
  const emptyAndLoadingStateRowArray = emptyState ? [1] : [1, 2, 3, 4, 5];

  const addPlaceholderColumn = tableSize.width > tableSize.columnWidth;

  return (
    <div>
      {(header || enableGlobalFilter) && (
        <>
          <div className="flex justify-between">
            {header && <div className="max-w-1/2">{header}</div>}
            {enableGlobalFilter && (
              <div className="max-w-1/2">
                {globalFilterComponent ? (
                  React.cloneElement(globalFilterComponent, {
                    table: table,
                  })
                ) : (
                  <Filter table={table as any} />
                )}
              </div>
            )}
          </div>
        </>
      )}

      <div className="relative w-full">
        <div
          className={`overflow-x-auto ${
            !maxHeight
              ? ""
              : typeof maxHeight === "string"
                ? maxHeight
                : "max-h-" + maxHeight
          } relative scrollbar w-full min-w-full rounded-md`}
          ref={tableRef}
        >
          <table
            className=" whitespace-nowrap table-fixed relative border-separate border-spacing-y-2"
            {...{
              style: enableResizing
                ? {
                    width: tableSize.width,
                  }
                : {},
            }}
          >
            {table.getHeaderGroups().map((headerGroup, index) => (
              <colgroup key={index}>
                {headerGroup.headers.map((header, headerIndex) => {
                  return (
                    <col
                      width={`${
                        enableResizing && header.column.getCanResize()
                          ? header.getSize()
                          : header.column.getSize()
                            ? header.column.getSize()
                            : defaultColumnWidth
                      }px`}
                      key={headerIndex}
                    />
                  );
                })}
                {addPlaceholderColumn ? (
                  <col width={tableSize.width - tableSize.columnWidth} />
                ) : null}
              </colgroup>
            ))}
            <thead className="sticky top-0 z-20">
              {table.getHeaderGroups().map((headerGroup, index) => (
                <tr
                  key={`header-${headerGroup.id}-${index}`}
                  className="w-full"
                >
                  {headerGroup.headers.map((header, headerIndex) => {
                    return (
                      <th
                        key={`header-${header.id}-${headerIndex}`}
                        className={`border-collapse max-w-[1px] bg-gray-700 border-spacing-0 py-0 px-4 text-sm font-semibold text-left align-middle text-foreground rounded-none ${
                          header.column.getIsPinned()
                            ? "sticky left-0 z-20"
                            : "relative z-10"
                        }`}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            className={
                              "min-h-[2.5rem] border-y-4 border-transparent flex items-center max-w-[140px] break-words whitespace-normal"
                            }
                          >
                            {header.column.getCanSort() ? (
                              <div
                                {...{
                                  className: `flex items-center cursor-pointer hover:font-medium hover:text-foreground ${
                                    header.column.getIsSorted()
                                      ? "font-semibold text-foreground"
                                      : ""
                                  }`,
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                <span
                                  className={`transform transition-all duration-150 ml-2 ${
                                    header.column.getIsSorted() === "desc"
                                      ? "rotate-180"
                                      : "rotate-0"
                                  }
                              ${
                                header.column.getIsSorted()
                                  ? "opacity-100"
                                  : "opacity-0 hover:opacity-100"
                              }
                              `}
                                >
                                  <ArrowUpIcon className="h-3 w-3 text-rateIncrease" />
                                </span>
                              </div>
                            ) : (
                              <div className="max-w-full">
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </div>
                            )}
                            {header.column.getCanResize() && enableResizing && (
                              <div
                                onMouseDown={header.getResizeHandler()}
                                onTouchStart={header.getResizeHandler()}
                                className={`absolute right-1 cursor-col-resize text-primary select-none touch-none`}
                              >
                                <ChevronUpDownIcon className="transform rotate-90 h-5 w-5" />
                              </div>
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                  {addPlaceholderColumn ? <th className="bg-gray-700" /> : null}
                </tr>
              ))}
            </thead>
            <tbody className="relative">
              {emptyState || loadingState
                ? emptyAndLoadingStateRowArray.map((el) => (
                    <tr
                      className={`transition-colors ease-out bg-gray-800 rounded-xl ${
                        loadingState ? "animate-pulse" : ""
                      }`}
                      key={el}
                    >
                      <td
                        colSpan={
                          table.getAllColumns().length +
                          (addPlaceholderColumn ? 1 : 0)
                        }
                        className="rounded-xl"
                      >
                        <div
                          className={`flex items-center row-wrap ${minHeight} rounded-xl`}
                        >
                          {emptyState ? emptyState : null}
                        </div>
                      </td>
                    </tr>
                  ))
                : hideRows
                  ? null
                  : table.getRowModel().rows.map((row, index) => {
                      return (
                        <tr
                          key={`body-${row.id}-${index}`}
                          className={`${
                            expandAll && row.depth > 0
                              ? "bg-cardPrimary cursor-default"
                              : "bg-gray-800"
                          } group/row transition-colors duration-200 ease-out rounded-xl`}
                          id={`row-${index}`}
                        >
                          {row.getVisibleCells().map((cell, cellIndex) => {
                            return (
                              <td
                                key={`body-${cell.id}-${cellIndex}`}
                                className={`group/cell py-1 px-4 text-foreground max-w-[1px] text-sm text-left ${
                                  expandAll ? "card-primary" : "bg-gray-800"
                                } ${tdClassName}   ${
                                  cell.column.getIsPinned()
                                    ? "sticky left-0 z-10"
                                    : "relative z-0"
                                } ${
                                  cellIndex === 0
                                    ? `rounded-l-xl ${
                                        expandAll
                                          ? row.depth > 0
                                            ? "bg-cardPrimary"
                                            : "bg-gray-800"
                                          : ""
                                      }`
                                    : cellIndex + 1 ===
                                          row.getVisibleCells().length &&
                                        !addPlaceholderColumn
                                      ? "rounded-r-xl"
                                      : ""
                                }`}
                              >
                                <div
                                  className={`flex items-center row-wrap ${minHeight}`}
                                >
                                  {showIndexForColumns.includes(
                                    cell.column.id
                                  ) && (
                                    <span className="mr-1">{index + 1}.</span>
                                  )}
                                  {cell.getIsPlaceholder()
                                    ? null
                                    : flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                </div>
                              </td>
                            );
                          })}
                          {addPlaceholderColumn ? (
                            <td
                              className={`group/cell py-1 px-4 text-foreground max-w-[1px] text-sm text-left relative z-0 rounded-r-xl ${
                                expandAll && row.depth > 0
                                  ? "bg-cardPrimary"
                                  : "bg-gray-800"
                              }`}
                            />
                          ) : null}
                        </tr>
                      );
                    })}
              {showFooter &&
                data &&
                !loadingState &&
                !emptyState &&
                table.getFooterGroups().map((footerGroup, index) => (
                  <tr
                    key={`footer-${footerGroup.id}-${index}`}
                    className={`sticky inset-x-0 bottom-0 z-30 transition-colors duration-200 ease-out bg-primary rounded-xl`}
                  >
                    {footerGroup.headers.map((header, footerIndex) => {
                      return (
                        <td
                          key={`footer-${header.id}-${footerIndex}`}
                          className={`py-1 px-4 text-on-primary text-sm text-left bg-primary  ${
                            header.column.getIsPinned()
                              ? "sticky left-0 z-20"
                              : "relative z-10"
                          } ${
                            footerIndex === 0
                              ? "rounded-tl-xl rounded-bl-lg"
                              : footerIndex + 1 ===
                                    footerGroup.headers.length &&
                                  !addPlaceholderColumn
                                ? "rounded-tr-xl rounded-br-lg"
                                : ""
                          }`}
                        >
                          <div
                            className={`flex items-center row-wrap ${minHeight} h-full`}
                          >
                            {flexRender(
                              header.column.columnDef.footer,
                              header.getContext()
                            )}{" "}
                          </div>
                        </td>
                      );
                    })}
                    {addPlaceholderColumn ? (
                      <td
                        className={`group/cell py-1 px-4 max-w-[1px] text-sm text-left bg-primary relative z-0 rounded-r-xl`}
                      />
                    ) : null}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {hidePagination ? null : (
        <>
          <div className="h-2" />
          {!newTablePagination ? (
            <div className="flex w-full justify-center mt-4">
              <TablePagination
                table={table}
                disableRowsPerPage={disableRowsPerPage}
                tableRef={tableRef}
              />
            </div>
          ) : (
            <div className="flex w-full justify-center mt-2">
              <TablePaginationNew table={table} tableRef={tableRef} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

function Filter({ table }: { table: Table<any> }) {
  const globalFilterValue = table.getState().globalFilter ?? "";
  return (
    <Input
      type="text"
      value={(globalFilterValue ?? "") as string}
      onChange={(e) => table.setGlobalFilter(e.target.value)}
      width="320px"
      placeholder={`Search...`}
    />
  );
}

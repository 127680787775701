import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";
import { toast } from "react-toastify";

export const CreateWebhookSchema = z.object({
  name: z.string({ required_error: "Name is required" }),
  active: z.boolean(),
  schedule: z.object({
    due_times: z.array(
      z.object({
        time: z.string({ required_error: "Time is required" }),
        weekday: z.string({ required_error: "Weekday is required" }),
      })
    ),
  }),
  webhook: z.object({
    webhook_url: z.string(),
    format_version: z.literal("v1"),
    hmac_secret: z.string().nullable(),
    job_template: z.object({
      ushape_border: z.number(),
      include_ad_names: z.boolean(),
      date_range: z.string(), // i.e. today, yesterday, last7days, 7days, etc.
      attribution_method: z.string(),
      attribution_window: z.number(),
      report_type: z.string(),
      utc_offset: z.number(), // offset in hours
      include_influencer_data: z.boolean(),
      include_newvsreturning: z.boolean(),
      use_daily: z.boolean(),
      touchpoints: z.array(z.string()),
      csids: z.array(z.string()),
    }),
  }),
});

export type CreateWebhookInput = z.infer<typeof CreateWebhookSchema>;

const onCreateWebhook = async (input: CreateWebhookInput, token: string) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    CreateWebhookSchema.parse(input);
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_TRACY_BACKEND_BASE_URL}/webhook/create`,
      {
        ...input,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as APIError;
      const firstErr = apiError.error;
      throw new Error(firstErr ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
};

export const useCreateWebhook = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: CreateWebhookInput) =>
      onCreateWebhook(input, data?.token ?? ""),
    onSuccess: async (data, values) => {
      queryClient.invalidateQueries({ queryKey: ["webhooks"] });
    },
    onError: (error: any) => {
      toast.error(error.message, { toastId: "create-webhook-error" });
    },
  });
};

import {
  AVAILABLE_GOOGLE_CHANNEL_OPTIONS,
  AVAILABLE_INFLUENCER_SOURCE_OPTIONS,
  AVAILABLE_MARKETING_CHANNEL_OPTIONS,
  CHANNELS_WITHOUT_BASESCORE_TYPE,
  DEPLOY_ENVIRONMENT,
  STRINGS_TO_REMOVE_FROM_NAMES,
} from "constants/constants";
import dayjs from "dayjs";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import {
  AdInfo,
  AdInfoCollection,
  AdsetInfo,
  AnalyticsData,
  AnalyticsResult,
  AttributionData,
  BaseAnalytics,
  BaseAnalyticsResult,
  BaseScore,
  CampaignInfo,
  CustomerJourneyAttributionData,
  CustomerJourneyBaseAnalytics,
  DailyBaseAnalytics,
  NvrAttributionData,
  NvrBaseAnalytics,
  NvrDailyBaseAnalytics,
  MarketingChannelValuesType,
  PathScore,
  TimeSettingsType,
  InfluencerBaseAnalytics,
  NvrInfluencerAttributionData,
  InfluencerAttributionData,
  BaseScoreWithCreative,
  InfluencerCustomerJourneyAttributionData,
  IHCType,
  InfluencerCustomerJourneyBaseAnalytics,
  AnalyticsRequest,
} from "../../types/backendTypes";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { kpiCalculations, simpleAdsGroupFilter } from "@lib/util-functions";
import { Influencer } from "@lib/api-hooks/useInfluencers";
import { Cooperation } from "@lib/api-hooks/useCooperations";
import { getTimeZones } from "@vvo/tzdb";
import { AdCreativeInfo, AdInsight } from "@lib/api-hooks/useAdInfo";
import { AnalyticsFilterType } from "./requests";
dayjs.extend(utc);
dayjs.extend(timezone);

export type AnalyticsOptions = {
  influencerData?: Influencer[];
  cooperationData?: Cooperation[];
  skipInfluencerMapping?: boolean;
  calculateNewCustomerScores?: boolean;
  isNewUserData?: boolean;
  filter?: AnalyticsFilterType;
  views: AnalyticsRequest["views"];
  cooperationsAsAdsets?: boolean;
  forceCalculateNvr?: boolean;
};

export type TransformToBaseAnalyticsResultProps = {
  data: AnalyticsResult;
  timeSettings: TimeSettingsType;
  options: AnalyticsOptions;
};

/**
 *
 * @param {TransformToBaseAnalyticsResultProps} props
 * @param {AnalyticsResult} props.data  Data from the result provided by Tracify endpoint /analytics/results
 * @param {TimeSettingsType} props.timeSettings the timesettings used for creating the request
 * @return {BaseAnalyticsResult} The transformed analytics result for our request
 */
export const transformToBaseAnalyticsResult = ({
  data,
  timeSettings,
  options,
}: TransformToBaseAnalyticsResultProps): BaseAnalyticsResult => {
  const analytics: AnalyticsData = {} as AnalyticsData;

  // when hive doesn't have any attributions it just return null
  // we have to create an object manually so the transformation still runs
  if (!data?.attributions || data?.attributions[0] === null) {
    data.attributions = [
      options.views.reduce(
        (obj, key) => {
          // @ts-expect-error  we will prefill this later with correct values
          obj[key] = null;
          return obj;
        },
        {} as (typeof data.attributions)[number]
      ),
    ];
  }
  if (
    data?.attributions[0]?.aggregated !== undefined ||
    options.views.includes("aggregated")
  ) {
    if (!data?.attributions[0]?.aggregated) {
      // adding empty object so the transformation still runs and we add ad-connector data
      data.attributions[0].aggregated = {};
    }

    const analyticsData = transformFullyQualifiedAnalyticsResult(data);
    if (analyticsData) {
      analytics.aggregated = analyticsData;
    }
  }
  if (data?.attributions[0]?.daily !== undefined) {
    if (!data?.attributions[0]?.daily) {
      // adding empty object so the transformation still runs and we add ad-connector data
      data.attributions[0].daily = {};
    }

    analytics.daily = transformDailyAnalyticsResult(data, {
      utcOffset: timeSettings.utcOffset,
      startTime: timeSettings.startTime,
      endTime: timeSettings.endTime,
      timezone: timeSettings.timezone,
    });
  }
  if (data?.attributions[0]?.nvr_daily !== undefined) {
    if (!data?.attributions[0]?.nvr_daily) {
      // adding empty object so the transformation still runs and we add ad-connector data
      data.attributions[0].nvr_daily = {};
    }

    analytics.nvr_daily = transformNvrDailyAnalyticsResult(data, {
      utcOffset: timeSettings.utcOffset,
      startTime: timeSettings.startTime,
      endTime: timeSettings.endTime,
      timezone: timeSettings.timezone,
    });
  }
  if (data?.attributions[0]?.nvr_aggregated !== undefined) {
    if (!data?.attributions[0]?.nvr_aggregated) {
      // adding empty object so the transformation still runs and we add ad-connector data
      data.attributions[0].nvr_aggregated = { new: {}, returning: {} };
    }

    analytics.nvr_aggregated = transformNvrAnalyticsResult(data);
  }
  if (data?.attributions[0]?.customer_journey) {
    const analyticsData = transformCustomerJourneyResult(data);
    if (analyticsData) {
      analytics.customer_journey = analyticsData;
    }
  }

  if (data?.attributions[0]?.customer_journey_tc) {
    const analyticsData = transformInfluencerCustomerJourneyResult(
      data,
      {
        utcOffset: timeSettings.utcOffset,
        startTime: timeSettings.startTime,
        endTime: timeSettings.endTime,
        timezone: timeSettings.timezone,
      },
      options
    );
    if (analyticsData) {
      analytics.customer_journey_tc = analyticsData;
    }
  }
  if (data?.attributions[0]?.tc_aggregated !== undefined) {
    if (!data?.attributions[0]?.tc_aggregated) {
      // adding empty object so the transformation still runs and we add ad-connector data
      data.attributions[0].tc_aggregated = { "": { "": {} } };
    }
    const analyticsData = transformInfluencerResult(
      data,
      {
        utcOffset: timeSettings.utcOffset,
        startTime: timeSettings.startTime,
        endTime: timeSettings.endTime,
        timezone: timeSettings.timezone,
      },
      options
    );
    if (analyticsData) {
      analytics.tc_aggregated = analyticsData;
    }
  }

  if (data?.attributions[0]?.tc_daily !== undefined) {
    if (!data?.attributions[0]?.tc_daily) {
      // adding empty object so the transformation still runs and we add ad-connector data
      data.attributions[0].tc_daily = {};
    }

    const analyticsData = transformDailyInfluencerResult(
      data,
      {
        utcOffset: timeSettings.utcOffset,
        startTime: timeSettings.startTime,
        endTime: timeSettings.endTime,
        timezone: timeSettings.timezone,
      },
      options
    );

    if (analyticsData) {
      analytics.tc_daily = analyticsData;
    }
  }

  if (data?.attributions[0]?.nvr_tc_aggregated !== undefined) {
    if (!data?.attributions[0]?.nvr_tc_aggregated) {
      // adding empty object so the transformation still runs and we add ad-connector data
      data.attributions[0].nvr_tc_aggregated = {
        new: { "": { "": {} } },
        returning: { "": { "": {} } },
      };
    }
    const analyticsData = transformNvrInfluencerAnalyticsResult(
      data,
      timeSettings,
      options
    );
    if (analyticsData) {
      analytics.nvr_tc_aggregated = analyticsData;
    }
  }

  if (data?.attributions[0]?.nvr_tc_daily !== undefined) {
    if (!data?.attributions[0]?.nvr_tc_daily) {
      // adding empty object so the transformation still runs and we add ad-connector data
      data.attributions[0].nvr_tc_daily = {};
    }

    const analyticsData = transformNvrDailyInfluencerAnalyticsResult(
      data,
      {
        utcOffset: timeSettings.utcOffset,
        startTime: timeSettings.startTime,
        endTime: timeSettings.endTime,
        timezone: timeSettings.timezone,
      },
      options
    );
    if (analyticsData) {
      analytics.nvr_tc_daily = analyticsData;
    }
  }

  const result = {
    analytics: analytics,
    progress: 100.0,
    finished: true,
  } as BaseAnalyticsResult;

  return result;
};

export type ExtractAdInfoResult = [
  Map<string, AdInfo>,
  Map<string, AdsetInfo>,
  Map<string, CampaignInfo>,
];

export type ExtractAdInfoSelection = {
  ads?: boolean;
  adsets?: boolean;
  campaigns?: boolean;
};

const joinNvrInfluencerAttributionData = (
  attributionData: NvrInfluencerAttributionData
) => {
  const newData = attributionData?.new;
  const returningData = attributionData?.returning;

  // in these cases we don't have to join anything
  if (!newData && !returningData) return {};
  if (!newData) return returningData;
  if (!returningData) return newData;

  // we use JSON functions to make a deep copy of the newData object
  const allData: InfluencerAttributionData = JSON.parse(
    JSON.stringify(newData)
  );
  for (const [clid, clResults] of Object.entries(returningData)) {
    for (const [discountCode, values] of Object.entries(clResults)) {
      for (const [fqid, value] of Object.entries(values)) {
        // if we have data for this exact fqid, we have to add the returning data to it
        if (
          allData[clid] &&
          allData[clid][discountCode] &&
          allData[clid][discountCode][fqid]
        ) {
          for (const [csid, interactionMap] of Object.entries(value)) {
            if (!allData[clid][discountCode][fqid][csid]) {
              allData[clid][discountCode][fqid] = {
                ...allData[clid][discountCode][fqid],
                [csid]: { ...interactionMap },
              };
            } else {
              for (const [interaction, scoreMap] of Object.entries(
                interactionMap
              )) {
                for (const [scoreName, scoreValue] of Object.entries(
                  scoreMap
                )) {
                  if (scoreName === "attribution") {
                    if (interaction === "pageview") {
                      const currentValue =
                        allData[clid][discountCode][fqid][csid].pageview
                          ?.attribution ?? 0;
                      allData[clid][discountCode][fqid][csid].pageview = {
                        attribution: currentValue + scoreValue,
                      };
                    } else if (interaction === "productview") {
                      const currentValue =
                        allData[clid][discountCode][fqid][csid].productview
                          ?.attribution ?? 0;
                      allData[clid][discountCode][fqid][csid].productview = {
                        attribution: currentValue + scoreValue,
                      };
                    } else if (interaction === "purchase") {
                      const currentValue =
                        allData[clid][discountCode][fqid][csid].purchase
                          ?.attribution ?? 0;
                      const nitemsValue =
                        allData[clid][discountCode][fqid][csid].purchase
                          ?.nitems ?? 0;
                      const amountValue =
                        allData[clid][discountCode][fqid][csid].purchase
                          ?.amount ?? 0;

                      allData[clid][discountCode][fqid][csid].purchase = {
                        attribution: currentValue + scoreValue,
                        nitems: nitemsValue,
                        amount: amountValue,
                      };
                    } else if (interaction === "addtocart") {
                      const currentValue =
                        allData[clid][discountCode][fqid][csid].addtocart
                          ?.attribution ?? 0;
                      allData[clid][discountCode][fqid][csid].addtocart = {
                        attribution: currentValue + scoreValue,
                      };
                    }
                  } else if (scoreName === "nitems") {
                    if (interaction === "purchase") {
                      const attributionValue =
                        allData[clid][discountCode][fqid][csid].purchase
                          ?.attribution ?? 0;
                      const nitemsValue =
                        allData[clid][discountCode][fqid][csid].purchase
                          ?.nitems ?? 0;
                      const amountValue =
                        allData[clid][discountCode][fqid][csid].purchase
                          ?.amount ?? 0;

                      allData[clid][discountCode][fqid][csid].purchase = {
                        attribution: attributionValue,
                        nitems: nitemsValue + scoreValue,
                        amount: amountValue,
                      };
                    }
                  } else if (scoreName === "amount") {
                    if (interaction === "purchase") {
                      const attributionValue =
                        allData[clid][discountCode][fqid][csid].purchase
                          ?.attribution ?? 0;
                      const nitemsValue =
                        allData[clid][discountCode][fqid][csid].purchase
                          ?.nitems ?? 0;
                      const amountValue =
                        allData[clid][discountCode][fqid][csid].purchase
                          ?.amount ?? 0;
                      allData[clid][discountCode][fqid][csid].purchase = {
                        attribution: attributionValue,
                        nitems: nitemsValue,
                        amount: amountValue + scoreValue,
                      };
                    }
                  }
                }
              }
            }
          }
        } else if (allData[clid] && allData[clid][discountCode]) {
          allData[clid][discountCode][fqid] = { ...value };
        } else if (allData[clid]) {
          allData[clid][discountCode] = { [fqid]: { ...value } };
        } else {
          allData[clid] = { [discountCode]: { [fqid]: { ...value } } };
        }
      }
    }
  }
  return allData;
};
/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @param {TimeSettingsType} timeSettings the timesettings used for creating the request
 * @param {AnalyticsOptions} options Options for the influencer mapping
 * @return {NvrDailyBaseAnalytics} returns the BaseAnalytics result for every day we got data
 */
export const transformNvrDailyInfluencerAnalyticsResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType,
  options: AnalyticsOptions
) => {
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].nvr_tc_daily
  ) {
    // return empty result
    return {} as NvrDailyBaseAnalytics;
  }

  const rawResult = { ...result } as AnalyticsResult;
  const allAdsetIds = result.adsetids;
  const allCampaignIds = result.campaignids;
  const nvrDailyAnalyticsData: NvrDailyBaseAnalytics = {};
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.nvr_tc_daily) {
      return {} as NvrDailyBaseAnalytics;
    }
    const diff = dayjs(timeSettings.endTime).diff(
      dayjs(timeSettings.startTime),
      "day"
    );
    let addedDatesManually = false;
    for (let i = 0; i <= diff; i++) {
      const day = dayjs(timeSettings.startTime)
        .utcOffset(timeSettings.utcOffset ?? 0)
        .add(i, "day")
        .format("YYYY-MM-DD");
      if (!attribution.nvr_tc_daily[day]) {
        addedDatesManually = true;
        attribution.nvr_tc_daily[day] = {
          new: { "": { "": {} } },
          returning: { "": { "": {} } },
        };
      }
    }
    if (addedDatesManually) {
      // obj keys need to be sorted for the daily analytics (graphs) to work
      const sortedArray = Object.entries(attribution.nvr_tc_daily).sort(
        ([key1], [key2]) => dayjs(key1).unix() - dayjs(key2).unix()
      );
      attribution.nvr_tc_daily = Object.fromEntries(sortedArray);
    }
    for (const [attributionDay, attributionData] of Object.entries(
      attribution.nvr_tc_daily
    )) {
      // here we get the dates back in the format "YYYY-MM-DD" and we want to
      // transform that to the request timezone in UTC by adding the utcOffset
      // to be able to check if the start and end date are the same as in the current local day
      const attributionDate = timeSettings.utcOffset
        ? dayjs(attributionDay).utcOffset(timeSettings.utcOffset, true)
        : dayjs(attributionDay);

      const isInTimeRange =
        attributionDate.isAfter(
          dayjs(timeSettings.startTime).subtract(1, "second"), // subtract 1 second to avoid having to check for same & after
          "second"
        ) &&
        attributionDate.isBefore(
          dayjs(timeSettings.endTime).add(1, "second"), // add 1 second to avoid having to check for same & before
          "second"
        );

      // only if the date is in the selected timerange, we want to add its result to our analytics data
      if (isInTimeRange) {
        const dailyAggregatedAdKpis: {
          [key: string]: { [key: string]: number };
        } = {};

        if (result?.ads && result?.ads[index]?.ad_kpis) {
          // quick fix for summer time changes where ad connector dates are outside of current date
          // to fix this, we add the offset diff to the adkpi date below
          const startOffset = dayjs(timeSettings.startTime)
            .tz(timeSettings.timezone)
            .utcOffset();
          const offsetDiff = startOffset / 60 - (timeSettings.utcOffset ?? 0);

          // for every adId we get an array of ad kpis for every date in the timerange (when available)
          for (const [adId, adKpis] of Object.entries(
            result?.ads[index]?.ad_kpis ?? {}
          )) {
            for (const adKpi of adKpis) {
              // here we get the date in UTC format YYYY-MM-DD HH:mm:ss in the accounts
              // timezone in the respective ads manager (fb, google, tiktok)
              // since we can only get date for the account in this timezone,
              // we can also only check if the end of the date in the accounts timezone
              // is at least the same day and add these kpi to this date then
              const adKpiDate = dayjs(adKpi.date)
                .utc(true)
                .add(offsetDiff, "hours");

              // check if date is between start and end of date because we
              // sometimes also have ads that have a different timestamp
              // (i.e. different timezone in ads account) and might be a different date in utc
              const dateBoolean =
                adKpiDate.isAfter(attributionDate.subtract(1, "second")) &&
                adKpiDate.isBefore(
                  attributionDate.add(1, "day").subtract(1, "millisecond")
                );

              if (dateBoolean) {
                dailyAggregatedAdKpis[adId] = adKpi;
              }
            }
          }
        }

        // here we mimic the result we would get back from Hive for only the current date in
        // the aggregated view, so we can reuse the transform function
        const newDailyDataToAggregateMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ tc_aggregated: attributionData.new }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };
        // here we mimic the result we would get back from Hive for only the current date in
        // the aggregated view, so we can reuse the transform function
        const returningDailyDataToAggregateMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ tc_aggregated: attributionData.returning }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };

        // we want to join both the new and returning data for properly displaying the total amounts
        // without counting the ad-connector data twice
        const allAttributions =
          joinNvrInfluencerAttributionData(attributionData);

        const allDailyDataToAggregateMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ tc_aggregated: allAttributions }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };

        const newDailyData = transformInfluencerResult(
          newDailyDataToAggregateMap,
          {
            ...timeSettings,
            endTime: attributionDate.endOf("day").toISOString(),
            startTime: attributionDate.startOf("day").toISOString(),
          },
          { ...options, calculateNewCustomerScores: true, isNewUserData: true }
        );
        const returningDailyData = transformInfluencerResult(
          returningDailyDataToAggregateMap,
          {
            ...timeSettings,
            endTime: attributionDate.endOf("day").toISOString(),
            startTime: attributionDate.startOf("day").toISOString(),
          },
          { ...options, forceCalculateNvr: true }
        );
        const allDailyData = transformInfluencerResult(
          allDailyDataToAggregateMap,
          {
            ...timeSettings,
            endTime: attributionDate.endOf("day").toISOString(),
            startTime: attributionDate.startOf("day").toISOString(),
          },
          { ...options, calculateNewCustomerScores: true }
        );
        // add the purchaseCount and cac to allData, so we can display that in our tables
        if (newDailyData && allDailyData) {
          addCacDataToNvrAll(newDailyData, allDailyData);
          addAllDataToNvrNew(newDailyData, allDailyData);
          addAllDataToNvrReturning(returningDailyData, allDailyData);
        }

        if (nvrDailyAnalyticsData[attributionDay] && newDailyData) {
          nvrDailyAnalyticsData[attributionDay].new = {
            ...nvrDailyAnalyticsData[attributionDay].new,
            ...newDailyData,
          };
        } else if (newDailyData) {
          nvrDailyAnalyticsData[attributionDay] = { new: newDailyData };
        }

        if (nvrDailyAnalyticsData[attributionDay] && returningDailyData) {
          nvrDailyAnalyticsData[attributionDay].returning = {
            ...nvrDailyAnalyticsData[attributionDay].returning,
            ...returningDailyData,
          };
        } else if (returningDailyData) {
          nvrDailyAnalyticsData[attributionDay] = {
            returning: returningDailyData,
          };
        }
        if (nvrDailyAnalyticsData[attributionDay] && allDailyData) {
          nvrDailyAnalyticsData[attributionDay].all = {
            ...nvrDailyAnalyticsData[attributionDay].all,
            ...allDailyData,
          };
        } else if (allDailyData) {
          nvrDailyAnalyticsData[attributionDay] = {
            all: allDailyData,
          };
        }
      }
    }
    index += 1;
  }
  return nvrDailyAnalyticsData;
};

/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @param {TimeSettingsType} timeSettings the timesettings used for creating the request
 * @param {AnalyticsOptions} options Data from the result provided by Tracify endpoint /analytics/results
 * @return {NvrBaseAnalytics} returns the BaseAnalytics result for every day we got data
 */
export const transformNvrInfluencerAnalyticsResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType,
  options: AnalyticsOptions
) => {
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].nvr_tc_aggregated
  ) {
    // return empty result
    return {} as NvrBaseAnalytics;
  }

  const rawResult = { ...result } as AnalyticsResult;
  const allAdsetIds = result.adsetids;
  const allCampaignIds = result.campaignids;
  const nvrAnalyticsData: NvrBaseAnalytics = {} as NvrBaseAnalytics;
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.nvr_tc_aggregated) {
      return {} as NvrBaseAnalytics;
    }
    // here we mimic the result we would get back from Hive for only the current date in
    // the aggregated view, so we can reuse the transform function
    const newDataToAggregateMap: AnalyticsResult = {
      adsetids: allAdsetIds,
      campaignids: allCampaignIds,
      totals: result.totals,
      attributions: [{ tc_aggregated: attribution.nvr_tc_aggregated.new }],
      ads: result.ads ? [result.ads[index]] : [],
    };
    const returningDataToAggregateMap: AnalyticsResult = {
      adsetids: allAdsetIds,
      campaignids: allCampaignIds,
      totals: result.totals,
      attributions: [
        { tc_aggregated: attribution.nvr_tc_aggregated.returning },
      ],
      ads: result.ads ? [result.ads[index]] : [],
    };
    const allAttributions = joinNvrInfluencerAttributionData(
      attribution.nvr_tc_aggregated
    );
    const allDataToAggregateMap: AnalyticsResult = {
      adsetids: allAdsetIds,
      campaignids: allCampaignIds,
      totals: result.totals,
      attributions: [{ tc_aggregated: allAttributions }],
      ads: result.ads ? [result.ads[index]] : [],
    };
    const newData = transformInfluencerResult(
      newDataToAggregateMap,
      timeSettings,
      { ...options, calculateNewCustomerScores: true, isNewUserData: true }
    );

    const returningData = transformInfluencerResult(
      returningDataToAggregateMap,
      timeSettings,
      { ...options, forceCalculateNvr: true }
    );
    const allData = transformInfluencerResult(
      allDataToAggregateMap,
      timeSettings,
      { ...options, calculateNewCustomerScores: true }
    );

    // add the purchaseCount and cac to allData, so we can display that in our tables
    if (newData && allData) {
      addCacDataToNvrAll(newData, allData);
      addAllDataToNvrNew(newData, allData);
      addAllDataToNvrReturning(returningData, allData);
    }
    if (newData) {
      nvrAnalyticsData.new = newData;
    }
    if (returningData) {
      nvrAnalyticsData.returning = returningData;
    }
    if (allData) {
      nvrAnalyticsData.all = allData;
    }

    index += 1;
  }
  return nvrAnalyticsData;
};

/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @param {TimeSettingsType} timeSettings the timesettings used for creating the request
 * @param {AnalyticsOptions} options Data from the result provided by Tracify endpoint /analytics/results
 * @return {BaseAnalytics} returns the BaseAnalytics result for every day we got data
 */
export const transformInfluencerResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType,
  options: AnalyticsOptions
) => {
  let regularAnalyticsData: BaseAnalytics = {
    ads: new Map(),
    adsets: new Map(),
    campaigns: new Map(),
  } as BaseAnalytics;
  const regularAnalyticsWithDiscount: BaseAnalytics[] = [];
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].tc_aggregated
  ) {
    // return empty result
    return regularAnalyticsData;
  }
  const timezones = getTimeZones();
  const currentTz = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTimezone: string | undefined = timezones.find((el) => {
    return (
      el.name === currentTz &&
      el.currentTimeOffsetInMinutes / 60 === timeSettings.utcOffset
    );
  })?.name;

  const timezoneWithOffset = localTimezone
    ? localTimezone
    : timezones.find((el) => {
        return el.currentTimeOffsetInMinutes / 60 === timeSettings.utcOffset;
      })?.name ?? new Intl.DateTimeFormat().resolvedOptions().timeZone;

  const rawResult = { ...result } as AnalyticsResult;
  const tcAnalyticsData: InfluencerBaseAnalytics =
    new Map() as InfluencerBaseAnalytics;
  // let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.tc_aggregated) {
      return regularAnalyticsData;
    }
    for (const [clid, clResults] of Object.entries(attribution.tc_aggregated)) {
      for (const [discountCode, values] of Object.entries(clResults)) {
        if (clid !== "") {
          // initialize empty linkScore
          let linkScore = initializeBaseScoreValue({
            baseScoreId: clid,
            fqId: clid,
            scope: "influencer_module",
            type: "cooperationLink",
          });

          // add attribution data from Hive to adScore
          for (const [, csidMap] of Object.entries(values)) {
            for (const [, interactionMap] of Object.entries(csidMap)) {
              for (const [interaction, scoreMap] of Object.entries(
                interactionMap as Object
              )) {
                for (const [scoreName, scoreValue] of Object.entries(
                  scoreMap as Object
                )) {
                  if (scoreName === "attribution") {
                    if (interaction === "pageview")
                      linkScore.pageview += scoreValue;
                    else if (interaction === "productview")
                      linkScore.productview += scoreValue;
                    else if (interaction === "checkout")
                      linkScore.checkout += scoreValue;
                    else if (interaction === "purchase")
                      linkScore.purchaseCount += scoreValue;
                    else if (interaction === "addtocart")
                      linkScore.addtocart += scoreValue;
                  } else if (scoreName === "nitems") {
                    if (interaction === "purchase")
                      linkScore.purchaseCartItems += scoreValue;
                  } else if (scoreName === "amount") {
                    if (interaction === "purchase")
                      linkScore.purchaseAmount += scoreValue;
                  }
                }
              }
            }
          }
          linkScore.cr = kpiCalculations.cr(linkScore);
          linkScore.aov = kpiCalculations.aov(linkScore);

          // In case this score already exists because there are attributions
          // using different discount codes, retrieve that data and merge it
          // with the current data.
          const existingScore = tcAnalyticsData?.get(clid);
          let discountCodeScore = structuredClone(linkScore);
          discountCodeScore.refId = `${discountCodeScore.refId}::${discountCode}`;
          discountCodeScore.fullyQualifiedId = `${discountCodeScore.fullyQualifiedId}::${discountCode}`;
          discountCodeScore.name = discountCode;
          discountCodeScore.type = "discountCode";

          let cooperation;

          if (options.influencerData) {
            for (const influencer of options.influencerData) {
              if (cooperation) break;

              for (const coop of influencer.cooperations) {
                if (cooperation) break;
                const isScoreInCoop =
                  coop.links.findIndex((el) => el.id === linkScore.refId) !==
                  -1;
                if (isScoreInCoop) {
                  cooperation = coop;
                }
              }
            }
          } else if (options.cooperationData) {
            for (const coop of options.cooperationData) {
              if (cooperation) break;

              const isScoreInCoop =
                coop.links.findIndex((el) => el.id === linkScore.refId) !== -1;
              if (isScoreInCoop) {
                cooperation = coop;
              }
            }
          }
          if (cooperation) {
            discountCodeScore = mergeCooperationLinkWithAnalytics(
              discountCodeScore,
              cooperation,
              {
                startTime: timeSettings.startTime,
                endTime: timeSettings.endTime,
                timezone: timezoneWithOffset,
              },
              {
                calculateNewCustomerScores: options.calculateNewCustomerScores,
                isNewUserData: options.isNewUserData,
                forceCalculateNvr: options.forceCalculateNvr,
              },
              "discountCode"
            ) as BaseScore;
          }
          if (existingScore) {
            linkScore = joinBaseScoreValuesFromArray(
              [existingScore, linkScore],
              {
                calculateNewCustomerScores: options.calculateNewCustomerScores,
                isNewUserData: options.isNewUserData,
                forceCalculateNvr: options.forceCalculateNvr,
              }
            );
          }
          linkScore.subRows.push(discountCodeScore);
          tcAnalyticsData.set(clid, linkScore);
        } else if (discountCode === "" && !options.filter?.onlyInfluencerData) {
          // only under the "" discount code we got the regular attributions for all the other channels
          const regularAttributions = [{ aggregated: values }];
          const regularResult: AnalyticsResult = {
            ...result,
            attributions: regularAttributions,
          };
          const data = transformFullyQualifiedAnalyticsResult(regularResult, {
            calculateNewCustomerScores: options.calculateNewCustomerScores,
            isNewUserData: options.isNewUserData,
          });
          if (data) regularAnalyticsData = data;
        } else if (!options.filter?.onlyInfluencerData) {
          // these are the old influencer attributions which also include the discount code now
          const regularAttributions = [{ aggregated: values }];
          const regularResult: AnalyticsResult = {
            ...result,
            ads: undefined, //  added here so we don't add unnecessary ad info to the result
            attributions: regularAttributions,
          };
          const transformResult = transformFullyQualifiedAnalyticsResult(
            regularResult,
            {
              calculateNewCustomerScores: options.calculateNewCustomerScores,
              isNewUserData: options.isNewUserData,
            }
          );
          if (transformResult) {
            regularAnalyticsWithDiscount.push(transformResult);
          }
        }
      }
    }
  }
  const adsData = Array.from(tcAnalyticsData.values());

  // as a default we also set the cooperation link data as the campaign data
  // but normally we either want to set the campaign data mapped to the influencers or the cooperations
  // which we do, when we either have the influencer or cooperation data in the request
  let influencerCampaignData = adsData;
  let influencerAdsetData: BaseScore[] = [];
  if (options.influencerData) {
    influencerCampaignData = mergeInfluencerWithAnalytics(
      options.influencerData,
      tcAnalyticsData,
      {
        startTime: timeSettings.startTime,
        endTime: timeSettings.endTime,
        timezone: timezoneWithOffset,
      },
      {
        calculateNewCustomerScores: options.calculateNewCustomerScores,
        isNewUserData: options.isNewUserData,
        cooperationsAsAdsets: Boolean(options.cooperationsAsAdsets),
        forceCalculateNvr: options.forceCalculateNvr,
      }
    )?.filter((el) => checkIfBaseScoreHasData(el)) as BaseScore[];
    // set the cooperation data as adsets
    if (options.cooperationData) {
      influencerAdsetData = mergeCooperationWithAnalytics(
        options.cooperationData,
        tcAnalyticsData,
        {
          startTime: timeSettings.startTime,
          endTime: timeSettings.endTime,
          timezone: timezoneWithOffset,
        },
        {
          calculateNewCustomerScores: options.calculateNewCustomerScores,
          isNewUserData: options.isNewUserData,
          forceCalculateNvr: options.forceCalculateNvr,
        }
      )?.filter((el) => checkIfBaseScoreHasData(el)) as BaseScore[];
    }
  } else if (options.cooperationData) {
    influencerCampaignData = mergeCooperationWithAnalytics(
      options.cooperationData,
      tcAnalyticsData,
      {
        startTime: timeSettings.startTime,
        endTime: timeSettings.endTime,
        timezone: timezoneWithOffset,
      },
      {
        calculateNewCustomerScores: options.calculateNewCustomerScores,
        isNewUserData: options.isNewUserData,
        forceCalculateNvr: options.forceCalculateNvr,
      }
    )?.filter((el) => checkIfBaseScoreHasData(el)) as BaseScore[];
  } else if (adsData.length > 0) {
    // we set the pure cooperation link attributions as the ads data for influencers
    // only when we don't want to match against influencer data
    regularAnalyticsData?.ads.set("influencer_module", adsData);
  }

  if (influencerCampaignData.length > 0) {
    regularAnalyticsData?.campaigns.set(
      "influencer_module",
      influencerCampaignData
    );
    regularAnalyticsData?.ads.set("influencer_module", influencerCampaignData);
  }
  if (influencerAdsetData.length > 0) {
    regularAnalyticsData?.adsets.set("influencer_module", influencerAdsetData);
  }
  regularAnalyticsData = mergeBaseAnalyticsValues(
    [regularAnalyticsData, ...regularAnalyticsWithDiscount],
    options
  );
  return regularAnalyticsData;
};

export const transformDailyInfluencerResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType,
  options: AnalyticsOptions
) => {
  const dailyAnalyticsData: DailyBaseAnalytics = {};
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].tc_daily
  ) {
    // return empty result
    return dailyAnalyticsData;
  }
  const rawResult = { ...result } as AnalyticsResult;
  const allAdsetIds = result.adsetids;
  const allCampaignIds = result.campaignids;
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.tc_daily) {
      return dailyAnalyticsData;
    }
    const diff = dayjs(timeSettings.endTime).diff(
      dayjs(timeSettings.startTime),
      "day"
    );

    let addedDatesManually = false;
    for (let i = 0; i <= diff; i++) {
      const day = dayjs(timeSettings.startTime)
        .utcOffset(timeSettings.utcOffset ?? 0)
        .add(i, "day")
        .format("YYYY-MM-DD");

      if (!attribution.tc_daily[day]) {
        attribution.tc_daily[day] = { "": { "": {} } };
        addedDatesManually = true;
      }
    }
    if (addedDatesManually) {
      // obj keys need to be sorted for the daily analytics (graphs) to work
      const sortedArray = Object.entries(attribution.tc_daily).sort(
        ([key1], [key2]) => dayjs(key1).unix() - dayjs(key2).unix()
      );
      attribution.tc_daily = Object.fromEntries(sortedArray);
    }
    for (const [attributionDay, attributionData] of Object.entries(
      attribution.tc_daily
    )) {
      // here we get the dates back in the format "YYYY-MM-DD" and we want to
      // transform that to the request timezone in UTC by adding the utcOffset
      // to be able to check if the start and end date are the same as in the current local day
      const attributionDate = timeSettings.utcOffset
        ? dayjs(attributionDay).utcOffset(timeSettings.utcOffset, true)
        : dayjs(attributionDay);

      const isInTimeRange =
        attributionDate.isAfter(
          dayjs(timeSettings.startTime).subtract(1, "second"), // subtract 1 second to avoid having to check for same & after
          "second"
        ) &&
        attributionDate.isBefore(
          dayjs(timeSettings.endTime).add(1, "second"), // add 1 second to avoid having to check for same & before
          "second"
        );

      // only if the date is in the selected timerange, we want to add its result to our analytics data
      if (isInTimeRange) {
        const dailyAggregatedAdKpis: {
          [key: string]: { [key: string]: number };
        } = {};
        if (result?.ads && result?.ads[index]?.ad_kpis) {
          // quick fix for summer time changes where ad connector dates are outside of current date
          // to fix this, we add the offset diff to the adkpi date below
          const startOffset = dayjs(timeSettings.startTime)
            .tz(timeSettings.timezone)
            .utcOffset();
          const offsetDiff = startOffset / 60 - (timeSettings.utcOffset ?? 0);

          // offset diff can be negative, so we only want to apply it if it's positive to avoid timezone inconsistencies
          const offsetToApply = offsetDiff > 0 ? offsetDiff : 0;
          // for every adId we get an array of ad kpis for every date in the timerange (when available)
          for (const [adId, adKpis] of Object.entries(
            result?.ads[index]?.ad_kpis ?? {}
          )) {
            for (const adKpi of adKpis) {
              // here we get the date in UTC format YYYY-MM-DD HH:mm:ss in the accounts
              // timezone in the respective ads manager (fb, google, tiktok)
              // since we can only get date for the account in this timezone,
              // we can also only check if the end of the date in the accounts timezone
              // is at least the same day and add these kpi to this date then

              const adKpiDate = dayjs(adKpi.date)
                .utc(true)
                .add(offsetToApply, "hours");
              // check if date is between start and end of date because we
              // sometimes also have ads that have a different timestamp
              // (i.e. different timezone in ads account) and might be a different date in utc
              const dateBoolean =
                adKpiDate.isAfter(attributionDate.subtract(1, "second")) &&
                adKpiDate.isBefore(
                  attributionDate.add(1, "day").subtract(1, "millisecond")
                );

              if (dateBoolean) {
                dailyAggregatedAdKpis[adId] = adKpi;
              }
            }
          }
        }
        // here we mimic the result we would get back from Hive for only the current date in
        // the aggregated view, so we can reuse the transform function
        const dailyDataToAggregatedMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ tc_aggregated: attributionData }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };
        const dailyData = transformInfluencerResult(
          dailyDataToAggregatedMap,
          {
            ...timeSettings,
            endTime: attributionDate.endOf("day").toISOString(),
            startTime: attributionDate.startOf("day").toISOString(),
          },
          options
        );
        if (dailyAnalyticsData[attributionDay] && dailyData) {
          dailyAnalyticsData[attributionDay] = {
            ...dailyAnalyticsData[attributionDay],
            ...dailyData,
          };
        } else if (dailyData) {
          dailyAnalyticsData[attributionDay] = dailyData;
        }
      }
    }
    index++;
  }
  return dailyAnalyticsData;
};
export const mergeBaseAnalyticsValues = (
  analytics: BaseAnalytics[],
  options: FQTransformOptions
) => {
  // ideally we want to have the biggest BaseAnalytics object as the first value so
  // we can set the first value as default to avoid having to loop over the biggest obj
  const joinedAnalytics = {
    ads: analytics[0].ads ?? new Map(),
    adsets: analytics[0].adsets ?? new Map(),
    campaigns: analytics[0].campaigns ?? new Map(),
  } as BaseAnalytics;

  for (const analyticsValue of analytics.slice(1)) {
    const adScores = mergeBaseScoreMap(
      analyticsValue.ads,
      joinedAnalytics.ads,
      options
    );
    const adsetScores = mergeBaseScoreMap(
      analyticsValue.adsets,
      joinedAnalytics.adsets,
      options
    );
    const campaignScores = mergeBaseScoreMap(
      analyticsValue.campaigns,
      joinedAnalytics.campaigns,
      options
    );

    joinedAnalytics.ads = adScores;
    joinedAnalytics.adsets = adsetScores;
    joinedAnalytics.campaigns = campaignScores;
  }
  return joinedAnalytics;
};
export const mergeBaseScoreMap = (
  newScores: Map<string, BaseScore[]>,
  existingScores: Map<string, BaseScore[]>,
  options: FQTransformOptions
) => {
  for (const [scope, baseScores] of newScores) {
    const scopedScores = existingScores?.get(scope);
    if (!scopedScores) {
      existingScores.set(scope, baseScores);
    } else {
      for (const baseScore of baseScores) {
        const existingScoreIdx = scopedScores.findIndex(
          (el) => el.fullyQualifiedId === baseScore.fullyQualifiedId
        );
        if (existingScoreIdx !== -1) {
          const existingScore = scopedScores.at(existingScoreIdx) as BaseScore;
          // In case this score already exists because there are attributions
          // using different discount codes, retrieve that data and merge it
          // with the current data.
          const mergedScore = joinBaseScoreValuesFromArray(
            [existingScore, baseScore],
            {
              calculateNewCustomerScores: options.calculateNewCustomerScores,
              isNewUserData: options.isNewUserData,
              forceCalculateNvr: options.forceCalculateNvr,
            }
          );
          scopedScores[existingScoreIdx] = mergedScore;
        } else {
          scopedScores.push(baseScore);
        }
      }
      existingScores.set(scope, scopedScores);
    }
  }
  return existingScores;
};

export type JoinBaseScoreSettingsType = {
  scope?: (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"];
};

export const joinBaseScoreValuesFromArray = (
  scores: BaseScore[],
  options: FQTransformOptions,
  joinSettings?: JoinBaseScoreSettingsType
) => {
  // these values should be skipped in the initial addition and are recalculated later
  const skipValues = [
    "cpc",
    "cpm",
    "ctr",
    "thumbstopRatio",
    "videoAvgTimeWatchedActions",
    "frequency",
    "aov",
    "cr",
    "cpo",
    "cac",
    "roas",
    "newCustomerRate",
    "newVisitorRate",
  ];

  // we don't really care about these values here,
  // we only want to have a 0 for all available values inside the baseScore
  const emptyBaseScore = initializeBaseScoreValue({
    baseScoreId: scores[0]?.refId,
    scope: joinSettings?.scope ?? "influencer",
    baseScoreInfo: {
      name: scores[0]?.name,
    } as AdInfo,
    type: scores[0]?.type ?? "cooperationLink",
    fqId: scores[0]?.fullyQualifiedId,
  });
  const score: BaseScore = scores.reduce((prev, curr) => {
    let newScore: BaseScore = {
      ...prev,
      subRows: [...prev.subRows, ...curr.subRows],
    };
    for (const [untypedKey, value] of Object.entries(curr)) {
      const key = untypedKey as keyof BaseScore;
      if (typeof value === "number" && !skipValues.includes(key)) {
        const prevScoreValue = (newScore[key] as number) ?? 0;
        newScore = { ...newScore, [key]: prevScoreValue + value };
      }
    }
    return newScore;
  }, emptyBaseScore);
  score.videoAvgTimeWatchedActions =
    kpiCalculations.videoAvgTimeWatchedActions(scores); // this has to be calculated from scores because it is a weighted average
  score.cpc = kpiCalculations.cpc(score);
  score.cpm = kpiCalculations.cpm(score);
  score.ctr = kpiCalculations.ctr(score);
  score.thumbstopRatio = kpiCalculations.thumbstopRatio(score);
  score.frequency = kpiCalculations.frequency(score);
  score.aov = kpiCalculations.aov(score);
  score.cr = kpiCalculations.cr(score);
  score.cpo = kpiCalculations.cpo(score);
  score.roas = kpiCalculations.roas(score);
  if (options.calculateNewCustomerScores) {
    score.cac = kpiCalculations.cac(score, options.isNewUserData);
    score.newCustomerRate = kpiCalculations.newCustomerRate(
      score,
      options.isNewUserData
    );
    score.newVisitorRate = kpiCalculations.newVisitorRate(
      score,
      options.isNewUserData
    );
  }
  const dcOrders = score.subRows?.reduce((prev, curr) => {
    if (curr?.name !== "") return prev + (curr.purchaseCount ?? 0);
    return prev;
  }, 0);
  score.discountMatchingRate =
    score.purchaseCount > 0 && dcOrders > 0
      ? dcOrders / score.purchaseCount
      : 0;
  return score;
};
export const mergeAdWithAdConnectorData = ({
  ad,
  info,
  insights,
}: {
  ad: BaseScore;
  info?: AdCreativeInfo;
  insights?: AdInsight;
}) => {
  return {
    ...ad,
    comment: insights?.comment,
    onsiteConversionPostSave: insights?.onsiteConversionPostSave,
    post: insights?.post,
    videoAvgTimeWatchedActions: insights?.videoAvgTimeWatchedActions,
    videoView: insights?.videoView,
    creative: info?.creative,
    thumbstopRatio:
      ad.impressions && insights?.videoView
        ? insights.videoView / ad.impressions
        : 0,
    creativeFormat: info?.creative?.video
      ? "video"
      : info?.creative?.image
        ? "image"
        : undefined,
  } as BaseScoreWithCreative;
};

export const mergeInfluencerWithAnalytics = (
  influencers: Influencer[],
  analytics: InfluencerBaseAnalytics,
  timerange: { startTime: string; endTime: string; timezone: string },
  options: FQTransformOptions
) => {
  if (influencers) {
    return influencers
      ?.map((influencer: Influencer) => {
        // we loop over the cooperations here because they include the
        // respective commission/variable commission per link that is need to
        // calculate the spend and all metrics related to it
        const cooperationsScore = mergeCooperationWithAnalytics(
          influencer.cooperations,
          analytics,
          timerange,
          options
        )?.filter((el) => Boolean(el)) as BaseScore[];
        const cooperationsScoreWithSpend = joinBaseScoreValuesFromArray(
          cooperationsScore,
          options
        );
        const obj = {
          ...cooperationsScoreWithSpend,
          ...influencer,
          refId: `influencer::${influencer.id}`,
          fullyQualifiedId: `influencer::${influencer.id}`,
        };
        if (options.cooperationsAsAdsets) {
          obj.subRows = cooperationsScore;
        }
        return obj;
      })
      ?.filter((el) => {
        return !!el;
      }) as Array<BaseScore & Influencer>;
  }
};

export const mergeCooperationWithAnalytics = (
  cooperations: Cooperation[],
  analytics: InfluencerBaseAnalytics,
  timerange: { startTime: string; endTime: string; timezone: string },
  options: FQTransformOptions
) => {
  if (cooperations) {
    return cooperations
      ?.map((cooperation: Cooperation, index: number) => {
        if (!cooperation) {
          return null;
        }
        const cooperationScoreArray = cooperation.links
          .map((link) => {
            const linkScore = analytics?.get(link.id);
            return linkScore;
          })
          .filter((el) => !!el) as BaseScore[];
        const cooperationScoreSum = joinBaseScoreValuesFromArray(
          cooperationScoreArray,
          options
        );
        let totalVariableCommission = 0;
        if (
          options?.forceCalculateNvr ||
          (options?.isNewUserData && options?.calculateNewCustomerScores)
        ) {
          if (
            cooperation.variableCommissionType === "percentage" &&
            cooperation.variableCommission &&
            cooperationScoreSum.nvr_purchaseAmount &&
            cooperationScoreSum.nvr_purchaseAmount > 0
          ) {
            totalVariableCommission =
              (parseFloat(cooperation.variableCommission) / 100) *
              cooperationScoreSum.nvr_purchaseAmount;
          } else if (
            cooperation.variableCommissionType === "total" &&
            cooperation.variableCommission &&
            cooperationScoreSum.nvr_purchaseCount &&
            cooperationScoreSum.nvr_purchaseCount > 0
          ) {
            totalVariableCommission =
              parseFloat(cooperation.variableCommission) *
              cooperationScoreSum.nvr_purchaseCount;
          }
        } else {
          if (
            cooperation.variableCommissionType === "percentage" &&
            cooperation.variableCommission &&
            cooperationScoreSum.purchaseAmount > 0
          ) {
            totalVariableCommission =
              (parseFloat(cooperation.variableCommission) / 100) *
              cooperationScoreSum.purchaseAmount;
          } else if (
            cooperation.variableCommissionType === "total" &&
            cooperation.variableCommission &&
            cooperationScoreSum.purchaseCount > 0
          ) {
            totalVariableCommission =
              parseFloat(cooperation.variableCommission) *
              cooperationScoreSum.purchaseCount;
          }
        }

        // we only want to attribute the fixed spent of a cooperation, when the scheduled cooperation date
        // lies in between the selected timerange
        const start = dayjs(dayjs(timerange.startTime).startOf("day"))
          .tz(timerange.timezone, true)
          .subtract(1, "second");
        const end = dayjs(dayjs(timerange.endTime).endOf("day")).tz(
          timerange.timezone,
          true
        );
        const scheduledFor = dayjs(cooperation.scheduledFor);
        let totalCommission = totalVariableCommission;
        let fixedCommission = 0;
        if (scheduledFor.isAfter(start) && scheduledFor.isBefore(end)) {
          totalCommission =
            totalVariableCommission + (parseFloat(cooperation.commission) ?? 0);
          fixedCommission = parseFloat(cooperation.commission) ?? 0;
        }
        const scoreWithSpend = {
          ...cooperationScoreSum,
          fixedSpent: fixedCommission ?? 0,
          variableSpent: totalVariableCommission ?? 0,
          spend: totalCommission,
        };
        // Force kpiCalculations to re-compute the previously
        // set roas and cpo field, now the spend has been modified.
        delete scoreWithSpend["roas"];
        delete scoreWithSpend["cpo"];
        delete scoreWithSpend["cac"];
        delete scoreWithSpend["newCustomerRate"];
        delete scoreWithSpend["newVisitorRate"];
        scoreWithSpend.roas = kpiCalculations.roas(scoreWithSpend);
        scoreWithSpend.cpo = kpiCalculations.cpo(scoreWithSpend);
        if (options.calculateNewCustomerScores) {
          scoreWithSpend.cac = kpiCalculations.cac(
            scoreWithSpend,
            options.isNewUserData
          );
          scoreWithSpend.newCustomerRate = kpiCalculations.newCustomerRate(
            scoreWithSpend,
            options.isNewUserData
          );
          scoreWithSpend.newVisitorRate = kpiCalculations.newVisitorRate(
            scoreWithSpend,
            options.isNewUserData
          );
        }
        return {
          ...scoreWithSpend,
          ...cooperation,
        };
      })
      ?.filter((el) => {
        return !!el;
      }) as Array<BaseScore & Cooperation>;
  }
};

export const mergeCooperationLinkWithAnalytics = (
  linkScore: BaseScore,
  cooperation: Cooperation,
  timerange: { startTime: string; endTime: string; timezone: string },
  options: FQTransformOptions,
  type: "discountCode" | "link"
) => {
  const linkInCooperation = cooperation?.links?.find(
    (el) => el.id === linkScore?.refId?.split("::")[0]
  );
  if (cooperation && linkInCooperation) {
    let totalVariableCommission = 0;
    if (
      options?.forceCalculateNvr ||
      (options?.isNewUserData && options?.calculateNewCustomerScores)
    ) {
      if (
        cooperation.variableCommissionType === "percentage" &&
        cooperation.variableCommission &&
        linkScore.nvr_purchaseAmount &&
        linkScore.nvr_purchaseAmount > 0
      ) {
        totalVariableCommission =
          (parseFloat(cooperation.variableCommission) / 100) *
          linkScore.nvr_purchaseAmount;
      } else if (
        cooperation.variableCommissionType === "total" &&
        cooperation.variableCommission &&
        linkScore.nvr_purchaseCount &&
        linkScore.nvr_purchaseCount > 0
      ) {
        totalVariableCommission =
          parseFloat(cooperation.variableCommission) *
          linkScore.nvr_purchaseCount;
      }
    } else {
      if (
        cooperation.variableCommissionType === "percentage" &&
        cooperation.variableCommission &&
        linkScore.purchaseAmount > 0
      ) {
        totalVariableCommission =
          (parseFloat(cooperation.variableCommission) / 100) *
          linkScore.purchaseAmount;
      } else if (
        cooperation.variableCommissionType === "total" &&
        cooperation.variableCommission &&
        linkScore.purchaseCount > 0
      ) {
        totalVariableCommission =
          parseFloat(cooperation.variableCommission) * linkScore.purchaseCount;
      }
    }
    // we only want to attribute the fixed spent of a cooperation, when the scheduled cooperation date
    // lies in between the selected timerange
    const start = dayjs(dayjs(timerange.startTime).startOf("day"))
      .tz(timerange.timezone, true)
      .subtract(1, "second");
    const end = dayjs(dayjs(timerange.endTime).endOf("day")).tz(
      timerange.timezone,
      true
    );
    const scheduledFor = dayjs(cooperation.scheduledFor);
    let totalCommission = totalVariableCommission;
    const count =
      type === "discountCode"
        ? cooperation.discountCodes.length
        : cooperation.links.length;
    let fixedCommission = 0;
    if (
      scheduledFor.isAfter(start) &&
      scheduledFor.isBefore(end) &&
      count > 0
    ) {
      totalCommission =
        totalVariableCommission +
        (parseFloat(cooperation.commission) ?? 0) / count;
      fixedCommission = (parseFloat(cooperation.commission) ?? 0) / count;
    }

    // cooperation links only get the variableCommission as the spend, because the fixed comission are
    // only applicable to a cooperation
    const scoreWithSpend = {
      ...linkScore,
      fixedSpent: fixedCommission ?? 0,
      variableSpent: totalVariableCommission ?? 0,
      spend: totalCommission,
    };
    // Force kpiCalculations to re-compute the previously
    // set roas and cpo field, now the spend has been modified.
    delete scoreWithSpend["roas"];
    delete scoreWithSpend["cpo"];
    delete scoreWithSpend["cac"];
    delete scoreWithSpend["newCustomerRate"];
    delete scoreWithSpend["newVisitorRate"];
    scoreWithSpend.roas = kpiCalculations.roas(scoreWithSpend);
    scoreWithSpend.cpo = kpiCalculations.cpo(scoreWithSpend);
    if (options.calculateNewCustomerScores) {
      scoreWithSpend.cac = kpiCalculations.cac(
        scoreWithSpend,
        options.isNewUserData
      );
      scoreWithSpend.newCustomerRate = kpiCalculations.newCustomerRate(
        scoreWithSpend,
        options.isNewUserData
      );
      scoreWithSpend.newVisitorRate = kpiCalculations.newVisitorRate(
        scoreWithSpend,
        options.isNewUserData
      );
    }
    return {
      ...scoreWithSpend,
      ...linkInCooperation,
    };
  }
};

const calculatePathSpend = (
  path: CustomerJourneyAttributionData["conversion_paths"][string],
  ads: AdInfoCollection,
  cjAds: CustomerJourneyAttributionData["ad_ids"]
) => {
  let pathSpend = 0;

  for (const [adId, journeyCount] of Object.entries(path.ad_ids)) {
    // calculate percentage of the spend based on journey count
    const adKpi =
      ads && ads.aggregated_ad_kpis ? ads.aggregated_ad_kpis[adId] : null;
    const totalJourneysForAd = cjAds[adId];
    const pathPercentage =
      totalJourneysForAd && totalJourneysForAd > 0 && journeyCount
        ? journeyCount / totalJourneysForAd
        : 0;
    pathSpend += (adKpi?.spend ?? 0) * pathPercentage;
  }
  return pathSpend;
};

const calculateInfluencerPathSpend = (
  path: InfluencerCustomerJourneyAttributionData["conversion_paths"][string],
  ads: AdInfoCollection,
  cjAds: InfluencerCustomerJourneyAttributionData["ad_ids"],
  linkScores: Map<string, BaseScore>
) => {
  let pathSpend = 0;
  for (const [clid, clResults] of Object.entries(path.ad_ids)) {
    for (const [discountCode, values] of Object.entries(clResults)) {
      for (const [adId, journeyCount] of Object.entries(values)) {
        if (clid === "" && discountCode === "") {
          // calculate percentage of the spend based on journey count
          const adKpi =
            ads && ads.aggregated_ad_kpis ? ads.aggregated_ad_kpis[adId] : null;
          const totalJourneysForAd = cjAds[clid]
            ? cjAds[clid][discountCode]
              ? cjAds[clid][discountCode][adId]
              : null
            : null;
          const pathPercentage =
            totalJourneysForAd && totalJourneysForAd > 0 && journeyCount
              ? journeyCount / totalJourneysForAd
              : 0;
          pathSpend += (adKpi?.spend ?? 0) * pathPercentage;
        } else if (clid !== "") {
          const linkScore = linkScores?.get(clid);
          const totalJourneysForAd = cjAds[clid]
            ? cjAds[clid][discountCode]
              ? cjAds[clid][discountCode][adId]
              : null
            : null;
          const pathPercentage =
            totalJourneysForAd && totalJourneysForAd > 0 && journeyCount
              ? journeyCount / totalJourneysForAd
              : 0;
          pathSpend += (linkScore?.spend ?? 0) * pathPercentage;
        }
      }
    }
  }
  return pathSpend;
};

type InitializePathScoreProps =
  | {
      type: "default";
      path: CustomerJourneyAttributionData["conversion_paths"][string];
      ads: AdInfoCollection;
      cjAds: CustomerJourneyAttributionData["ad_ids"];
      linkScores?: unknown;
    }
  | {
      path: InfluencerCustomerJourneyAttributionData["conversion_paths"][string];
      ads: AdInfoCollection;
      cjAds: InfluencerCustomerJourneyAttributionData["ad_ids"];
      type: "tc";
      linkScores: Map<string, BaseScore>;
    };

export const initializePathScore = ({
  path,
  ads,
  cjAds,
  type,
  linkScores,
}: InitializePathScoreProps) => {
  const pathSpend =
    type === "default"
      ? calculatePathSpend(path, ads, cjAds)
      : calculateInfluencerPathSpend(path, ads, cjAds, linkScores);

  return {
    spend: pathSpend,
    newCustomers: path.all.new?.customers ?? 0,
    cac:
      path.all.new?.customers && path.all.new?.customers > 0
        ? pathSpend / path.all.new?.customers
        : 0,
    new: {
      purchaseCount: path.purchase.new?.count ?? 0,
      purchaseAmount: path.purchase.new?.amount ?? 0,
      aov: (path.purchase.new?.amount ?? 0) / (path.purchase.new?.count ?? 0),
      journeys: path.all.new?.journeys ?? 0,
      cr: (path.all.new?.purchases ?? 0) / (path.all.new?.journeys ?? 0),
      customers: path.all.new?.customers ?? 0,
      customerPercentage: 0,
    },
    returning: {
      purchaseCount: path.purchase.returning?.count ?? 0,
      purchaseAmount: path.purchase.returning?.amount ?? 0,
      aov:
        (path.purchase.returning?.amount ?? 0) /
        (path.purchase.returning?.count ?? 0),
      journeys: path.all.returning?.journeys ?? 0,
      cr:
        (path.all.returning?.purchases ?? 0) /
        (path.all.returning?.journeys ?? 0),
      customers: path.all.returning?.customers ?? 0,
      customerPercentage: 0,
    },
    purchaseCount:
      (path.purchase.returning?.count ?? 0) + (path.purchase.new?.count ?? 0),
    purchaseAmount:
      (path.purchase.returning?.amount ?? 0) + (path.purchase.new?.amount ?? 0),
    aov:
      ((path.purchase.returning?.amount ?? 0) +
        (path.purchase.new?.amount ?? 0)) /
      ((path.purchase.returning?.count ?? 0) + (path.purchase.new?.count ?? 0)),

    journeys:
      (path.all.returning?.journeys ?? 0) + (path.all.new?.journeys ?? 0),
    customers:
      (path.all.returning?.customers ?? 0) + (path.all.new?.customers ?? 0),
    cr:
      ((path.all.new?.purchases ?? 0) + (path.all.returning?.purchases ?? 0)) /
      ((path.all.new?.journeys ?? 0) + (path.all.returning?.journeys ?? 0)),
    avgConversionTime: Object.values(path.deltas ?? {}).reduce((prev, curr) => {
      if (!curr.avg || Number.isNaN(curr.avg)) return prev;
      return prev + curr.avg;
    }, 0),
    customerPercentage: 0,
    ncr:
      (path.all.new?.customers ?? 0) + (path.all.returning?.customers ?? 0) > 0
        ? (path.all.new?.customers ?? 0) /
          ((path.all.new?.customers ?? 0) +
            (path.all.returning?.customers ?? 0))
        : 0,
    firstTouchpoint: "",
    lastTouchpoint: "",
  } as PathScore;
};

/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @return {CustomerJourneyBaseAnalytics} returns the BaseAnalytics result for every day we got data
 */
export const transformCustomerJourneyResult = (result: AnalyticsResult) => {
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].customer_journey
  ) {
    // return empty result
    return {} as CustomerJourneyBaseAnalytics;
  }

  const rawResult = { ...result } as AnalyticsResult;
  // const allAdsetIds = result.adsetids;
  // const allCampaignIds = result.campaignids;
  const cjAnalyticsData: CustomerJourneyBaseAnalytics =
    {} as CustomerJourneyBaseAnalytics;
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.customer_journey) {
      return {} as CustomerJourneyBaseAnalytics;
    }
    const ads = rawResult.ads ? rawResult.ads[index] : {};
    const cjData = attribution.customer_journey;
    cjAnalyticsData.touchpoints = cjData.n_tps;
    cjAnalyticsData.ad_ids = cjData.ad_ids;
    cjAnalyticsData.daysToConvert = cjData.days_to_convert;
    cjAnalyticsData.daysToPurchase = cjData.days_to_purchase;
    cjAnalyticsData.ihc = cjData.ihc;
    const conversionPaths: CustomerJourneyBaseAnalytics["conversionPaths"] = {};
    let totalCustomers = 0;
    let maxSteps = 0;
    for (const [path, value] of Object.entries(cjData.conversion_paths)) {
      const newPathScore = initializePathScore({
        path: value,
        ads: ads,
        cjAds: cjData.ad_ids,
        type: "default",
      });
      const splitPath = path.split("->");
      const steps = splitPath.length;
      conversionPaths[path] = {
        ...newPathScore,
        ad_ids: value.ad_ids,
        deltas: value.deltas,
        steps,
        path,
        firstTouchpoint: splitPath[0],
        lastTouchpoint: splitPath[splitPath.length - 1],
      };
      if (steps > maxSteps) maxSteps = steps;
      totalCustomers += newPathScore.customers ?? 0;
    }
    for (const value of Object.values(conversionPaths)) {
      value.customerPercentage = value.customers / totalCustomers;
      value.new.customerPercentage = value.new.customers / totalCustomers;
      value.returning.customerPercentage =
        value.returning.customers / totalCustomers;
    }

    const channelSpend: Partial<{
      // eslint-disable-next-line no-unused-vars
      [scope in MarketingChannelValuesType]: number;
    }> = {};

    for (const [fqid, value] of Object.entries(ads?.aggregated_ad_kpis ?? {})) {
      const scope = fqid.split("::")[0] as MarketingChannelValuesType;
      channelSpend[scope] = (channelSpend[scope] ?? 0) + (value.spend ?? 0);
    }

    cjAnalyticsData.channelSpend = channelSpend;
    cjAnalyticsData.maxSteps = maxSteps;
    cjAnalyticsData.conversionPaths = conversionPaths;
    index++;
  }
  return cjAnalyticsData;
};

/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @return {CustomerJourneyBaseAnalytics} returns the BaseAnalytics result for every day we got data
 * @param {TimeSettingsType} timeSettings the timesettings used for creating the request
 * @param {AnalyticsOptions} options Data from the result provided by Tracify endpoint /analytics/results
 */
export const transformInfluencerCustomerJourneyResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType,
  options: AnalyticsOptions
) => {
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].customer_journey_tc
  ) {
    // return empty result
    return {} as InfluencerCustomerJourneyBaseAnalytics;
  }
  const timezones = getTimeZones();
  const currentTz = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTimezone: string | undefined = timezones.find((el) => {
    return (
      el.name === currentTz &&
      el.currentTimeOffsetInMinutes / 60 === timeSettings.utcOffset
    );
  })?.name;

  const timezoneWithOffset = localTimezone
    ? localTimezone
    : timezones.find((el) => {
        return el.currentTimeOffsetInMinutes / 60 === timeSettings.utcOffset;
      })?.name ?? new Intl.DateTimeFormat().resolvedOptions().timeZone;

  const rawResult = { ...result } as AnalyticsResult;
  // const allAdsetIds = result.adsetids;
  // const allCampaignIds = result.campaignids;
  const cjAnalyticsData: InfluencerCustomerJourneyBaseAnalytics =
    {} as InfluencerCustomerJourneyBaseAnalytics;
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.customer_journey_tc) {
      return {} as InfluencerCustomerJourneyBaseAnalytics;
    }
    const ads = rawResult.ads ? rawResult.ads[index] : {};
    const cjData = attribution.customer_journey_tc;
    cjAnalyticsData.touchpoints = cjData.n_tps;
    cjAnalyticsData.ad_ids = cjData.ad_ids;
    cjAnalyticsData.daysToConvert = cjData.days_to_convert;
    cjAnalyticsData.daysToPurchase = cjData.days_to_purchase;
    cjAnalyticsData.ihc = {};
    const conversionPaths: InfluencerCustomerJourneyBaseAnalytics["conversionPaths"] =
      {};
    let totalCustomers = 0;
    let maxSteps = 0;
    const tcAnalyticsData: InfluencerBaseAnalytics =
      new Map() as InfluencerBaseAnalytics;

    const channelSpend: Partial<{
      // eslint-disable-next-line no-unused-vars
      [scope in MarketingChannelValuesType]: number;
    }> = {};

    for (const [fqid, value] of Object.entries(ads?.aggregated_ad_kpis ?? {})) {
      const scope = fqid.split("::")[0] as MarketingChannelValuesType;
      channelSpend[scope] = (channelSpend[scope] ?? 0) + (value.spend ?? 0);
    }
    const influencerIhc = {
      closer: 0,
      holder: 0,
      initiator: 0,
      new: { closer: 0, holder: 0, initiator: 0 },
      returning: { closer: 0, initiator: 0, holder: 0 },
    };

    for (const [clid, clResults] of Object.entries(cjData.ihc)) {
      for (const [discountCode, ihc] of Object.entries(clResults)) {
        if (clid !== "" && !["closer", "holder", "initiator"].includes(clid)) {
          let linkScore = initializeBaseScoreValue({
            baseScoreId: clid,
            fqId: clid,
            scope: "influencer_module",
            type: "cooperationLink",
          });
          linkScore.purchaseCount =
            (ihc?.new?.closer?.influencer ?? 0) +
            (ihc?.returning?.closer?.influencer ?? 0);
          let cooperation;
          if (options.cooperationData) {
            for (const coop of options.cooperationData) {
              if (cooperation) break;

              const isScoreInCoop =
                coop.links.findIndex((el) => el.id === linkScore.refId) !== -1;
              if (isScoreInCoop) {
                cooperation = coop;
              }
            }
          }
          if (cooperation) {
            linkScore = mergeCooperationLinkWithAnalytics(
              linkScore,
              cooperation,
              {
                startTime: timeSettings.startTime,
                endTime: timeSettings.endTime,
                timezone: timezoneWithOffset,
              },
              {
                calculateNewCustomerScores: options.calculateNewCustomerScores,
                isNewUserData: options.isNewUserData,
                forceCalculateNvr: options.forceCalculateNvr,
              },
              "discountCode"
            ) as BaseScore;
          }
          const existingScore = tcAnalyticsData?.get(clid);
          if (existingScore) {
            linkScore = joinBaseScoreValuesFromArray(
              [existingScore, linkScore],
              {
                calculateNewCustomerScores: options.calculateNewCustomerScores,
                isNewUserData: options.isNewUserData,
                forceCalculateNvr: options.forceCalculateNvr,
              }
            );
          }
          tcAnalyticsData.set(clid, linkScore);

          influencerIhc.closer +=
            (ihc?.new?.closer?.influencer ?? 0) +
            (ihc?.returning?.closer?.influencer ?? 0);

          influencerIhc.new.closer += ihc?.new?.closer?.influencer ?? 0;
          influencerIhc.returning.closer +=
            ihc?.returning?.closer?.influencer ?? 0;

          influencerIhc.initiator +=
            (ihc?.new?.initiator?.influencer ?? 0) +
            (ihc?.returning?.initiator?.influencer ?? 0);

          influencerIhc.new.initiator += ihc?.new?.initiator?.influencer ?? 0;
          influencerIhc.returning.initiator +=
            ihc?.returning?.initiator?.influencer ?? 0;

          influencerIhc.holder +=
            (ihc?.new?.holder?.influencer ?? 0) +
            (ihc?.returning?.holder?.influencer ?? 0);

          influencerIhc.new.holder += ihc?.new?.holder?.influencer ?? 0;
          influencerIhc.returning.holder +=
            ihc?.returning?.holder?.influencer ?? 0;
        } else if (clid === "" && discountCode === "") {
          cjAnalyticsData.ihc.new = ihc.new;
          cjAnalyticsData.ihc.returning = ihc.returning;
        } else if (["closer", "holder", "initiator"].includes(clid)) {
          // @ts-ignore
          cjAnalyticsData.ihc[clid] = clResults as any;
        }
      }
    }
    if (options.cooperationData) {
      const influencerCampaignData = mergeCooperationWithAnalytics(
        options.cooperationData,
        tcAnalyticsData,
        {
          startTime: timeSettings.startTime,
          endTime: timeSettings.endTime,
          timezone: timezoneWithOffset,
        },
        {
          calculateNewCustomerScores: options.calculateNewCustomerScores,
          isNewUserData: options.isNewUserData,
          forceCalculateNvr: options.forceCalculateNvr,
        }
      )?.filter((el) => checkIfBaseScoreHasData(el)) as BaseScore[];
      const influencerSpend = influencerCampaignData.reduce(
        (prev, curr) => prev + (curr.spend ?? 0),
        0
      );
      channelSpend["influencer_module"] =
        (channelSpend["influencer_module"] ?? 0) + influencerSpend;
    }

    cjAnalyticsData.ihc.closer
      ? (cjAnalyticsData.ihc.closer["influencer_module"] = influencerIhc.closer)
      : null;
    cjAnalyticsData.ihc.initiator
      ? (cjAnalyticsData.ihc.initiator["influencer_module"] =
          influencerIhc.initiator)
      : null;
    cjAnalyticsData.ihc.holder
      ? (cjAnalyticsData.ihc.holder["influencer_module"] = influencerIhc.holder)
      : null;
    cjAnalyticsData.ihc.new?.closer
      ? (cjAnalyticsData.ihc.new.closer["influencer_module"] =
          influencerIhc.new?.closer)
      : null;
    cjAnalyticsData.ihc.new?.initiator
      ? (cjAnalyticsData.ihc.new.initiator["influencer_module"] =
          influencerIhc.new.initiator)
      : null;
    cjAnalyticsData.ihc.new?.holder
      ? (cjAnalyticsData.ihc.new.holder["influencer_module"] =
          influencerIhc.new.holder)
      : null;
    cjAnalyticsData.ihc.returning?.closer
      ? (cjAnalyticsData.ihc.returning.closer["influencer_module"] =
          influencerIhc.returning.closer)
      : null;
    cjAnalyticsData.ihc.returning?.initiator
      ? (cjAnalyticsData.ihc.returning.initiator["influencer_module"] =
          influencerIhc.returning?.initiator)
      : null;
    cjAnalyticsData.ihc.returning?.holder
      ? (cjAnalyticsData.ihc.returning.holder["influencer_module"] =
          influencerIhc.returning?.holder)
      : null;

    for (const [path, value] of Object.entries(cjData.conversion_paths)) {
      const newPathScore = initializePathScore({
        path: value,
        ads: ads,
        cjAds: cjData.ad_ids,
        type: "tc",
        linkScores: tcAnalyticsData,
      });
      const splitPath = path.split("->");
      const steps = splitPath.length;
      conversionPaths[path] = {
        ...newPathScore,
        ad_ids: value.ad_ids,
        deltas: value.deltas,
        steps,
        path,
        firstTouchpoint: splitPath[0],
        lastTouchpoint: splitPath[splitPath.length - 1],
      };
      if (steps > maxSteps) maxSteps = steps;
      totalCustomers += newPathScore.customers ?? 0;
    }
    for (const value of Object.values(conversionPaths)) {
      value.customerPercentage = value.customers / totalCustomers;
      value.new.customerPercentage = value.new.customers / totalCustomers;
      value.returning.customerPercentage =
        value.returning.customers / totalCustomers;
    }

    cjAnalyticsData.channelSpend = channelSpend;
    cjAnalyticsData.maxSteps = maxSteps;
    cjAnalyticsData.conversionPaths = conversionPaths;
    index++;
  }
  return cjAnalyticsData;
};

/**
 *
 * @param {AdInfoCollection} adInfoData the ad infos we get back from the ad-connector for a given request
 * @param {ExtractAdInfoSelection} [selection] optional selection of infos you want to receive back
 * @return {ExtractAdInfoResult} returns [AdInfo, AdsetInfo, CampaignInfo]
 */
export const extractInfosFromAnalyticsResult = (
  adInfoData: AdInfoCollection | undefined,
  selection?: ExtractAdInfoSelection
): ExtractAdInfoResult => {
  const adInfos = new Map<string, AdInfo>();
  const adsetInfos = new Map<string, AdsetInfo>();
  const campaignInfos = new Map<string, CampaignInfo>();
  if (adInfoData?.ad_infos !== undefined && (!selection || selection.ads)) {
    for (const [scopedAdId, adInfo] of Object.entries(adInfoData.ad_infos)) {
      const adsetId = adInfo.adset_id?.split("::")[1] ?? 0;
      const campaignId = adInfo.campaign_id?.split("::")[1] ?? 0;
      const splitAdId = scopedAdId.split("::") ?? 0;
      let fqId = "";
      if (adsetId && campaignId) {
        fqId = `${splitAdId[0]}::${campaignId}::${adsetId}::${splitAdId[1]}`;
        adInfos.set(fqId, adInfo);
      } else {
        adInfos.set(scopedAdId, adInfo);
      }
    }
  }
  if (
    adInfoData?.adset_infos !== undefined &&
    (!selection || selection.adsets)
  ) {
    for (const [scopedAdsetId, adsetInfo] of Object.entries(
      adInfoData.adset_infos
    )) {
      const campaignId = adsetInfo.campaign_id?.split("::")[1] ?? 0;
      const splitAdsetId = scopedAdsetId.split("::") ?? 0;
      let fqId = "";
      if (campaignId) {
        fqId = `${splitAdsetId[0]}::${campaignId}::${splitAdsetId[1]}`;
        adsetInfos.set(fqId, adsetInfo);
      } else {
        adsetInfos.set(scopedAdsetId, adsetInfo);
      }
    }
  }
  if (
    adInfoData?.campaign_infos !== undefined &&
    (!selection || selection.campaigns)
  ) {
    for (const [scopedCampaignId, campaignInfo] of Object.entries(
      adInfoData.campaign_infos
    )) {
      campaignInfos.set(scopedCampaignId, campaignInfo);
    }
  }
  return [adInfos, adsetInfos, campaignInfos];
};

const joinNvrAttributionData = (attributionData: NvrAttributionData) => {
  const newData = attributionData?.new;
  const returningData = attributionData?.returning;

  // in these cases we don't have to join anything
  if (!newData && !returningData) return {};
  if (!newData) return returningData;
  if (!returningData) return newData;

  // we use JSON functions to make a deep copy of the newData object
  const allData: AttributionData = JSON.parse(JSON.stringify(newData));

  for (const [fqid, value] of Object.entries(returningData)) {
    // if we have data for this exact fqid, we have to add the returning data to it
    if (allData[fqid]) {
      for (const [csid, interactionMap] of Object.entries(value)) {
        if (!allData[fqid][csid]) {
          allData[fqid] = { ...allData[fqid], [csid]: { ...interactionMap } };
        } else {
          for (const [interaction, scoreMap] of Object.entries(
            interactionMap
          )) {
            for (const [scoreName, scoreValue] of Object.entries(scoreMap)) {
              if (scoreName === "attribution") {
                if (interaction === "pageview") {
                  const currentValue =
                    allData[fqid][csid].pageview?.attribution ?? 0;
                  allData[fqid][csid].pageview = {
                    attribution: currentValue + scoreValue,
                  };
                } else if (interaction === "productview") {
                  const currentValue =
                    allData[fqid][csid].productview?.attribution ?? 0;
                  allData[fqid][csid].productview = {
                    attribution: currentValue + scoreValue,
                  };
                } else if (interaction === "purchase") {
                  const currentValue =
                    allData[fqid][csid].purchase?.attribution ?? 0;
                  const nitemsValue = allData[fqid][csid].purchase?.nitems ?? 0;
                  const amountValue = allData[fqid][csid].purchase?.amount ?? 0;

                  allData[fqid][csid].purchase = {
                    attribution: currentValue + scoreValue,
                    nitems: nitemsValue,
                    amount: amountValue,
                  };
                } else if (interaction === "addtocart") {
                  const currentValue =
                    allData[fqid][csid].addtocart?.attribution ?? 0;
                  allData[fqid][csid].addtocart = {
                    attribution: currentValue + scoreValue,
                  };
                }
              } else if (scoreName === "nitems") {
                if (interaction === "purchase") {
                  const attributionValue =
                    allData[fqid][csid].purchase?.attribution ?? 0;
                  const nitemsValue = allData[fqid][csid].purchase?.nitems ?? 0;
                  const amountValue = allData[fqid][csid].purchase?.amount ?? 0;

                  allData[fqid][csid].purchase = {
                    attribution: attributionValue,
                    nitems: nitemsValue + scoreValue,
                    amount: amountValue,
                  };
                }
              } else if (scoreName === "amount") {
                if (interaction === "purchase") {
                  const attributionValue =
                    allData[fqid][csid].purchase?.attribution ?? 0;
                  const nitemsValue = allData[fqid][csid].purchase?.nitems ?? 0;
                  const amountValue = allData[fqid][csid].purchase?.amount ?? 0;
                  allData[fqid][csid].purchase = {
                    attribution: attributionValue,
                    nitems: nitemsValue,
                    amount: amountValue + scoreValue,
                  };
                }
              }
            }
          }
        }
      }
    } else {
      allData[fqid] = { ...value };
    }
  }
  return allData;
};

const addCacDataToNvrAll = (newData: BaseAnalytics, allData: BaseAnalytics) => {
  for (const [type, values] of Object.entries(newData)) {
    for (const [channel, scores] of values.entries()) {
      const allValues =
        allData[type as keyof BaseAnalytics]?.get(channel) ?? [];
      for (const untypedScore of scores) {
        const score = untypedScore as BaseScore;

        const scoreInAll = (allValues as BaseScore[])?.find((el: BaseScore) => {
          return (
            typeof el === "object" &&
            el?.fullyQualifiedId === score.fullyQualifiedId
          );
        });

        if (scoreInAll) {
          scoreInAll.nvr_purchaseCount = score.purchaseCount;
          scoreInAll.nvr_purchaseAmount = score.purchaseAmount;
          scoreInAll.nvr_upv = score.pageview;
          scoreInAll.newCustomerRate =
            kpiCalculations.newCustomerRate(scoreInAll);
          scoreInAll.newVisitorRate =
            kpiCalculations.newVisitorRate(scoreInAll);
          scoreInAll.cac = score.cac;
        }
      }
    }
  }
};

const addAllDataToNvrNew = (newData: BaseAnalytics, allData: BaseAnalytics) => {
  for (const [type, values] of Object.entries(allData)) {
    for (const [channel, scores] of values.entries()) {
      const newValues = (newData[type as keyof BaseAnalytics]?.get(channel) ??
        []) as BaseScore[];
      for (const untypedScore of scores) {
        const score = untypedScore as BaseScore;

        const scoreInNew = newValues?.find((el: BaseScore) => {
          return (
            typeof el === "object" &&
            el?.fullyQualifiedId === score.fullyQualifiedId
          );
        });
        if (scoreInNew) {
          scoreInNew.nvr_purchaseCount = score.purchaseCount;
          scoreInNew.nvr_purchaseAmount = score.purchaseAmount;
          scoreInNew.nvr_upv = score.pageview;
          scoreInNew.spend = score.spend;
          scoreInNew.newCustomerRate = kpiCalculations.newCustomerRate(
            scoreInNew,
            true
          );
          scoreInNew.newVisitorRate = kpiCalculations.newVisitorRate(
            scoreInNew,
            true
          );
        } else {
          // if we don't have this score in the new score array
          // add it, set all values to 0, except the pageview and orders for **all** customers
          // so we can correctly sum those up to display channel metrics
          const emptyBaseScore = structuredClone(score);
          for (const untypedKey in emptyBaseScore) {
            if (untypedKey) {
              const key = untypedKey as keyof typeof emptyBaseScore;
              const value = emptyBaseScore[key];
              if (value && typeof value === "number") {
                // @ts-ignore
                emptyBaseScore[key] = 0;
              }
            }
          }
          emptyBaseScore.nvr_purchaseCount = score.purchaseCount;
          emptyBaseScore.nvr_purchaseAmount = score.purchaseAmount;
          emptyBaseScore.nvr_upv = score.pageview;
          emptyBaseScore.spend = score.spend;
          emptyBaseScore.newCustomerRate = 0;
          emptyBaseScore.newVisitorRate = 0;
          newValues.push(emptyBaseScore);
        }
      }
    }
  }
};

const addAllDataToNvrReturning = (
  returningData: BaseAnalytics,
  allData: BaseAnalytics
) => {
  for (const [type, values] of Object.entries(allData)) {
    for (const [channel, scores] of values.entries()) {
      const returningValues = (returningData[type as keyof BaseAnalytics]?.get(
        channel
      ) ?? []) as BaseScore[];
      for (const untypedScore of scores) {
        const score = untypedScore as BaseScore;

        const scoreInReturning = returningValues?.find((el: BaseScore) => {
          return (
            typeof el === "object" &&
            el?.fullyQualifiedId === score.fullyQualifiedId
          );
        });
        if (scoreInReturning) {
          scoreInReturning.nvr_purchaseCount = score.purchaseCount;
          scoreInReturning.nvr_purchaseAmount = score.purchaseAmount;
          scoreInReturning.nvr_upv = score.pageview;
          scoreInReturning.spend = score.spend;
        } else {
          // if we don't have this score in the new score array
          // add it, set all values to 0, except the pageview and orders for **all** customers
          // so we can correctly sum those up to display channel metrics
          const emptyBaseScore = structuredClone(score);
          for (const untypedKey in emptyBaseScore) {
            if (untypedKey) {
              const key = untypedKey as keyof typeof emptyBaseScore;
              const value = emptyBaseScore[key];
              if (value && typeof value === "number") {
                // @ts-ignore
                emptyBaseScore[key] = 0;
              }
            }
          }
          emptyBaseScore.nvr_purchaseCount = score.purchaseCount;
          emptyBaseScore.nvr_purchaseAmount = score.purchaseAmount;
          emptyBaseScore.nvr_upv = score.pageview;
          emptyBaseScore.spend = score.spend;
          returningValues.push(emptyBaseScore);
        }
      }
    }
  }
};

/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @param {TimeSettingsType} timeSettings the timesettings used for creating the request
 * @return {NvrDailyBaseAnalytics} returns the BaseAnalytics result for every day we got data
 */
export const transformNvrDailyAnalyticsResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType
) => {
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].nvr_daily
  ) {
    // return empty result
    return {} as NvrDailyBaseAnalytics;
  }

  const rawResult = { ...result } as AnalyticsResult;
  const allAdsetIds = result.adsetids;
  const allCampaignIds = result.campaignids;
  const nvrDailyAnalyticsData: NvrDailyBaseAnalytics = {};
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.nvr_daily) {
      return {} as NvrDailyBaseAnalytics;
    }
    const diff = dayjs(timeSettings.endTime).diff(
      dayjs(timeSettings.startTime),
      "day"
    );
    let addedDatesManually = false;
    for (let i = 0; i <= diff; i++) {
      const day = dayjs(timeSettings.startTime)
        .utcOffset(timeSettings.utcOffset ?? 0)
        .add(i, "day")
        .format("YYYY-MM-DD");

      if (!attribution.nvr_daily[day]) {
        addedDatesManually = true;
        attribution.nvr_daily[day] = { new: {}, returning: {} };
      }
    }
    if (addedDatesManually) {
      // obj keys need to be sorted for the daily analytics (graphs) to work
      const sortedArray = Object.entries(attribution.nvr_daily).sort(
        ([key1], [key2]) => dayjs(key1).unix() - dayjs(key2).unix()
      );
      attribution.nvr_daily = Object.fromEntries(sortedArray);
    }
    for (const [attributionDay, attributionData] of Object.entries(
      attribution.nvr_daily
    )) {
      // here we get the dates back in the format "YYYY-MM-DD" and we want to
      // transform that to the request timezone in UTC by adding the utcOffset
      // to be able to check if the start and end date are the same as in the current local day
      const attributionDate = timeSettings.utcOffset
        ? dayjs(attributionDay)
            .utc(true)
            .add(timeSettings.utcOffset * -1, "hour")
        : dayjs(attributionDay);

      const isInTimeRange =
        attributionDate.isAfter(
          dayjs(timeSettings.startTime).subtract(1, "second"), // subtract 1 second to avoid having to check for same & after
          "second"
        ) &&
        attributionDate.isBefore(
          dayjs(timeSettings.endTime).add(1, "second"), // add 1 second to avoid having to check for same & before
          "second"
        );

      // only if the date is in the selected timerange, we want to add its result to our analytics data
      if (isInTimeRange) {
        const dailyAggregatedAdKpis: {
          [key: string]: { [key: string]: number };
        } = {};

        if (result?.ads && result?.ads[index]?.ad_kpis) {
          // quick fix for summer time changes where ad connector dates are outside of current date
          // to fix this, we add the offset diff to the adkpi date below
          const startOffset = dayjs(timeSettings.startTime)
            .tz(timeSettings.timezone)
            .utcOffset();
          const offsetDiff = startOffset / 60 - (timeSettings.utcOffset ?? 0);

          // for every adId we get an array of ad kpis for every date in the timerange (when available)
          for (const [adId, adKpis] of Object.entries(
            result?.ads[index]?.ad_kpis ?? {}
          )) {
            for (const adKpi of adKpis) {
              // here we get the date in UTC format YYYY-MM-DD HH:mm:ss in the accounts
              // timezone in the respective ads manager (fb, google, tiktok)
              // since we can only get date for the account in this timezone,
              // we can also only check if the end of the date in the accounts timezone
              // is at least the same day and add these kpi to this date then
              const adKpiDate = dayjs(adKpi.date)
                .utc(true)
                .add(offsetDiff, "hours");

              if (adKpiDate.isSame(attributionDate, "day")) {
                dailyAggregatedAdKpis[adId] = adKpi;
              }
            }
          }
        }

        // here we mimic the result we would get back from Hive for only the current date in
        // the aggregated view, so we can reuse the transform function
        const newDailyDataToAggregateMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ aggregated: attributionData.new }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };
        // here we mimic the result we would get back from Hive for only the current date in
        // the aggregated view, so we can reuse the transform function
        const returningDailyDataToAggregateMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ aggregated: attributionData.returning }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };

        // we want to join both the new and returning data for properly displaying the total amounts
        // without counting the ad-connector data twice
        const allAttributions = joinNvrAttributionData(attributionData);

        const allDailyDataToAggregateMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ aggregated: allAttributions }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };

        const newDailyData = transformFullyQualifiedAnalyticsResult(
          newDailyDataToAggregateMap,
          {
            calculateNewCustomerScores: true,
            isNewUserData: true,
          }
        );
        const returningDailyData = transformFullyQualifiedAnalyticsResult(
          returningDailyDataToAggregateMap
        );
        const allDailyData = transformFullyQualifiedAnalyticsResult(
          allDailyDataToAggregateMap
        );
        // add the purchaseCount and cac to allData, so we can display that in our tables
        if (newDailyData && allDailyData) {
          addCacDataToNvrAll(newDailyData, allDailyData);
          addAllDataToNvrNew(newDailyData, allDailyData);
          if (returningDailyData) {
            addAllDataToNvrReturning(returningDailyData, allDailyData);
          }
        }

        if (nvrDailyAnalyticsData[attributionDay] && newDailyData) {
          nvrDailyAnalyticsData[attributionDay].new = {
            ...nvrDailyAnalyticsData[attributionDay].new,
            ...newDailyData,
          };
        } else if (newDailyData) {
          nvrDailyAnalyticsData[attributionDay] = { new: newDailyData };
        }

        if (nvrDailyAnalyticsData[attributionDay] && returningDailyData) {
          nvrDailyAnalyticsData[attributionDay].returning = {
            ...nvrDailyAnalyticsData[attributionDay].returning,
            ...returningDailyData,
          };
        } else if (returningDailyData) {
          nvrDailyAnalyticsData[attributionDay] = {
            returning: returningDailyData,
          };
        }
        if (nvrDailyAnalyticsData[attributionDay] && allDailyData) {
          nvrDailyAnalyticsData[attributionDay].all = {
            ...nvrDailyAnalyticsData[attributionDay].all,
            ...allDailyData,
          };
        } else if (allDailyData) {
          nvrDailyAnalyticsData[attributionDay] = {
            all: allDailyData,
          };
        }
      }
    }
    index += 1;
  }
  return nvrDailyAnalyticsData;
};

/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @return {NvrBaseAnalytics} returns the BaseAnalytics result for every day we got data
 */
export const transformNvrAnalyticsResult = (result: AnalyticsResult) => {
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].nvr_aggregated
  ) {
    // return empty result
    return {} as NvrBaseAnalytics;
  }

  const rawResult = { ...result } as AnalyticsResult;
  const allAdsetIds = result.adsetids;
  const allCampaignIds = result.campaignids;
  const nvrAnalyticsData: NvrBaseAnalytics = {} as NvrBaseAnalytics;
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.nvr_aggregated) {
      return {} as NvrBaseAnalytics;
    }
    // here we mimic the result we would get back from Hive for only the current date in
    // the aggregated view, so we can reuse the transform function
    const newDataToAggregateMap: AnalyticsResult = {
      adsetids: allAdsetIds,
      campaignids: allCampaignIds,
      totals: result.totals,
      attributions: [{ aggregated: attribution.nvr_aggregated.new }],
      ads: result.ads ? [result.ads[index]] : [],
    };
    const returningDataToAggregateMap: AnalyticsResult = {
      adsetids: allAdsetIds,
      campaignids: allCampaignIds,
      totals: result.totals,
      attributions: [{ aggregated: attribution.nvr_aggregated.returning }],
      ads: result.ads ? [result.ads[index]] : [],
    };
    const allAttributions = joinNvrAttributionData(attribution.nvr_aggregated);
    const allDataToAggregateMap: AnalyticsResult = {
      adsetids: allAdsetIds,
      campaignids: allCampaignIds,
      totals: result.totals,
      attributions: [{ aggregated: allAttributions }],
      ads: result.ads ? [result.ads[index]] : [],
    };

    const newData = transformFullyQualifiedAnalyticsResult(
      newDataToAggregateMap,
      { calculateNewCustomerScores: true, isNewUserData: true }
    );
    const returningData = transformFullyQualifiedAnalyticsResult(
      returningDataToAggregateMap
    );
    const allData = transformFullyQualifiedAnalyticsResult(
      allDataToAggregateMap,
      { calculateNewCustomerScores: true }
    );

    // add the purchaseCount and cac to allData, so we can display that in our tables
    if (newData && allData) {
      addCacDataToNvrAll(newData, allData);
      addAllDataToNvrNew(newData, allData);
      if (returningData) {
        addAllDataToNvrReturning(returningData, allData);
      }
    }
    if (newData) {
      nvrAnalyticsData.new = newData;
    }
    if (returningData) {
      nvrAnalyticsData.returning = returningData;
    }
    if (allData) {
      nvrAnalyticsData.all = allData;
    }

    index += 1;
  }

  return nvrAnalyticsData;
};

/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @param {TimeSettingsType} timeSettings the timesettings used for creating the request
 * @return {DailyBaseAnalytics} returns the BaseAnalytics result for every day we got data
 */
export const transformDailyAnalyticsResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType
) => {
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].daily
  ) {
    // return empty result
    return {} as DailyBaseAnalytics;
  }

  const rawResult = { ...result } as AnalyticsResult;
  const allAdsetIds = result.adsetids;
  const allCampaignIds = result.campaignids;
  const dailyAnalyticsData: DailyBaseAnalytics = {};
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.daily) {
      return {} as DailyBaseAnalytics;
    }

    const diff = dayjs(timeSettings.endTime).diff(
      dayjs(timeSettings.startTime),
      "day"
    );
    let addedDatesManually = false;
    for (let i = 0; i <= diff; i++) {
      const day = dayjs(timeSettings.startTime)
        .utcOffset(timeSettings.utcOffset ?? 0)
        .add(i, "day")
        .format("YYYY-MM-DD");

      if (!attribution.daily[day]) {
        addedDatesManually = true;
        attribution.daily[day] = {};
      }
    }

    if (addedDatesManually) {
      // obj keys need to be sorted for the daily analytics (graphs) to work
      const sortedArray = Object.entries(attribution.daily).sort(
        ([key1], [key2]) => dayjs(key1).unix() - dayjs(key2).unix()
      );
      attribution.daily = Object.fromEntries(sortedArray);
    }
    for (const [attributionDay, attributionData] of Object.entries(
      attribution.daily
    )) {
      // here we get the dates back in the format "YYYY-MM-DD" and we want to
      // transform that to the request timezone in UTC by adding the utcOffset
      // to be able to check if the start and end date are the same as in the current local day
      const attributionDate = timeSettings.utcOffset
        ? dayjs(attributionDay).utcOffset(timeSettings.utcOffset, true)
        : dayjs(attributionDay);

      const isInTimeRange =
        attributionDate.isAfter(
          dayjs(timeSettings.startTime).subtract(1, "second"), // subtract 1 second to avoid having to check for same & after
          "second"
        ) &&
        attributionDate.isBefore(
          dayjs(timeSettings.endTime).add(1, "second"), // add 1 second to avoid having to check for same & before
          "second"
        );

      // only if the date is in the selected timerange, we want to add its result to our analytics data
      if (isInTimeRange) {
        const dailyAggregatedAdKpis: {
          [key: string]: { [key: string]: number };
        } = {};

        if (result?.ads && result?.ads[index]?.ad_kpis) {
          // quick fix for summer time changes where ad connector dates are outside of current date
          // to fix this, we add the offset diff to the adkpi date below
          const startOffset = dayjs(timeSettings.startTime)
            .tz(timeSettings.timezone)
            .utcOffset();
          const offsetDiff = startOffset / 60 - (timeSettings.utcOffset ?? 0);

          // for every adId we get an array of ad kpis for every date in the timerange (when available)
          for (const [adId, adKpis] of Object.entries(
            result?.ads[index]?.ad_kpis ?? {}
          )) {
            for (const adKpi of adKpis) {
              // here we get the date in UTC format YYYY-MM-DD HH:mm:ss in the accounts
              // timezone in the respective ads manager (fb, google, tiktok)
              // since we can only get date for the account in this timezone,
              // we can also only check if the end of the date in the accounts timezone
              // is at least the same day and add these kpi to this date then
              const adKpiDate = dayjs(adKpi.date)
                .utc(true)
                .add(offsetDiff, "hours");

              // check if date is between start and end of date because we
              // sometimes also have ads that have a different timestamp
              // (i.e. different timezone in ads account) and might be a different date in utc
              const dateBoolean =
                adKpiDate.isAfter(attributionDate.subtract(1, "second")) &&
                adKpiDate.isBefore(
                  attributionDate.add(1, "day").subtract(1, "millisecond")
                );

              if (dateBoolean) {
                dailyAggregatedAdKpis[adId] = adKpi;
              }
            }
          }
        }

        // here we mimic the result we would get back from Hive for only the current date in
        // the aggregated view, so we can reuse the transform function
        const dailyDataToAggregatedMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ aggregated: attributionData }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };
        const dailyData = transformFullyQualifiedAnalyticsResult(
          dailyDataToAggregatedMap
        );
        if (dailyAnalyticsData[attributionDay] && dailyData) {
          dailyAnalyticsData[attributionDay] = {
            ...dailyAnalyticsData[attributionDay],
            ...dailyData,
          };
        } else if (dailyData) {
          dailyAnalyticsData[attributionDay] = dailyData;
        }
      }
    }
    index += 1;
  }
  return dailyAnalyticsData;
};

export const checkIfBaseScoreHasData = (baseScore: BaseScore) => {
  return !(
    baseScore.addtocart === 0 &&
    baseScore.pageview === 0 &&
    baseScore.productview === 0 &&
    baseScore.purchaseCount === 0 &&
    baseScore.purchaseAmount === 0 &&
    baseScore.checkout === 0 &&
    baseScore.purchaseCartItems === 0 &&
    baseScore.spend === 0 &&
    (baseScore.clicks === 0 || baseScore.linkClick === 0) &&
    baseScore.platformConversions === 0 &&
    baseScore.allPlatformConversions === 0 &&
    baseScore.reach === 0 &&
    baseScore.impressions === 0
  );
};

export type ScopeType =
  | (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"]
  | "referral"
  | "influencer_module";

export type SplitScopedAdIdProps = {
  scopedId: string;
  type: "ad";
};
export type SplitScopedAdsetIdProps = {
  scopedId: string;
  type: "adset";
};
export type SplitScopedCampaignIdProps = {
  scopedId: string;
  type: "campaign";
};

export type SplitScopedAdIdResult = {
  scope: ScopeType;
  campaignId: string;
  adsetId: string;
  adId: string;
  fqId: string;
};
export type SplitScopedAdsetIdResult = {
  scope: ScopeType;
  campaignId: string;
  adsetId: string;
  fqId: string;
};
export type SplitScopedCampaignIdResult = {
  scope: ScopeType;
  campaignId: string;
  fqId: string;
};

// conditional return types based on input
// see https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
export function splitScopedId({
  scopedId,
  type,
}: SplitScopedAdIdProps): SplitScopedAdIdResult;
export function splitScopedId({
  scopedId,
  type,
}: SplitScopedAdsetIdProps): SplitScopedAdsetIdResult;
export function splitScopedId({
  scopedId,
  type,
}: SplitScopedCampaignIdProps): SplitScopedCampaignIdResult;

// function implementation for above types
export function splitScopedId({
  scopedId,
  type,
}:
  | SplitScopedAdIdProps
  | SplitScopedAdsetIdProps
  | SplitScopedCampaignIdProps) {
  const splitId = scopedId.split("::");
  const scope = splitId[0] as ScopeType;
  const rest = splitId.slice(1);
  const campaignId = rest[0];
  const adsetId = type !== "campaign" ? rest[1] : "";
  const adId = type === "ad" ? rest[rest.length - 1] : "";
  const fqId = rest.join("::");

  return { campaignId, adsetId, adId, fqId, scope };
}

export type InitializeAdScoreProps = {
  baseScoreId: string;
  fqId?: string;
  baseScoreInfo?: AdInfo | AdsetInfo | CampaignInfo;
  scope: ScopeType;
  type?: BaseScore["type"];
  adKpi?: {
    [key: string]: number;
  } | null;
};

export const initializeBaseScoreValue = ({
  baseScoreId,
  fqId,
  scope,
  baseScoreInfo,
  adKpi,
  type,
}: InitializeAdScoreProps) => {
  const adScore: BaseScore = {
    refId: baseScoreId,
    fullyQualifiedId: fqId,
    name: baseScoreInfo?.name || baseScoreId,
    status: baseScoreInfo?.status,
    addtocart: 0,
    pageview: 0,
    productview: 0,
    purchaseCount: 0,
    purchaseAmount: 0.0,
    purchaseCartItems: 0,
    checkout: 0,
    cac: 0,
    cpo: 0,
    frequency: 0,
    roas: 0,
    ctr: 0,
    thumbstopRatio: 0,
    cpc: 0,
    cr: 0,
    aov: 0,
    clicks: adKpi?.clicks ?? 0,
    cpm: adKpi?.cpm ?? 0,
    impressions: adKpi?.impressions ?? 0,
    landingPageView: adKpi?.landing_page_view ?? 0,
    linkClick: adKpi?.link_click ?? 0,
    platformConversions: adKpi?.conversions ?? 0,
    allPlatformConversions: adKpi?.all_conversions ?? 0,
    reach: adKpi?.reach ?? 0,
    spend: adKpi?.spend
      ? adKpi?.spend
      : adKpi?.cpm && adKpi?.reach
        ? (adKpi?.cpm * adKpi?.reach) / 1000
        : 0,
    fixedSpent: adKpi?.fixedSpent ?? 0,
    variableSpent: adKpi?.variableSpent ?? 0,
    type: type,
    provider: scope,
    subRows: [],
  };
  return adScore;
};

export const getAdScoresFromAggregatedAttribution = (
  { attributions, ads }: AnalyticsResult,
  options?: FQTransformOptions
) => {
  let attrIdx = 0;
  const scopedAds = new Map<string, BaseScore>(); // <- we only use this for adset / campaign grouping

  // here we go through raw result data and transform them into the more readable BaseScore view
  for (const attribution of attributions) {
    if (!attribution.aggregated) return scopedAds;

    const correspondingAds = ads?.at(attrIdx);
    const [adInfos] = extractInfosFromAnalyticsResult(correspondingAds, {
      ads: true,
    });
    attrIdx++;

    for (const [scopedAdId, csidMap] of Object.entries(
      attribution.aggregated ?? {}
    )) {
      const { scope, adId, fqId } = splitScopedId({
        scopedId: scopedAdId,
        type: "ad",
      });

      // just in case we don't have the fq id in the ad connector data
      // we also check if there is data for the regular adId
      const adKpi =
        ads && ads[0] && ads[0].aggregated_ad_kpis
          ? ads[0].aggregated_ad_kpis[`${scope}::${fqId}`] ??
            ads[0].aggregated_ad_kpis[`${scope}::${adId}`]
          : null;

      // we normally return the adInfos with the fq-ids as the key
      // only when there is no campaign/adset id available, we return it with the normal id
      const adInfo =
        adInfos?.get(`${scope}::${fqId}`) ?? adInfos?.get(`${scope}::${adId}`);

      // initialize adScore with adKpi from ad-connector but without attribution values from Hive
      const adScore = initializeBaseScoreValue({
        baseScoreId: adId,
        fqId,
        scope: scope as ScopeType,
        adKpi,
        baseScoreInfo: adInfo,
        type: "ad",
      });
      // add attribution data from Hive to adScore
      for (const [, interactionMap] of Object.entries(csidMap)) {
        for (const [interaction, scoreMap] of Object.entries(
          interactionMap as Object
        )) {
          for (const [scoreName, scoreValue] of Object.entries(
            scoreMap as Object
          )) {
            if (scoreName === "attribution") {
              if (interaction === "pageview") adScore.pageview += scoreValue;
              else if (interaction === "productview")
                adScore.productview += scoreValue;
              else if (interaction === "checkout")
                adScore.checkout += scoreValue;
              else if (interaction === "purchase")
                adScore.purchaseCount += scoreValue;
              else if (interaction === "addtocart")
                adScore.addtocart += scoreValue;
            } else if (scoreName === "nitems") {
              if (interaction === "purchase")
                adScore.purchaseCartItems += scoreValue;
            } else if (scoreName === "amount") {
              if (interaction === "purchase")
                adScore.purchaseAmount += scoreValue;
            }
          }
        }
      }

      adScore.roas = kpiCalculations.roas(adScore);
      adScore.frequency = kpiCalculations.frequency(adScore);
      adScore.cpm = kpiCalculations.cpm(adScore);
      adScore.cpo = kpiCalculations.cpo(adScore);
      adScore.ctr = kpiCalculations.ctr(adScore);
      adScore.thumbstopRatio = kpiCalculations.thumbstopRatio(adScore);
      adScore.cpc = kpiCalculations.cpc(adScore);
      adScore.cr = kpiCalculations.cr(adScore);
      adScore.aov = kpiCalculations.aov(adScore);
      if (options?.calculateNewCustomerScores) {
        adScore.cac = kpiCalculations.cac(adScore, options.isNewUserData);
        adScore.newCustomerRate = kpiCalculations.newCustomerRate(
          adScore,
          options.isNewUserData
        );
        adScore.newVisitorRate = kpiCalculations.newVisitorRate(
          adScore,
          options.isNewUserData
        );
      }

      const hasData = checkIfBaseScoreHasData(adScore);

      if (hasData) {
        scopedAds.set(scopedAdId, adScore);
      }
    }
  }
  return scopedAds;
};

export const getUnattributedAdScores = (
  { ads }: AnalyticsResult,
  scopedAds: Map<string, BaseScore>,
  options?: FQTransformOptions
) => {
  const unattributedAds: Array<{
    campaignId: string;
    adsetId: string;
    adId: string;
    fqId: string;
  }> = [];

  ads?.forEach((correspondingAds, index) => {
    const [adInfos] = extractInfosFromAnalyticsResult(correspondingAds, {
      ads: true,
    });

    for (const [scopedAdId, adKpi] of Object.entries(
      correspondingAds.aggregated_ad_kpis || {}
    )) {
      const { scope, adId, fqId, campaignId, adsetId } = splitScopedId({
        scopedId: scopedAdId,
        type: "ad",
      });
      const attributedAd = scopedAds?.get(scopedAdId);

      // We didn't find it during attribution
      if (!attributedAd) {
        unattributedAds.push({
          campaignId,
          adsetId,
          adId,
          fqId: `${scope}::${fqId}`,
        });
        // Hence we backfill an adScore object
        // with empty attribution details, but with filled kpis.
        const adInfo =
          adInfos?.get(`${scope}::${fqId}`) ??
          adInfos?.get(`${scope}::${adId}`);

        const adScore = initializeBaseScoreValue({
          baseScoreId: adId,
          fqId,
          scope: scope,
          adKpi,
          baseScoreInfo: adInfo,
          type: "ad",
        });

        adScore.roas = kpiCalculations.roas(adScore);
        adScore.frequency = kpiCalculations.frequency(adScore);
        adScore.cpm = kpiCalculations.cpm(adScore);
        adScore.cpo = kpiCalculations.cpo(adScore);
        adScore.ctr = kpiCalculations.ctr(adScore);
        adScore.thumbstopRatio = kpiCalculations.thumbstopRatio(adScore);
        adScore.cpc = kpiCalculations.cpc(adScore);
        adScore.cr = kpiCalculations.cr(adScore);
        adScore.aov = kpiCalculations.aov(adScore);
        if (options?.calculateNewCustomerScores) {
          adScore.cac = kpiCalculations.cac(adScore, options.isNewUserData);
          adScore.newCustomerRate = kpiCalculations.newCustomerRate(
            adScore,
            options.isNewUserData
          );
          adScore.newVisitorRate = kpiCalculations.newVisitorRate(
            adScore,
            options.isNewUserData
          );
        }

        scopedAds.set(scopedAdId, adScore);
      }
    }
  });
  return { scopedAds, unattributedAds };
};

export type CalculateBaseScoreFromAdsProps = {
  type: "adset" | "campaign";
  result: AnalyticsResult;
  scopedAds: Map<string, BaseScore>;
  scopedAdsets?: Map<string, BaseScore>;
  unattributedAds: {
    campaignId: string;
    adsetId: string;
    adId: string;
    fqId: string;
  }[];
};

export const getBaseScoreFromAds = (
  {
    result: { adsetids, campaignids, ads },
    scopedAds,
    scopedAdsets,
    type,
    unattributedAds,
  }: CalculateBaseScoreFromAdsProps,
  options?: FQTransformOptions
) => {
  const scopedBaseScores = new Map<string, BaseScore>();
  const idsToMap = type === "adset" ? adsetids : campaignids;

  // TODO make this dynamic with an index? -> for now we always only have an attribution array of size 1
  const correspondingAds = ads?.at(0);

  // find ads for which we don't have an attributed adset/campaign
  // (only possible for ads in the unattributedAds array)
  // and add the corresponding adset/campaign with its adIds to idsToMap
  // so we can later add the ad-connector data
  const allBaseScoreKeys = Array.from(idsToMap.keys());
  for (const unattributedAd of unattributedAds) {
    const scope = unattributedAd.fqId.split("::")[0];
    const idToSearch =
      type === "adset"
        ? `${scope}::${unattributedAd.campaignId}::${unattributedAd.adsetId}`
        : `${scope}::${unattributedAd.campaignId}`;
    // check that the id doesn't end with :: which would mean that there is an ID missing
    if (!allBaseScoreKeys.includes(idToSearch) && !idToSearch.endsWith("::")) {
      const currentIds = idsToMap?.get(idToSearch) ?? [];
      idsToMap.set(idToSearch, [...currentIds, unattributedAd.fqId]);
    }
  }

  const infos = extractInfosFromAnalyticsResult(correspondingAds, {
    adsets: type === "adset",
    campaigns: type === "campaign",
  });

  // infos[1] is always the adset info and infos[2] is always the campaign info
  const baseScoreInfos = type === "adset" ? infos[1] : infos[2];

  for (const [scopedId, adIds] of idsToMap.entries()) {
    // eslint-disable-next-line one-var
    let scope: string, fqId: string, baseScoreId: string;

    if (type === "adset") {
      ({
        scope,
        fqId,
        adsetId: baseScoreId,
      } = splitScopedId({
        scopedId: scopedId,
        type: "adset",
      }));
    } else {
      ({
        scope,
        fqId,
        campaignId: baseScoreId,
      } = splitScopedId({
        scopedId: scopedId,
        type: "campaign",
      }));
    }

    let baseScoreInfo =
      baseScoreInfos?.get(`${scope}::${fqId}`) ??
      baseScoreInfos?.get(`${scope}::${baseScoreId}`);

    const baseScore = initializeBaseScoreValue({
      baseScoreId,
      fqId: fqId,
      scope: scope as ScopeType,
      baseScoreInfo: baseScoreInfo,
      type: !CHANNELS_WITHOUT_BASESCORE_TYPE.includes(scope) ? type : undefined,
    });

    const campaignAdsets: string[] = [];
    // we want to find out which adsets belong to the current campaign (if type === campaign)
    if (type === "campaign") {
      baseScoreInfo = baseScoreInfo as CampaignInfo;
      // note: google perf. max and smart campaigns don't have adsets and ads
      if (
        !CHANNELS_WITHOUT_BASESCORE_TYPE.includes(scope) &&
        !["SMART", "PERFORMANCE_MAX"].includes(
          baseScoreInfo?.advertising_channel_type ?? ""
        )
      ) {
        for (const [key, value] of adsetids.entries()) {
          for (const adId of adIds) {
            const id = key.split("::")?.slice(1)?.join("::");
            if (value.includes(`${adId}`) && !campaignAdsets.includes(id) && id)
              campaignAdsets.push(id);
          }
        }
      }
      if (
        (baseScore.provider === "google" ||
          baseScore.provider === "pinterest") &&
        baseScoreInfo
      ) {
        // only added the type here to avoid ts errors
        baseScore.advertisingChannelType =
          baseScoreInfo.advertising_channel_type ?? "UNKNOWN";
      }
    }

    // find out which unattributedAds - that we have calculated earlier - belong to this adset/campaign
    let unattributedBaseScoreAds = unattributedAds
      .filter((el) =>
        type === "adset"
          ? el.adsetId === baseScoreId
          : el.campaignId === baseScoreId
      )
      .map((el) => el.fqId);

    // map the belonging adScores to this adset/campaign
    for (const adId of adIds) {
      // remove the ad if it actually is attributed to this adset/campaign
      const adIndex = unattributedBaseScoreAds.indexOf(adId);
      if (adIndex !== -1) {
        unattributedBaseScoreAds = [
          ...unattributedBaseScoreAds.slice(0, adIndex),
          ...unattributedBaseScoreAds.slice(adIndex + 1),
        ];
      }
      const adScore = scopedAds?.get(adId);
      // TODO why are we missing data here -> data is in scopedAds but we don't attribute it to a campaign??

      if (adScore) {
        baseScore.addtocart += adScore.addtocart;
        baseScore.pageview += adScore.pageview;
        baseScore.productview += adScore.productview;
        baseScore.purchaseAmount += adScore.purchaseAmount;
        baseScore.purchaseCartItems += adScore.purchaseCartItems;
        baseScore.purchaseCount += adScore.purchaseCount;
        baseScore.checkout += adScore.checkout;
        baseScore.clicks! += adScore.clicks ?? 0;
        baseScore.impressions! += adScore.impressions ?? 0;
        baseScore.landingPageView! += adScore.landingPageView ?? 0;
        baseScore.linkClick! += adScore.linkClick ?? 0;
        baseScore.platformConversions! += adScore.platformConversions ?? 0;
        baseScore.allPlatformConversions! +=
          adScore.allPlatformConversions ?? 0;
        baseScore.reach! += adScore.reach ?? 0;
        baseScore.spend! += adScore.spend ?? 0;

        if (type === "adset") {
          const newAdScore = {
            ...adScore,
            parentAdset: {
              refId: baseScore.refId,
              name: baseScore.name,
            },
          };
          // set the updated adScore
          scopedAds.set(adId, newAdScore);
          // add the updated adScore to the adset subRows (for our tables)
          baseScore.subRows.push({ ...newAdScore, type: "ad" });
        } else {
          const newAdScore = {
            ...adScore,
            advertisingChannelType: baseScore.advertisingChannelType,
            parentCampaign: {
              refId: baseScore.refId,
              advertisingChannelType: baseScore.advertisingChannelType,
              name: baseScore.name,
            },
          };
          // set the updated adScore
          scopedAds.set(adId, newAdScore);

          // if there are no adsets for this campaign, only nest ads
          if (
            campaignAdsets.length === 0 &&
            ![
              "influencer",
              "email",
              "referred",
              "referral",
              "whatsapp",
              "direct",
            ].includes(scope) &&
            !["SMART", "PERFORMANCE_MAX"].includes(
              baseScore?.advertisingChannelType ?? ""
            )
          )
            // add the updated adScore to the campaign subRows (for our tables)
            baseScore.subRows.push({ ...newAdScore, type: "ad" });
        }
      }
    }

    // loop over all left over unattributed ads and map the values to the adset/campaign
    if (unattributedBaseScoreAds.length > 0) {
      for (const unattributedAd of unattributedBaseScoreAds) {
        const adScore = scopedAds?.get(unattributedAd);
        if (adScore) {
          baseScore.addtocart += adScore.addtocart;
          baseScore.pageview += adScore.pageview;
          baseScore.productview += adScore.productview;
          baseScore.purchaseAmount += adScore.purchaseAmount;
          baseScore.purchaseCartItems += adScore.purchaseCartItems;
          baseScore.purchaseCount += adScore.purchaseCount;
          baseScore.checkout += adScore.checkout;
          baseScore.clicks! += adScore.clicks ?? 0;
          baseScore.impressions! += adScore.impressions ?? 0;
          baseScore.landingPageView! += adScore.landingPageView ?? 0;
          baseScore.linkClick! += adScore.linkClick ?? 0;
          baseScore.platformConversions! += adScore.platformConversions ?? 0;
          baseScore.allPlatformConversions! +=
            adScore.allPlatformConversions ?? 0;
          baseScore.reach! += adScore.reach ?? 0;
          baseScore.spend! += adScore.spend ?? 0;

          if (type === "adset") {
            const newAdScore = {
              ...adScore,
              parentAdset: {
                refId: baseScore.refId,
                name: baseScore.name,
              },
            };
            // set the updated adScore
            scopedAds.set(unattributedAd, newAdScore);
            // add the updated adScore to the adset subRows (for our tables)
            baseScore.subRows.push({ ...newAdScore, type: "ad" });
          } else {
            const newAdScore = {
              ...adScore,
              advertisingChannelType: baseScore.advertisingChannelType,
              parentCampaign: {
                refId: baseScore.refId,
                advertisingChannelType: baseScore.advertisingChannelType,
                name: baseScore.name,
              },
            };
            // set the updated adScore
            scopedAds.set(unattributedAd, newAdScore);

            // if there are no adsets for this campaign, only nest ads
            if (
              campaignAdsets.length === 0 &&
              ![
                "influencer",
                "email",
                "referred",
                "referral",
                "whatsapp",
                "direct",
              ].includes(scope) &&
              !["SMART", "PERFORMANCE_MAX"].includes(
                baseScore?.advertisingChannelType ?? ""
              )
            )
              // add the updated adScore to the campaign subRows (for our tables)
              baseScore.subRows.push({ ...newAdScore, type: "ad" });
          }
        }
      }
    }

    // only for campaigns: we want to update the belonging adsets with the campaign info
    // to allow filtering in the table and also push the adsets to the campaign subRows for our tables
    if (campaignAdsets.length > 0 && type === "campaign" && scopedAdsets) {
      const allAdsets = Array.from(scopedAdsets.values());
      const keys = Array.from(scopedAdsets.keys());
      for (const campaignAdset of campaignAdsets) {
        const adsetIndex = allAdsets?.findIndex(
          (el) => el.fullyQualifiedId === campaignAdset
        );
        const adset = allAdsets[adsetIndex];
        const key = keys[adsetIndex];
        if (adset) {
          const newAdset = {
            ...adset,
            advertisingChannelType: baseScore.advertisingChannelType,
            parentCampaign: {
              refId: baseScore.refId,
              advertisingChannelType: baseScore.advertisingChannelType,
              name: baseScore.name,
            },
          };
          scopedAdsets.set(key, newAdset);
          baseScore.subRows.push({ ...newAdset, type: "adset" });
        }
      }
    }

    baseScore.subRows.sort((a, b) => b.purchaseAmount - a.purchaseAmount);

    // calculate kpis that can't be just summed up over all adScores
    baseScore.roas = kpiCalculations.roas(baseScore);
    baseScore.frequency = kpiCalculations.frequency(baseScore);
    baseScore.cpm = kpiCalculations.cpm(baseScore);
    baseScore.cpo = kpiCalculations.cpo(baseScore);
    baseScore.ctr = kpiCalculations.ctr(baseScore);
    baseScore.thumbstopRatio = kpiCalculations.thumbstopRatio(baseScore);
    baseScore.cpc = kpiCalculations.cpc(baseScore);
    baseScore.cr = kpiCalculations.cr(baseScore);
    baseScore.aov = kpiCalculations.aov(baseScore);

    if (options?.calculateNewCustomerScores) {
      baseScore.cac = kpiCalculations.cac(baseScore, options.isNewUserData);
      baseScore.newCustomerRate = kpiCalculations.newCustomerRate(
        baseScore,
        options.isNewUserData
      );
      baseScore.newVisitorRate = kpiCalculations.newVisitorRate(
        baseScore,
        options.isNewUserData
      );
    }

    const hasData = checkIfBaseScoreHasData(baseScore);
    if (hasData) {
      scopedBaseScores.set(scopedId, baseScore);
    }
  }

  return scopedBaseScores;
};

export type FQTransformOptions = {
  calculateNewCustomerScores?: boolean;
  isNewUserData?: boolean;
  ids?: string[];
  cooperationsAsAdsets?: boolean;
  forceCalculateNvr?: boolean;
};

export const transformFullyQualifiedAnalyticsResult = (
  result: AnalyticsResult,
  options?: FQTransformOptions
) => {
  if (result.attributions?.length === 0 || result.attributions[0] === null) {
    // pack result
    return null;
  }
  // raw result (from API) and transformed data
  const ads = new Map<string, Array<BaseScore>>();
  const adsets = new Map<string, Array<BaseScore>>();
  const campaigns = new Map<string, Array<BaseScore>>();

  // trim all "max_" or "smart_" prefixes from campaign ids across the whole result data
  // according to https://tracify-ai.atlassian.net/browse/DB-364
  // can be deleted after Hive and the ad-connector trim these prefixes server side
  for (const attribution of result.attributions) {
    const attributionData = attribution.aggregated;
    if (attributionData) {
      for (const [key, value] of Object.entries(attributionData)) {
        if (
          key.startsWith("google::max_") ||
          key.startsWith("google::smart_")
        ) {
          delete attributionData[key];
          const newKey = key
            ?.replace("google::max_", "google::")
            .replace("google::smart_", "google::");
          attributionData[newKey] = value;
        }
      }
    }
  }
  if (result.ads?.length) {
    for (const adData of result.ads) {
      const adInfos = adData.ad_infos;
      if (adInfos) {
        for (const [key, value] of Object.entries(adInfos)) {
          if (
            value?.campaign_id?.startsWith("google::max_") ||
            value?.campaign_id?.startsWith("google::smart_")
          ) {
            const newId = value?.campaign_id
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            adInfos[key] = { ...value, campaign_id: newId };
          }
          adData.ad_infos = adInfos;
        }
      }
      const adsetInfos = adData.adset_infos;
      if (adsetInfos) {
        for (const [key, value] of Object.entries(adsetInfos)) {
          if (
            value?.campaign_id?.startsWith("google::max_") ||
            value?.campaign_id?.startsWith("google::smart_")
          ) {
            const newId = value?.campaign_id
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            adsetInfos[key] = { ...value, campaign_id: newId };
          }
          adData.adset_infos = adsetInfos;
        }
      }
      const campaignInfos = adData.campaign_infos;
      if (campaignInfos) {
        for (const [key, value] of Object.entries(campaignInfos)) {
          if (
            key.startsWith("google::max_") ||
            key.startsWith("google::smart_")
          ) {
            delete campaignInfos[key];
            const newKey = key
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            campaignInfos[newKey] = value;
          }
          adData.campaign_infos = campaignInfos;
        }
      }
      const aggregatedKpis = adData.aggregated_ad_kpis;
      if (aggregatedKpis) {
        for (const [key, value] of Object.entries(aggregatedKpis)) {
          if (
            key.startsWith("google::max_") ||
            key.startsWith("google::smart_")
          ) {
            delete aggregatedKpis[key];
            const newKey = key
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            const newId = (value?.ad_id as string)
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            aggregatedKpis[newKey] = { ...value, ad_id: newId };
          }
        }
        adData.aggregated_ad_kpis = aggregatedKpis;
      }
    }
  }

  const rawResult = {
    ...result,
    adsetids: new Map(), // will be initialized later
    campaignids: new Map(), // will be initialized later
  } as AnalyticsResult;
  let scopedAds = getAdScoresFromAggregatedAttribution(rawResult, options);

  // https://github.com/Tracify-ai/dashboard/issues/91
  // not all ads might be attributed. This will lead to missing
  // spend. Hence, we iterate over the ads in the result
  // and fill up the missing entries.
  const unattributedResult = getUnattributedAdScores(
    rawResult,
    scopedAds,
    options
  );
  if (unattributedResult.scopedAds) {
    scopedAds = new Map([...scopedAds, ...unattributedResult.scopedAds]);
  }

  // we need this to map these unattributedAds to adsets and campaigns later
  // because these were not caught by Hive because not events have been received
  const unattributedAds = unattributedResult.unattributedAds;

  // sort ads by order count here
  scopedAds = new Map(
    [...scopedAds.entries()].sort(
      (a, b) => b[1].purchaseCount - a[1].purchaseCount
    )
  );

  const adsetids: Map<string, string[]> = new Map();
  const campaignids: Map<string, string[]> = new Map();
  // aggregate adsets
  for (const adKey of scopedAds.keys()) {
    const splitKey = adKey.split("::");

    // we only have an adset if they length here is 4 (or >3)
    // i.e. for facebook::123campaignId::123adsetId::123adId
    // if we dont have 4 elements (which also all must have a string value)
    // we typically have something like google::123perfMaxId::::adidhash
    // or referred::https://google.de::::adidhash
    // which don't hold any adsets only a campaign and an ad
    if (splitKey.length > 3 && !splitKey.includes("")) {
      const adsetKey = splitKey.slice(0, -1).join("::");
      const currentAdsetIds = adsetids?.get(adsetKey) ?? [];
      adsetids.set(adsetKey, [...currentAdsetIds, adKey]);
    }

    // only add to campaignids if there actually is an campaign id
    // exception is direct, since direct doesn't have a campaign id
    if (
      (splitKey[1] !== "" && splitKey[0] !== "direct") ||
      splitKey[0] === "direct"
    ) {
      const campaignKey = splitKey.slice(0, -2).join("::");
      const currentCampaignIds = campaignids?.get(campaignKey) ?? [];
      campaignids.set(campaignKey, [...currentCampaignIds, adKey]);
    }
  }
  rawResult.adsetids = adsetids;
  rawResult.campaignids = campaignids;

  let scopedAdsets = getBaseScoreFromAds(
    {
      result: { ...rawResult },
      scopedAds,
      type: "adset",
      unattributedAds,
    },
    options
  );

  scopedAdsets = new Map(
    [...scopedAdsets.entries()].sort(
      (a, b) => b[1].purchaseCount - a[1].purchaseCount
    )
  );

  // aggregate campaigns
  // REMARKS: In earlier versions, max campaign data from ad-connector did not have
  // a prefix campaign id (i.e. "max_{campaign_id}"). But with the introduction of
  // virtual ads this was changed to be consistent with Hive.
  // Therefore we do no longer have to remove the prefix.
  let scopedCampaigns = getBaseScoreFromAds(
    {
      result: { ...rawResult },
      scopedAds,
      scopedAdsets,
      type: "campaign",
      unattributedAds,
    },
    options
  );

  // sort campaigns by purchase count
  scopedCampaigns = new Map(
    [...scopedCampaigns.entries()].sort(
      (a, b) => b[1].purchaseCount - a[1].purchaseCount
    )
  );
  // create channel scoped maps
  for (const [scopedAdId, adScore] of scopedAds.entries()) {
    const [scope] = scopedAdId.split("::");
    const adsArr = ads?.get(scope);
    if (adsArr) adsArr.push(adScore);
    else ads.set(scope, [adScore]);
  }
  for (const [scopedAdsetId, adsetScore] of scopedAdsets.entries()) {
    const [scope] = scopedAdsetId.split("::");
    const adsetsArr = adsets?.get(scope);
    if (adsetsArr) adsetsArr.push(adsetScore);
    else adsets.set(scope, [adsetScore]);
  }
  for (const [scopedCampaignId, campaignScore] of scopedCampaigns.entries()) {
    const [scope] = scopedCampaignId.split("::");
    const campaignsArr = campaigns?.get(scope);
    if (campaignsArr) campaignsArr.push(campaignScore);
    else campaigns.set(scope, [campaignScore]);
  }

  // pack result
  const analytics = {
    ads: ads,
    adsets: adsets,
    campaigns: campaigns,
  } as BaseAnalytics;
  return analytics;
};

export const groupAdsByName = (
  ads: BaseScoreWithCreative[],
  options?: FQTransformOptions & { limit?: number }
) => {
  const groups = simpleAdsGroupFilter(ads);
  const accumulatedGroup = groups.map((group) => {
    let name = group[0].name;
    for (const string of STRINGS_TO_REMOVE_FROM_NAMES) {
      name = name.replaceAll(string, "");
    }
    const score: BaseScoreWithCreative & { adCount: number } = {
      refId: group[0].refId,
      fullyQualifiedId: group[0].fullyQualifiedId,
      name: name,
      status: group[0].status,
      addtocart: 0,
      pageview: 0,
      productview: 0,
      purchaseCount: 0,
      nvr_purchaseCount: 0,
      nvr_purchaseAmount: 0,
      nvr_upv: 0,
      newVisitorRate: 0,
      newCustomerRate: 0,
      purchaseAmount: 0.0,
      purchaseCartItems: 0,
      checkout: 0,
      clicks: 0,
      cpm: 0,
      cac: 0,
      cpo: 0,
      frequency: 0,
      roas: 0,
      impressions: 0,
      landingPageView: 0,
      linkClick: 0,
      platformConversions: 0,
      allPlatformConversions: 0,
      reach: 0,
      spend: 0,
      ctr: 0,
      thumbstopRatio: 0,
      cpc: 0,
      cr: 0,
      aov: 0,
      adCount: 0,
      comment: 0,
      onsiteConversionPostSave: 0,
      post: 0,
      videoAvgTimeWatchedActions: 0,
      videoView: 0,
      creative: group.find((el) => el.creative?.image)?.creative, // find the first creative in the group, should be the same overall
      creativeFormat: group.find((el) => el.creative?.image)?.creativeFormat,
      type: "ad",
      provider: group[0].provider,
      subRows: [],
      campaigns: [],
      adsets: [],
    };

    for (const adScore of group) {
      score.addtocart += adScore.addtocart;
      score.pageview += adScore.pageview;
      score.productview += adScore.productview;
      score.purchaseAmount += adScore.purchaseAmount;
      score.checkout += adScore.checkout;
      score.purchaseCartItems += adScore.purchaseCartItems;
      score.purchaseCount += adScore.purchaseCount;
      score.nvr_purchaseCount! += adScore.nvr_purchaseCount ?? 0;
      score.nvr_purchaseAmount! += adScore.nvr_purchaseAmount ?? 0;
      score.nvr_upv! += adScore.nvr_upv ?? 0;
      score.clicks! += adScore.clicks ?? 0;
      score.impressions! += adScore.impressions ?? 0;
      score.landingPageView! += adScore.landingPageView ?? 0;
      score.linkClick! += adScore.linkClick ?? 0;
      score.reach! += adScore.reach ?? 0;
      score.spend! += adScore.spend ?? 0;
      score.comment! += adScore.comment ?? 0;
      score.post! += adScore.post ?? 0;

      score.videoView! += adScore.videoView ?? 0;
      score.onsiteConversionPostSave! += adScore.onsiteConversionPostSave ?? 0;
      score.adCount +=
        (adScore as BaseScoreWithCreative & { adCount?: number })?.adCount ?? 1;

      adScore.parentCampaign?.name &&
        score.campaigns?.push(adScore.parentCampaign?.name);

      adScore.parentAdset?.name &&
        score.adsets?.push(adScore.parentAdset?.name);
    }
    // calculated differently because we need a weighted value here and
    // calculate that numbers from any other numbers
    score.videoAvgTimeWatchedActions =
      kpiCalculations.videoAvgTimeWatchedActions(group);

    score.roas = kpiCalculations.roas(score);
    score.frequency = kpiCalculations.frequency(score);
    score.cpo = kpiCalculations.cpo(score);
    score.ctr = kpiCalculations.ctr(score);
    score.thumbstopRatio = kpiCalculations.thumbstopRatio(score);
    score.cpc = kpiCalculations.cpc(score);
    score.cr = kpiCalculations.cr(score);
    score.aov = kpiCalculations.aov(score);
    score.cpm = kpiCalculations.cpm(score);

    if (options?.calculateNewCustomerScores) {
      score.cac = kpiCalculations.cac(score, options.isNewUserData);
      score.newCustomerRate = kpiCalculations.newCustomerRate(
        score,
        options.isNewUserData
      );
      score.newVisitorRate = kpiCalculations.newVisitorRate(
        score,
        options.isNewUserData
      );
    }

    return score;
  });
  const sortedGroup = accumulatedGroup.sort(
    (a, b) => b.purchaseAmount - a.purchaseAmount
  );

  return options?.limit ? sortedGroup.slice(0, options?.limit) : sortedGroup;
};

export type GetChannelDataProps = {
  adScores: BaseScore[];
  scope: ScopeType;
  label?: string;
};

export const getChannelData = (
  { adScores, label, scope }: GetChannelDataProps,
  options?: FQTransformOptions
) => {
  const channelData = {
    channel: label,
    spend: 0,
    reach: 0,
    impressions: 0,
    clicks: 0,
    linkClick: 0,
    platformConversions: 0,
    allPlatformConversions: 0,
    ctr: 0,
    thumbstopRatio: 0,
    cr: 0,
    cpm: 0,
    aov: 0,
    cpc: 0,
    purchaseCount: 0,
    nvr_purchaseCount: 0,
    nvr_purchaseAmount: 0,
    nvr_upv: 0,
    newVisitorRate: 0,
    newCustomerRate: 0,
    purchaseAmount: 0,
    checkout: 0,
    roas: 0,
    cac: 0,
    cpo: 0,
    frequency: 0,
    productview: 0,
    addtocart: 0,
    pageview: 0,
    chartData: [],
    isIncreasing: false,
    provider: scope,
    color: "",
  } as MarketingChannelOverviewInterface;

  for (const adScore of adScores) {
    channelData.purchaseCount += adScore.purchaseCount;
    channelData.purchaseAmount += adScore.purchaseAmount;
    channelData.checkout += adScore.checkout;
    channelData.pageview += adScore.pageview;
    channelData.addtocart += adScore.addtocart;
    channelData.productview += adScore.productview;
    channelData.nvr_purchaseCount! += adScore.nvr_purchaseCount ?? 0;
    channelData.nvr_purchaseAmount! += adScore.nvr_purchaseAmount ?? 0;
    channelData.nvr_upv! += adScore.nvr_upv ?? 0;
    channelData.spend += adScore.spend ?? 0;
    channelData.reach += adScore.reach ?? 0;
    channelData.impressions += adScore.impressions ?? 0;
    channelData.clicks += adScore.clicks ?? 0;
    channelData.linkClick += adScore.linkClick ?? 0;
    channelData.platformConversions += adScore.platformConversions ?? 0;
    channelData.allPlatformConversions += adScore.allPlatformConversions ?? 0;
  }
  channelData.color = mapChannelToColor(channelData.channel);
  channelData.roas = kpiCalculations.roas(channelData);
  channelData.frequency = kpiCalculations.frequency(channelData);
  channelData.cpo = kpiCalculations.cpo(channelData);
  channelData.ctr = kpiCalculations.ctr(channelData);
  channelData.thumbstopRatio = kpiCalculations.thumbstopRatio(channelData);
  channelData.cpc = kpiCalculations.cpc(channelData);
  channelData.cr = kpiCalculations.cr(channelData);
  channelData.aov = kpiCalculations.aov(channelData);
  channelData.cpm = kpiCalculations.cpm(channelData);

  if (options?.calculateNewCustomerScores) {
    channelData.cac = kpiCalculations.cac(channelData, options.isNewUserData);
    channelData.newCustomerRate = kpiCalculations.newCustomerRate(
      channelData,
      options.isNewUserData
    );
    channelData.newVisitorRate = kpiCalculations.newVisitorRate(
      channelData,
      options.isNewUserData
    );
  }

  return channelData;
};

export const transformToChannelScore = (
  analytics: BaseAnalytics,
  options?: FQTransformOptions
) => {
  const channelDataArray: MarketingChannelOverviewInterface[] = [];
  const influencerIndex = AVAILABLE_MARKETING_CHANNEL_OPTIONS.findIndex(
    (el) => el.value === "influencer"
  );

  const extendendOptions = [
    ...AVAILABLE_MARKETING_CHANNEL_OPTIONS.slice(0, influencerIndex),
    { value: "influencer_module", label: "Influencer" },
    { value: "influencer", label: "Influencer (old)" },
    ...AVAILABLE_MARKETING_CHANNEL_OPTIONS.slice(influencerIndex + 1),
  ];

  // join referral data only for the marketing overview dashboard
  const adsData = analytics?.ads;
  const referralData = adsData?.get("referral");
  if (referralData) {
    const organicData = adsData?.get("referred") ?? [];
    organicData?.push(...referralData);
    adsData.set("referred", organicData);
    adsData.delete("referral");
  }

  for (const { label, value: scope } of extendendOptions) {
    const scopedAdsData = adsData?.get(scope);
    if (scopedAdsData) {
      const channelData = getChannelData(
        {
          adScores: scopedAdsData,
          label,
          scope:
            scope as (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"],
        },
        options
      );
      channelDataArray.push(channelData);
    }
  }
  return channelDataArray;
};

export const transformToGoogleChannelScore = (
  analytics: BaseAnalytics,
  options?: FQTransformOptions
) => {
  const googleChannelDataArray: MarketingChannelOverviewInterface[] = [];
  for (const { value } of AVAILABLE_GOOGLE_CHANNEL_OPTIONS) {
    const scopedAdsData = analytics.campaigns
      ?.get("google")
      ?.filter(
        (el) =>
          (value === "UNKNOWN" && !el.advertisingChannelType) ||
          el.advertisingChannelType === value
      );
    if (scopedAdsData && scopedAdsData.length > 0) {
      const channelData = getChannelData(
        {
          adScores: scopedAdsData,
          label: value,
          scope: "google",
        },
        options
      );
      if (
        channelData.spend > 0 ||
        channelData.purchaseAmount > 0 ||
        channelData.purchaseCount > 0
      ) {
        googleChannelDataArray.push(channelData);
      }
    }
  }
  return googleChannelDataArray;
};

export const transformToInfluencerChannelScore = (
  analytics: BaseAnalytics,
  options?: FQTransformOptions
) => {
  const influencerChannelDataArray: MarketingChannelOverviewInterface[] = [];
  for (const { label, value } of AVAILABLE_INFLUENCER_SOURCE_OPTIONS) {
    const scopedAdsData = (
      analytics.adsets?.get("influencer_module") as (BaseScore & Cooperation)[]
    )?.filter(
      (el) =>
        el.source === value &&
        (Boolean(options?.ids?.length) === false ||
          options?.ids?.includes(el.id))
    );
    if (scopedAdsData && scopedAdsData.length > 0) {
      const channelData = getChannelData(
        {
          adScores: scopedAdsData,
          label: label,
          scope: "influencer_module",
        },
        options
      );
      if (
        channelData.spend > 0 ||
        channelData.purchaseAmount > 0 ||
        channelData.purchaseCount > 0
      ) {
        influencerChannelDataArray.push(channelData);
      }
    }
  }
  return influencerChannelDataArray;
};

export const mapChannelToColor = (channel: string) => {
  return (
    {
      Meta: "#0668E1",
      Instagram: "#E1306C",
      YouTube: "#FF0000",
      Blog: "#F3B35A",
      Google: "#DB4437",
      TikTok: "#25F4EE",
      Email: "#46B8E3",
      Organic: "#65A30D",
      Referral: "#65A30D",
      Influencer: "#C13584",
      "Influencer (old)": "#C13584",
      Taboola: "#00659F",
      Pinterest: "#E60023",
      Snapchat: "#C4C116",
      Microsoft: "#008373",
      "Microsoft Ads": "#008373",
      WhatsApp: "#25D366",
      Messenger: "#25D366",
      Criteo: "#FE5000",
      Twitch: "#6441a5",
      Outbrain: "#EE6513",
      Direct: "#E3BD00",
      PERFORMANCE_MAX: "#E37400",
      SMART: "#673AB7",
      SEARCH: "#4285F4",
      DISCOVERY: "#0F9D58",
      DISPLAY: "#F4B400",
      SHOPPING: "#DB4437",
      VIDEO: "#FF0000",
    }[channel] ?? "#ccc"
  );
};

import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  InformationCircleIcon,
  ChevronUpDownIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { Divider } from "@ui";
import { getSelectColors, getSizes } from "@ui/select/styles";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { newVsReturningAtom } from "atoms";
import { NVR_OPTIONS } from "constants/constants";
import { useAtom } from "jotai";
import React, { useMemo } from "react";
import s from "../../ui-shared/select/select.module.css";
import useSelectedStores from "@lib/hooks/use-selected-stores";

const NvrDropdown = () => {
  const [newVsReturning, setNewVsReturning] = useAtom(newVsReturningAtom);

  const { height } = useMemo(() => getSizes("medium"), []);
  const colors = useMemo(() => getSelectColors("lite"), []);
  return (
    <>
      <Listbox
        value={newVsReturning}
        onChange={setNewVsReturning}
        as="div"
        className={"w-48 "}
      >
        <div className={`relative text-foreground z-30 ${height}`}>
          <Listbox.Button
            className={`relative w-full h-full pl-3 pr-6 text-left transition-color rounded-md duration-200 border ${s.select} ${colors} cursor-pointer sm:text-sm`}
          >
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-primary"
                aria-hidden="true"
              />
            </span>
            <span className="truncate font-semibold mr-2 inline-flex items-center">
              <UserGroupIcon className="h-4 w-4 inline-block mr-2" />{" "}
              {(
                NVR_OPTIONS.find((el) => el.value === newVsReturning)?.label ??
                "All Users"
              ).replace("Users", "")}
            </span>
          </Listbox.Button>
        </div>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={
              "absolute z-navbar scrollbar w-64 py-1 mt-1 rounded-md overflow-auto text-base bg-gray-800 shadow-lg text-foreground max-h-60 ring-1 ring-secondary ring-opacity-5 cursor-pointer focus:outline-none sm:text-sm"
            }
          >
            {NVR_OPTIONS.map((option) => (
              <React.Fragment key={option.value}>
                <Listbox.Option
                  key={option.value}
                  value={option.value}
                  className={({ active }) =>
                    `${active ? "text-primary bg-gray-700" : "text-gray-200"}
                            cursor-pointer select-none relative py-2 pl-10 pr-4`
                  }
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`inline-flex items-center ${
                          selected ? "font-medium" : "font-normal"
                        } block `}
                      >
                        {option.label}
                        {option.value === "default" ? (
                          <Tooltip>
                            <TooltipTrigger className="ml-2">
                              <InformationCircleIcon className="h-5 w-5 text-primary" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="w-96">
                                Faster loading. Doesn&apos;t include CAC and
                                differentiation between new vs returning users
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        ) : null}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? "text-primary" : "text-primary"
                          }
                                  absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon className="h-4 w-4" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
                {option.value === "default" ? <Divider y="my-2" /> : null}
              </React.Fragment>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </>
  );
};

export default NvrDropdown;

import {
  DividerAlign,
  DividerSpacesX,
  DividerSpacesY,
  SnippetTypes,
} from "../../lib/util-types";
import React, { useMemo } from "react";
import s from "./divider.module.css";

export type DividerTypes = SnippetTypes;

interface Props {
  x?: DividerSpacesX;
  y?: DividerSpacesY;
  type?: DividerTypes;
  align?: DividerAlign;
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type DividerProps = Props & NativeAttrs;

const getColor = (type: DividerTypes) => {
  const colors: { [key in DividerTypes]: string } = {
    default: "bg-gray-700 text-gray-700",
    lite: "bg-gray-100 text-gray-100",
    primary: "bg-primary text-primary",
    warning: "bg-yellow-50 text-yellow-500",
    error: "bg-red-500 text-red-500",
    secondary: "bg-secondary text-secondary",
    dark: "bg-foreground text-foreground",
  };
  return colors[type];
};

export const Divider: React.FC<React.PropsWithChildren<DividerProps>> = ({
  x = "mx-0",
  y = "my-4",
  align = "center" as DividerAlign,
  type = "default" as DividerTypes,
  className = "",
  children,
  ...props
}) => {
  const color = useMemo(() => getColor(type), [type]);
  const alignClassName = useMemo(() => {
    if (!align || align === "center") return "";
    if (align === "left" || align === "start") return s.start;
    return s.end;
  }, [align]);
  return (
    <div
      role="separator"
      className={`divider ${x} ${y} h-px w-auto max-w-full relative ${color} ${className}`}
      {...props}
    >
      {children && (
        <span className={`${s.text} ${alignClassName}`}>{children}</span>
      )}
    </div>
  );
};

export default Divider;

import NvrTooltip from "@components/NvrDropdown/NvrTooltip";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Button, Spinner } from "@ui";
import React from "react";

type Props = {
  showNvrTooltip: boolean;
  onFetchData: () => void;
  isPending: boolean;
  fetchingData: boolean;
  progress: number;
};

const PageLoadingButton = ({
  fetchingData,
  isPending,
  onFetchData,
  progress,
  showNvrTooltip,
}: Props) => {
  return (
    <NvrTooltip open={showNvrTooltip}>
      <Button
        type="primary"
        onClick={() => {
          onFetchData();
        }}
        disabled={isPending || fetchingData}
        iconButton
        size="large"
        className={
          fetchingData ? "!bg-primary !text-on-primary !cursor-wait" : ""
        }
      >
        {fetchingData ? (
          <>
            <Spinner />{" "}
            <span className="ml-2">
              Fetching Data ({fetchingData ? progress?.toFixed(0) : 99}
              %)
            </span>
          </>
        ) : (
          <ArrowPathIcon className="w-5 h-5" />
        )}
      </Button>
    </NvrTooltip>
  );
};

export default PageLoadingButton;

import { useEffect, useState } from "react";

export function useAttributionSettingsOpen() {
  const [settingsOpenState, setSettingsOpenState] = useState(false);

  useEffect(() => {
    const attributionWindowSelect = document.querySelector(
      "#attribution-window-select"
    );
    const attributionModelSelect = document.querySelector(
      "#attribution-model-select"
    );
    const reportTimeSelect = document.querySelector("#report-time-select");
    const channelsSelect = document.querySelector(
      "#selected-marketing-channels-select"
    );
    const datepickerPopover = document.querySelector("#date-select-popover");
    const storeSelector = document.querySelector("#store-selector-select");
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-headlessui-state"
        ) {
          const openState = (mutation.target as Element).getAttribute(
            mutation.attributeName
          );
          if (openState === "open") setSettingsOpenState(true);
          else setSettingsOpenState(false);
        }
      });
    });

    if (attributionWindowSelect) {
      observer.observe(attributionWindowSelect, {
        attributes: true, // configure it to listen to attribute changes
      });
    }
    if (attributionModelSelect) {
      observer.observe(attributionModelSelect, {
        attributes: true, // configure it to listen to attribute changes
      });
    }
    if (reportTimeSelect) {
      observer.observe(reportTimeSelect, {
        attributes: true, // configure it to listen to attribute changes
      });
    }
    if (channelsSelect) {
      observer.observe(channelsSelect, {
        attributes: true, // configure it to listen to attribute changes
      });
    }
    if (datepickerPopover) {
      observer.observe(datepickerPopover, {
        attributes: true, // configure it to listen to attribute changes
      });
    }
    if (storeSelector) {
      observer.observe(storeSelector, {
        attributes: true, // configure it to listen to attribute changes
      });
    }

    return () => observer.disconnect();
  }, []);

  return settingsOpenState;
}
